import React from 'react'
import { NavLink } from 'react-router-dom-v5-compat'

import { CUSTOM_NOTE_PORTAL_SETTINGS } from '../../utils/constants'
import { DataTable } from '../../components/DataTable/DataTable'
import { DEFAULT_FILTERS } from '../Filters/constants'
import { MainCell } from '../../components/DataTable/cells/MainCell'

export const ClientCustomNoteTemplatesDataTable = (props: any) => {
  const { to, onClick, ...rest } = props

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 240,
        formatValue: ({ data, value }: any) => (
          <MainCell
            id={data.id}
            as={NavLink}
            to={to ? to(data) : undefined}
            onClick={onClick ? () => onClick(data) : undefined}
            value={value}
          />
        ),
      },
      {
        title: 'Client Tracks',
        model: 'client_tracks',
        type: 'record_client_tracks',
        featureFlag: 'client_tracks',
        editPermission: 'client_tracks.edit',
        disableSort: true,
      },
      {
        title: 'Supervisor Signature',
        id: 'require_supervisor_signature',
        model: 'settings.require_supervisor_signature',
        type: 'boolean',
        width: 160,
      },
      {
        title: 'Client Signature',
        id: 'require_reference_signature',
        model: 'settings.require_reference_signature',
        type: 'boolean',
        width: 160,
      },
      {
        title: 'Portal Settings',
        model: 'portal_settings',
        width: 300,
        formatValue: ({ value }: any) => CUSTOM_NOTE_PORTAL_SETTINGS[value] || null,
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
        disableSort: true,
      },
    ],
    [to, onClick],
  )

  return (
    <DataTable
      asCard
      title="Client Custom Note Templates"
      testKey="client_custom_note_templates_data_table"
      icon="custom_notes"
      columns={columns}
      filtersConfig={FILTERS_CONFIG}
      {...rest}
    />
  )
}

const FILTERS_CONFIG = {
  name: {
    label: 'Name',
    type: 'string',
  },
  client_tracks: DEFAULT_FILTERS.client_tracks,
  require_supervisor_signature: {
    label: 'Supervisor Signature',
    type: 'boolean',
  },
  require_reference_signature: {
    label: 'Client Signature',
    type: 'boolean',
  },
  portal_settings: {
    label: 'Portal Settings',
    type: 'multi_select',
    options: Object.entries(CUSTOM_NOTE_PORTAL_SETTINGS).map(([value, label]) => ({ value, label })),
  },
  author: DEFAULT_FILTERS.author,
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
}
