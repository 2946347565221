import React from 'react'
import { useRouteMatch, Link } from 'react-router-dom'

import { apiUpdate } from '@behavehealth/modules/api'
import { withPageError } from '@behavehealth/hocs/withPageError'
import { useGet, useUpdate } from '@behavehealth/hooks/useNewAPI'

import {
  Accordion,
  AddressSelectorInput,
  Alert,
  Chotomate,
  Divider,
  EmailInput,
  Form,
  FormSection,
  Grid,
  Input,
  Option,
  Page,
  PhoneInput,
  Select,
  Textarea,
  URLInput,
  State,
} from '@behavehealth/components'

import Checkbox from '@behavehealth/components/Forms/Checkbox'
import CheckboxGroup from '@behavehealth/components/Forms/CheckboxGroup'
import NumberInput from '@behavehealth/components/Forms/NumberInput'

import { ACCREDITING_BODY } from '@behavehealth/constructs/Authority/constants'
import { DIRECTORIES_DATA } from '@behavehealth/constructs/AuthorityDirectories/data'

const RootDirectoryInfo: React.FC = () => {
  const match: any = useRouteMatch()
  const id = match.params?.resource_id

  const { data: organization, isLoading }: any = useGet({
    name: ['organization', id],
    url: `/organizations/${id}`,
  })

  const { mutateAsync: updateOrganizationDirectoryData }: any = useUpdate({
    name: ['organization', organization?.organization_directory_data?.id],
    url: `/organization_directory_data/${organization?.organization_directory_data?.id}`,
    invalidate: ['organizations'],
  })

  if (!organization || isLoading) return null

  if (!organization?.organization_directory_data)
    return <Page isEmpty feature="general_info" title="Directory Info" emptyDescription="No Directory Data found" />

  return (
    <Page feature="general_info" title="Directory Info">
      <Chotomate name="general_info" ready={organization} />

      <Grid gap="1.5rem">
        <div>
          {/* Organization Details */}
          <Accordion
            isOpen
            activateEditMode
            title="Organization Details"
            initialModel={organization?.organization_directory_data}
            glyph="organization_details"
            permission="organizations.general_info.organization_details.view"
            editPermission="organizations.general_info.organization_details.edit"
            onSubmit={updateOrganizationDirectoryData}
          >
            <Form>
              <FormSection labelWidth="150px">
                <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="none">
                  <Checkbox
                    label="Share this Organization in Directories"
                    description="Allow the organization to be shared in Directories"
                    model="is_shared_with_directories"
                  />
                </CheckboxGroup>

                <Alert small icon="directories">
                  <b>Note:</b> go to <Link to="/settings/directories">Settings → Directories</Link> to manage your directories and the data
                  points that are shared.
                </Alert>

                <URLInput label="Application Link" model="default_application_url" />

                <Input label="Licensing Body" model="licensing_body" />

                <Select allowEmpty label="Accrediting Body" model="accrediting_body">
                  {Object.keys(ACCREDITING_BODY).map((model) => (
                    <Option key={model} label={ACCREDITING_BODY[model]} value={model} />
                  ))}
                </Select>

                <Textarea label="About" model="about" minRows={4} description="A general overview of the facility and its services" />

                <Textarea
                  label="Mission Statement"
                  model="mission_statement"
                  minRows={4}
                  description="The facility's official mission statement"
                />

                <Textarea label="Description" model="description" minRows={4} />

                <NumberInput label="Year Founded" model="year_founded" size={6} />

                <CheckboxGroup
                  label="Facility Type"
                  tooltip={DIRECTORIES_DATA.facility_type.tooltip}
                  layout="vertical-dense"
                  trueIcon="check"
                  falseIcon="cross"
                  falseStyle="faded"
                >
                  {Object.entries(DIRECTORIES_DATA.facility_type.options).map(([model, label]) => (
                    <Checkbox key={model} model={`facility_type.${model}`} label={label} />
                  ))}
                </CheckboxGroup>

                <CheckboxGroup
                  label="Service Availability"
                  tooltip={DIRECTORIES_DATA.service_availability.tooltip}
                  layout="vertical-dense"
                  trueIcon="check"
                  falseIcon="cross"
                  falseStyle="faded"
                >
                  {Object.entries(DIRECTORIES_DATA.service_availability.options).map(([model, label]) => (
                    <Checkbox key={model} model={`service_availability.${model}`} label={label} />
                  ))}
                </CheckboxGroup>

                <CheckboxGroup
                  label="Required Activities"
                  tooltip={DIRECTORIES_DATA.required_activities.tooltip}
                  layout="vertical-dense"
                  trueIcon="check"
                  falseIcon="cross"
                  falseStyle="faded"
                >
                  {Object.entries(DIRECTORIES_DATA.required_activities.options).map(([model, label]) => (
                    <Checkbox key={model} model={`required_activities.${model}`} label={label} />
                  ))}
                </CheckboxGroup>

                <Input
                  label="Operating Hours"
                  model="operating_hours"
                  minRows={4}
                  description="The facility's standard hours of operation"
                />
              </FormSection>
            </Form>
          </Accordion>

          {/* Contact Details */}
          <Accordion
            activateEditMode
            glyph="contact_details"
            initialModel={organization?.organization_directory_data}
            title="Contact Details"
            permission="organizations.general_info.contact_details.view"
            editPermission="organizations.general_info.contact_details.edit"
            onSubmit={updateOrganizationDirectoryData}
          >
            <Form>
              <FormSection>
                <EmailInput label="Primary Email Address" model="primary_email" />
                <PhoneInput label="Primary Phone Number" model="primary_phone_no" />
                <Input glyph="phone_ext" label="Primary Phone No Extension" model="primary_phone_no_ext" />

                <Input glyph="fax" label="Primary Fax" model="primary_fax" />
                <URLInput glyph="website" label="Website" model="website" />

                <Divider className="m-0" />

                <h3>Admissions</h3>
                <Input label="Admissions Contact Name" model="admissions_contact_name" />
                <EmailInput label="Admissions Email Address" model="admissions_email" />
                <PhoneInput label="Admissions Phone Number" model="admissions_phone_no" />

                <Divider className="m-0" />

                <h3>Marketing</h3>
                <Input label="Marketing Contact Name" model="marketing_contact_name" />
                <EmailInput label="Marketing Email Address" model="marketing_email" />
                <PhoneInput label="Marketing Phone Number" model="marketing_phone_no" />

                <Divider className="m-0" />

                <h3>Emergency</h3>
                <Input label="Emergency Contact Name" model="emergency_contact_name" />
                <EmailInput label="Emergency Email Address" model="emergency_email" />
                <PhoneInput label="Emergency Phone Number" model="emergency_phone_no" />
                <Input
                  label="Emergency Contact Hours"
                  model="emergency_contact_hours"
                  minRows={4}
                  tooltip="Hours when emergency contacts are available"
                />
              </FormSection>
            </Form>
          </Accordion>

          {/* Address */}
          <Accordion
            title="Address"
            initialModel={organization?.organization_directory_data}
            glyph="address"
            permission="organizations.general_info.address.view"
          >
            <FormSection labelWidth="150px">
              <AddressSelectorInput
                label="Main Address"
                actionLabel="Update Address"
                value={organization?.organization_directory_data?.main_address}
                permission="organizations.general_info.address.edit"
                afterAddressSelect={(address: any) =>
                  apiUpdate({
                    name: 'organization_directory_data',
                    url: `/organization_directory_data/${organization?.organization_directory_data?.id}`,
                    params: { main_address: address },
                  })
                }
              />

              <AddressSelectorInput
                label="Mailing Address"
                actionLabel="Update Address"
                value={organization?.organization_directory_data?.mailing_address}
                permission="organizations.general_info.address.edit"
                afterAddressSelect={(address: any) =>
                  apiUpdate({
                    name: 'organization_directory_data',
                    url: `/organization_directory_data/${organization?.organization_directory_data?.id}`,
                    params: { mailing_address: address },
                  })
                }
              />
            </FormSection>
          </Accordion>
        </div>
      </Grid>
    </Page>
  )
}

export const DirectoryInfo = withPageError(RootDirectoryInfo)
