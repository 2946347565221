import {
  // Organization
  ORGANIZATION_DETAILS,

  // Locations
  LOCATION_DETAILS,
  LOCATION_GALLERY,
  LOCATION_CERTIFICATIONS,
  LOCATION_DEMOGRAPHICS,
  LOCATION_FACILITY_DETAILS,
  LOCATION_FINANCIALS,
  LOCATION_POLICIES,
  LOCATION_TREATMENT,
  LOCATION_MARKETING,
  LOCATION_TREATMENT_DETAILS,
  LOCATION_DOCUMENTATION,
  LOCATION_MEDICATION_MANAGEMENT,
  LOCATION_STAFF_DEVELOPMENT,

  // Programs
  PROGRAM_OPERATIONS,
} from './constants'

export const DIRECTORY_SCHEMA = {
  organization_details: {
    id: 'organization_details',
    name: 'Organization Details',
    icon: 'organizations',
    schema: ORGANIZATION_DETAILS,
  },
  location_gallery: {
    id: 'location_gallery',
    name: 'Location Details',
    icon: 'gallery',
    schema: LOCATION_GALLERY,
  },
  location_details: {
    id: 'location_details',
    name: 'Location Details',
    icon: 'general_info',
    schema: LOCATION_DETAILS,
  },
  location_financials: {
    id: 'location_financials',
    name: 'Financials',
    icon: 'financials',
    schema: LOCATION_FINANCIALS,
  },
  location_certifications: {
    id: 'location_certifications',
    name: 'Certifications',
    icon: 'patient_progress_measure',
    schema: LOCATION_CERTIFICATIONS,
  },
  location_demographics: {
    id: 'location_demographics',
    name: 'Demographics',
    icon: 'clients',
    schema: LOCATION_DEMOGRAPHICS,
  },
  location_facility_details: {
    id: 'location_facility_details',
    name: 'Facility Details',
    icon: 'enterprise',
    schema: LOCATION_FACILITY_DETAILS,
  },
  location_policies: {
    id: 'location_policies',
    name: 'Policies',
    icon: 'sell',
    schema: LOCATION_POLICIES,
  },
  program_operations: {
    id: 'program_operations',
    name: 'Programs',
    icon: 'checklist',
    schema: PROGRAM_OPERATIONS,
  },
  location_treatment: {
    id: 'location_treatment',
    name: 'Treatment',
    icon: 'treatment_data',
    schema: LOCATION_TREATMENT,
  },
  location_treatment_details: {
    id: 'location_treatment_details',
    name: 'Treatment Details',
    icon: 'treatment_plans',
    schema: LOCATION_TREATMENT_DETAILS,
  },
  location_marketing: {
    id: 'location_marketing',
    name: 'Marketing',
    icon: 'recovery_coaching',
    schema: LOCATION_MARKETING,
  },
  location_documentation: {
    id: 'location_documentation',
    name: 'Documentation',
    icon: 'files',
    schema: LOCATION_DOCUMENTATION,
  },
  location_medication_management: {
    id: 'location_medication_management',
    name: 'Medication Management',
    icon: 'medication',
    schema: LOCATION_MEDICATION_MANAGEMENT,
  },
  location_staff_development: {
    id: 'location_staff_development',
    name: 'Staff Development',
    icon: 'employees',
    schema: LOCATION_STAFF_DEVELOPMENT,
  },
  location_testimonials: {
    id: 'location_testimonials',
    name: 'Testimonials',
    icon: 'testimonials',
    schema: {
      testimonials: {
        id: 'testimonials',
        name: 'Testimonials',
        model: 'location.testimonials',
      },
    },
  },
} as const
