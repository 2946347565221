import React from 'react'
import { useParams } from 'react-router-dom'

import { daysToWords, titleCase, usDateTime } from '../../utils/functions'
import { ExportPDFButton } from '../../components/Buttons/ExportPDFButton'
import { useGet, invalidateQueries } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import AgreementSections from '../../components/Elements/AgreementSections'
import Button from '../../components/Button'
import Card from '../../components/Card'
import CardHeader from '../../components/CardHeader'
import CardMeta from '../../components/CardMeta'
import CardTitle from '../../components/CardTitle'
import Checkbox from '../../components/Forms/Checkbox'
import ContextShow from '../../components/Forms/ContextShow'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ContractStatus from '../../components/Statuses/ContractStatus'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Dialog from '../../components/Dialog'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import MultiObjectSelector from '../../components/Forms/Selectors/MultiObject/MultiObjectSelector'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import Permission from '../../components/Permission'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import SignAgreementOverlay from '../../components/Overlays/pages/Agreements/SignAgreementOverlay'
import SignatureDialog from '../../components/Dialogs/SignatureDialog'
import SignaturePad from '../../components/Forms/SignaturePad'
import SigneeRelationshipStatus from '../../components/Statuses/SigneeRelationshipStatus'
import Status from '../../components/Status'
import SummonOverlay from '../../components/SummonOverlay'
import Text from '../../components/Typography/Text'
import Textarea from '../../components/Forms/Textarea'
import Timeline from '../../components/Timeline/Timeline'

const Contract = ({ agreement, contract, reference, refetch }: any) => (
  <Card variant="variant-list" paddingX="0" paddingY="0.5rem" css={{ '&:last-of-type': { boxShadow: 'none !important' } }}>
    <CardHeader
      contentGap="0.35rem"
      after={
        agreement.should_sign && (
          <SummonOverlay
            overlay={
              <SignAgreementOverlay
                showBackdrop
                useContractsEndpoint
                agreement={agreement}
                contract={contract}
                onSignedSuccessfully={refetch}
              />
            }
          >
            {contract.status === 'signed' ? (
              <Button label="View Agreement" glyph="tick_circle" type="default" size={200} display="inline-flex" color="green" />
            ) : (
              <Button label="Sign Agreement" glyph="signature" type="primary" size={200} display="inline-flex" />
            )}
          </SummonOverlay>
        )
      }
    >
      <CardTitle title={contract?.signee?.signer?.name} className="!text-[1rem] !font-[700]" />

      <CardMeta>
        <SigneeRelationshipStatus signee={contract?.signee?.signer} />
        {agreement.should_sign && <ContractStatus status={contract?.status} />}
      </CardMeta>

      <CardMeta className="!text-[0.9rem]">
        {contract?.status === 'signed' && <Text label="Signed At: " glyph="signature" description={usDateTime(contract?.signed_at)} />}
      </CardMeta>
    </CardHeader>
  </Card>
)

const RootLegalAgreementOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialData,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
    updateAsync,
    refetch,
  } = useOverlay({
    name: 'agreements',
    endpoint: '/agreements',
    invalidate: 'agreements',
    invalidateKeys: ['agreements'],
    options: props,
    openAfterCreate: true,
  })

  const { tenant, timezone, isBehave } = useSettings()

  const signAgreement = async (signature) => {
    await updateAsync({
      [signature.model]: signature.value,
    })

    cancel()
  }

  // if signature was originally required and is now unchecked in edit mode
  const contractsSigned = initialModel?.contracts?.filter((o: any) => o.status === 'signed').length || 0

  if (isOverlayLoading) {
    return <OverlayLoader position="right" maxWidth={82} />
  }

  return (
    <Overlay
      showBackdrop={isEditable}
      position="right"
      maxWidth={82}
      onClose={close}
      closeWrapper={(element, onClose) => (
        <Dialog
          glyph="delete"
          title="Close without saving?"
          message="All changes will be lost. This action cannot be undone."
          yesColor="red"
          yesLabel="Yes, Close Without Saving"
          onYes={onClose}
          skip={!isEditable || !onClose}
        >
          {element}
        </Dialog>
      )}
    >
      <Overlay.Header title={data?.name} icon="legal_agreement_alt" />

      {!isEditable && (
        <Permission permission="clients.actions.export">
          <Overlay.SubHeader>
            <ExportPDFButton url={`/agreements/${id}/pdf`} />
          </Overlay.SubHeader>
        </Permission>
      )}

      <Overlay.Content>
        <Form
          useFullModel
          isEditable={isEditable}
          getForm={form}
          timezone={timezone}
          onValidationUpdate={onValidationUpdate}
          initialModel={data}
        >
          <Section title="Agreement Details">
            <FormSection maxWidth="100%">
              <Input
                label="Agreement Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter an agreement name',
                  },
                }}
              />

              <Select allowEmpty label="Review Cycle" model="review_cycle">
                <Option label="Daily" value="daily" />
                <Option label="Weekly" value="weekly" />
                <Option label="Monthly" value="monthly" />
                <Option label="Quarterly" value="quarterly" />
                <Option label="Semi-annually" value="semi_annually" />
                <Option label="Annually" value="annually" />
              </Select>

              <Flex gap={16} horizontal stretchChildrenX>
                <DateTimeInput
                  defaultToNow
                  model="dated_at"
                  label="Effective Date"
                  validations={{
                    presence: {
                      message: 'Please enter a date and time',
                    },
                  }}
                />

                <DateTimeInput model="expired_at" label="Expiry Date" />
              </Flex>

              <DateTimeInput model="reviewed_at" label="Last Review Date" />

              <Textarea useQuickText label="Notes" model="notes" />
            </FormSection>
          </Section>

          <Divider />

          <AgreementSections
            model="sections"
            validations={{
              presence: {
                message: 'Please add at least one section',
              },
            }}
          />

          <Section
            title="Company"
            aside={
              isEditable && (
                <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="linethrough">
                  <Checkbox label="Require Company Signature" model="request_cosigner_signature" />
                </CheckboxGroup>
              )
            }
          >
            {isEditable && (
              <FormSection maxWidth="100%" className="!mt-2">
                <MultiObjectSelector maxWidth="500px" icon="employees" label="Signees" model="signees" type="employees" />
              </FormSection>
            )}

            {!isNew &&
              data?.contracts?.map((contract: any) => (
                <Contract key={contract.id} agreement={data} contract={contract} reference={data?.reference} refetch={refetch} />
              ))}
          </Section>

          <Divider />

          <Section
            title="Behave Health"
            aside={
              isEditable && (
                <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="linethrough">
                  <Checkbox label="Require Behave Health Signature" model="should_sign" />
                </CheckboxGroup>
              )
            }
          >
            <Flex alignItems="center" gap={8} justifyContent="space-between">
              {data?.request_cosigner_signature && !data?.cosigner_signature && (
                <SignatureDialog
                  isDisabled={!isBehave}
                  allowPin={false}
                  model="cosigner"
                  title="Sign Agreement"
                  yesLabel="Sign"
                  onYes={signAgreement}
                >
                  {isBehave && (
                    <Button fullWidth label="Sign Agreement…" glyph="signature" type="default" size={200} isDisabled={isLoading} />
                  )}
                </SignatureDialog>
              )}

              {data?.cosigner_signature && (
                <>
                  <Button
                    label={`${data?.cosigner?.name} signed at ${usDateTime(data?.cosigner_signed_at, tenant.timezone)}`}
                    glyph="tick_circle"
                    type="tick_circle"
                    color="green"
                    size={200}
                  />

                  {isBehave && (
                    <SignaturePad
                      isValid
                      label="Behave Health"
                      person={data?.cosigner}
                      allowPin={false}
                      model="cosigner_signature"
                      value={data?.cosigner_signature}
                      signedAtModel="cosigner_signed_at"
                    />
                  )}
                </>
              )}
            </Flex>
          </Section>

          <Divider />

          {!isNew && (
            <>
              <Divider />
              <Section headingType="h2" title="Timeline">
                <Timeline isLoadingRecord={isLoading} recordID={data?.id} recordType={data?.type} />
              </Section>
            </>
          )}
        </Form>
      </Overlay.Content>

      {isBehave && (
        <Overlay.Footer>
          {isEditable && (
            <>
              <Button
                glyph="check"
                label="Save Agreement"
                type="primary"
                color="green"
                isLoading={isSaving}
                onClick={save}
                isDisabled={isInvalid}
                flex="100 1 240px"
                permission="agreements.create"
              />

              {!isNew && <Button glyph="cross" label="Cancel" type="default" isDisabled={isSaving} onClick={cancel} />}
            </>
          )}

          {!isEditable && (
            <>
              {!contractsSigned && (
                <Button
                  label="Edit"
                  glyph="edit"
                  type="default"
                  isDisabled={isLoading}
                  onClick={edit}
                  flex="100 1 auto"
                  permission="agreements.edit"
                />
              )}

              <DeleteDialog
                title="Delete Agreement?"
                message="Are you sure you want to delete this agreement? This action cannot be undone."
                onYes={deleteRecord}
              >
                <Button
                  label="Delete"
                  type="default"
                  glyph="delete"
                  color="red"
                  isLoading={isDeleting}
                  fullWidth
                  permission="agreements.delete"
                />
              </DeleteDialog>
            </>
          )}
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

export const LegalAgreementOverlay = withOverlayError(RootLegalAgreementOverlay)
