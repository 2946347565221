import React from 'react'
import { Link, NavLink, Route } from 'react-router-dom-v5-compat'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { Button, Page, HelpTagIframe } from '@behavehealth/components'
import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'
import Status from '@behavehealth/components/Status'

import { LegalAgreementOverlay } from '@behavehealth/constructs/Agreements/LegalAgreementOverlay'
import LegalAgreementTemplatesImportOverlay from '@behavehealth/components/Templates/overlays/LegalAgreementTemplatesImportOverlay'

const LegalAgreements = () => {
  return (
    <>
      <LegalAgreementsIndex />

      <AnimatedRoutes>
        <Route path="import" element={<LegalAgreementTemplatesImportOverlay useV6Router />} />
        <Route path=":id" element={<LegalAgreementOverlay useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

const LegalAgreementsIndex: React.FC = () => {
  const { isBehave } = useSettings()

  const tableProps = useDataTable({
    name: ['agreements'],
    endpoint: `/agreements`,
    params: { variant: 'legal' },
    localStorageKey: 'legal_agreements_v1',
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          return <MainCell id={data.id} as={NavLink} to={data.id} value={value} />
        },
      },
      {
        title: 'Company Signature',
        model: 'request_cosigner_signature',
        formatValue: ({ data, value }: any) => {
          if (!value) return 'Not required'

          return <Status label={`${data?.contracts_count} Signees`} />
        },
      },
      {
        title: 'Behave Health Signature',
        model: 'should_sign',
        formatValue: ({ data, value }: any) => {
          if (!value) return 'Not required'

          if (data?.cosigner_signed_at) return <Status label="Signed" color="green" />

          return <Status label="Not Signed" />
        },
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
        disableSort: true,
      },
    ],
    [],
  )

  return (
    <Page
      title="Legal Agreements"
      feature="legal_agreements"
      actions={isBehave && <Button as={Link} label="Import Behave Templates" glyph="add" color="blue" type="primary" link="import" />}
      help={<HelpTagIframe id="settings_legal_agreements" />}
    >
      <div className="grid">
        <DataTable asCard title="Legal Agreements" icon="legal_agreement_alt" columns={columns} {...tableProps} />
      </div>
    </Page>
  )
}

export default withPageError(LegalAgreements)
