import React from 'react'

import FormSection from '@behavehealth/components/Forms/FormSection'

export const QuestionCard = ({ title, children }: any) => {
  return (
    <div className="grid grid-4 border-2 border-solid border-divider rounded-[5px] px-4 pt-3 pb-4">
      {title && <h3>{title}</h3>}
      <FormSection maxWidth="100%">{children}</FormSection>
    </div>
  )
}
