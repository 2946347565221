import React from 'react'
import produce from 'immer'
import size from 'lodash/size'

import { countWord } from '../../utils/functions'
import Notifications from '../../modules/notifications'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'
import Flex from '../../components/Flex'
import TreeItem from '../../components/TreeItem'
import SummonOverlay from '../../components/SummonOverlay'
import FormSection from '../../components/Forms/FormSection'

import { withOverlayError } from '../../hocs/withOverlayError'

import { useSettings } from '../../hooks/useSettings'

import { ClaimsDataTable } from './ClaimsDataTable'
import { useDataTable } from '../../components/DataTable/useDataTable'

import { NewClaimPreviewOverlay } from './NewClaimPreviewOverlay'

const STEPS = {
  select: 'select',
  warning: 'warning',
}

const RootClaimsImportOverlay = (props: any) => {
  const { getImportedData }: any = props
  const { online }: any = useSettings()

  const [step, setStep] = React.useState(STEPS.select)
  const [importedIds, setImportedIds] = React.useState<any[]>([])
  const [duplicateIds, setDuplicateIds] = React.useState<any[]>([])

  const [previewID, setPreviewID] = React.useState<any>(null)
  const [selectedRows, setSelectedRows] = React.useState<any[]>([])

  const selectedCount = size(selectedRows)

  const tableProps = useDataTable({
    name: 'insurance_claims',
    endpoint: '/insurance_claims',
    params: { version: 'v2' },
    localStorageKey: 'insurance_claims_v1',
  })

  const handleImport = () => {
    // find duplicate service line identifiers
    if (size(importedIds) > 0) {
      const result: string[] = []

      for (const row of selectedRows) {
        if (size(row.insurance_claim_service_lines) > 0) {
          for (const line of row.insurance_claim_service_lines) {
            const id = line.identifier

            if (importedIds.includes(id)) result.push(id)
          }
        }
      }

      if (size(result) > 0) {
        setDuplicateIds(result)
        setStep(STEPS.warning)
        return
      }
    }

    importSelectedRows()
  }

  const importSelectedRows = () => {
    let newLinesCount = 0

    for (const row of selectedRows) {
      if (size(row.insurance_claim_service_lines) === 0) continue

      for (const _line of row.insurance_claim_service_lines) {
        newLinesCount++
      }
    }

    props.onSelect(selectedRows)
    props.onClose()

    Notifications.send(`Imported ${countWord('service line', newLinesCount)}`, 'positive')
  }

  const importNonDuplicateRows = () => {
    const result: any[] = []
    let newLinesCount = 0

    for (const row of selectedRows) {
      if (size(row.insurance_claim_service_lines) > 0) {
        const nonDuplicateLines: any[] = []

        // filter out duplicate service lines
        for (const line of row.insurance_claim_service_lines) {
          const id = line.identifier

          if (importedIds.includes(id)) continue

          nonDuplicateLines.push(line)
          newLinesCount++
        }

        // add row if there are non-duplicate service lines
        if (size(nonDuplicateLines) > 0) {
          const newRow = produce(row, (draft: any) => {
            draft.insurance_claim_service_lines = nonDuplicateLines
          })

          result.push(newRow)
        }
      }
    }

    props.onSelect(result)
    props.onClose()

    if (newLinesCount > 0) {
      Notifications.send(`Imported ${countWord('service line', newLinesCount)}`, 'positive')
    } else {
      Notifications.send(`No new service lines were imported`, 'warning')
    }
  }

  React.useEffect(() => {
    if (getImportedData) {
      const importedData = getImportedData()
      const result: string[] = []

      for (const item of importedData) {
        const id = item.insurance_claim_service_line?.identifier

        if (id) result.push(id)
      }

      setImportedIds(result)
    }
  }, [])

  return (
    <>
      <Overlay showBackdrop position="top" maxWidth={step === STEPS.select ? 90 : 50} onClose={props.onClose}>
        <Overlay.Header icon="accounting" title="Import Claims" />

        <Overlay.Content>
          <Section>
            {step === STEPS.warning && (
              <div className="-ml-2 !-mt-4 mb-2">
                <Button
                  label="← Back"
                  type="minimal"
                  color="blue"
                  onClick={() => {
                    setStep(STEPS.select)
                  }}
                  display="inline-flex"
                  size={200}
                />
              </div>
            )}

            <div className={step === STEPS.select ? 'block' : 'hidden'}>
              <FormSection maxWidth="100%">
                <Alert contrast glyph="info" children="Select Claims to import" />
                <ClaimsDataTable
                  canBatchSelect
                  {...tableProps}
                  activeID={previewID}
                  onRowSelectionUpdate={setSelectedRows}
                  onClick={(item) => {
                    setPreviewID(item.id)
                  }}
                />
              </FormSection>
            </div>

            {step === STEPS.warning && size(duplicateIds) > 0 && (
              <div>
                <Alert contrast type="warning" glyph="warning">
                  The selected claims contain service lines that have already been imported. You can choose to import them and create
                  duplicates, or you can import non-duplicate service lines only.
                </Alert>

                <div className="mt-2">
                  <TreeItem title={`Show duplicate service lines (${size(duplicateIds)})`}>
                    <div className="border border-solid border-divider bg-hover rounded-md px-2 py-1 text-sm">
                      {duplicateIds.map((id: string) => (
                        <div key={id} className="!border-0">
                          {id}
                        </div>
                      ))}
                    </div>
                  </TreeItem>
                </div>
              </div>
            )}
          </Section>
        </Overlay.Content>

        <Overlay.Footer online={online}>
          {step === STEPS.select && (
            <Button
              label={`Import ${countWord('Claims', selectedCount)}`}
              glyph="check"
              type="primary"
              color="green"
              flex="100 1 auto"
              onClick={handleImport}
              isDisabled={selectedCount <= 0}
            />
          )}

          {step === STEPS.warning && (
            <Flex gap="0.75rem">
              <Button
                label={`Import Duplicated Service Lines`}
                glyph="warning"
                glyphColor="orange"
                type="default"
                color="text"
                flex="1 1 auto"
                onClick={importSelectedRows}
                isDisabled={selectedCount <= 0}
              />

              <Button
                label={`Import New Service Lines Only`}
                glyph="check"
                type="primary"
                color="green"
                flex="100 1 auto"
                onClick={importNonDuplicateRows}
                isDisabled={selectedCount <= 0}
              />
            </Flex>
          )}
        </Overlay.Footer>
      </Overlay>

      <SummonOverlay
        isOpen={!!previewID}
        onClose={() => {
          setPreviewID(null)
        }}
        key={`preview-${previewID}`}
        overlay={<NewClaimPreviewOverlay key={`overlay-${previewID}`} dataID={previewID} />}
      />
    </>
  )
}

export const ClaimsImportOverlay = withOverlayError(RootClaimsImportOverlay)
