export const AUTHORITY_FORMS: any = {
  accreditation_application: {
    id: 'accreditation_application',
    name: 'Accreditation Application',
    slug: 'accreditation-application',
    icon: 'certifications_inspections',
    category: 'certification',
    formType: 'authority_form',
  },
  // agreement_form: {
  //   id: 'agreement_form',
  //   name: 'Agreement Form',
  //   slug: 'agreement-form',
  //   icon: 'certifications_inspections',
  //   category: 'agreements',
  // },
  application_form: {
    id: 'application_form',
    name: 'Application Form',
    slug: 'application-form',
    icon: 'certifications_inspections',
    category: 'certification',
    formType: 'authority_form',
  },
  certification_application: {
    id: 'certification_application',
    name: 'Certification Application',
    slug: 'certification-application',
    icon: 'certifications_inspections',
    category: 'certification',
    formType: 'authority_form',
  },
  contact_house_information: {
    id: 'contact_house_information',
    name: 'Contact & House Information',
    slug: 'contact-house-information',
    icon: 'certifications_inspections',
    category: 'certification',
    formType: 'authority_form',
  },
  document_review: {
    id: 'document_review',
    name: 'Document Review',
    slug: 'document-review',
    icon: 'certifications_inspections',
    category: 'inspection',
    formType: 'authority_form',
  },
  site_review: {
    id: 'site_review',
    name: 'Site Review',
    slug: 'site-review',
    icon: 'certifications_inspections',
    category: 'inspection',
    formType: 'authority_form',
    isEvaluatorOnly: true,
  },
  grievance_form: {
    id: 'grievance_form',
    name: 'Grievance Form',
    slug: 'grievance-form',
    icon: 'grievance_incident_forms',
    category: 'complaint',
    formType: 'authority_complaint',
  },
}

export const CATEGORIES = {
  administrative_operations: {
    id: 'administrative_operations',
    name: 'Administrative Operations',
  },
  physical_environment: {
    id: 'physical_environment',
    name: 'Physical Environment',
  },
  recovery_support: {
    id: 'recovery_support',
    name: 'Recovery Support',
  },
  good_neighbor: {
    id: 'good_neighbor',
    name: 'Good Neighbor',
  },
  informational: {
    id: 'informational',
    name: 'Informational',
  },
}

export const SUBCATEGORIES = {
  operate_with_integrity: {
    id: 'operate_with_integrity',
    name: 'Operate with integrity',
  },
  uphold_residents_rights: {
    id: 'uphold_residents_rights',
    name: "Uphold residents' rights",
  },
  create_culture_of_empowerment: {
    id: 'create_culture_of_empowerment',
    name: 'Create a culture of empowerment where residents engage in governance and leadership',
  },
  develop_staff_abilities: {
    id: 'develop_staff_abilities',
    name: 'Develop staff abilities to apply the Social Model',
  },
  home_like_environment: {
    id: 'home_like_environment',
    name: 'Provide a home-like environment',
  },
  safe_and_healthy_environment: {
    id: 'safe_and_healthy_environment',
    name: 'Promote a safe and healthy environment',
  },
  facilitate_active_recovery: {
    id: 'facilitate_active_recovery',
    name: 'Facilitate active recovery and recovery community engagement',
  },
  model_prosocial_behaviors: {
    id: 'model_prosocial_behaviors',
    name: 'Model prosocial behaviors and relationship enhancement skills',
  },
  good_neighbor: {
    id: 'good_neighbor',
    name: 'Be a good neighbor',
  },
  basic_details: {
    id: 'basic_details',
    name: 'Basic Details',
  },
  cultivate_sense_of_belonging: {
    id: 'cultivate_sense_of_belonging',
    name: "Cultivate the resident's sense of belonging and responsibility for community",
  },
}
