import React from 'react'
import { tint } from 'polished'
import clsx from 'clsx'
import compact from 'lodash/compact'
import isBoolean from 'lodash/isBoolean'
import isFunction from 'lodash/isFunction'
import isUndefined from 'lodash/isUndefined'
import size from 'lodash/size'

import { COLORS, HARD_SHADOW } from '../../../theme'
import { useFormField } from '../../Forms/hooks/useFormField'
import { useFuse } from '../../../hooks/useFuse'
import { useGet } from '../../../hooks/useNewAPI'
import { useStore } from '../utils/useStore'
import { withFormContext } from '../../Forms/context'

import Avatar from '../../../components/Avatar'
import Flex from '../../../components/Flex'
import Glyph from '../../../components/Glyph'
import State from '../../../components/State'
import Switch from '../../../components/Forms/Switch'
import Tooltip from '../../../components/Tooltip'

import { PopoverMenu, CustomPopoverMenuItem } from '../../../components/PopoverMenu'

import { WorksheetSearchInput } from '../WorksheetSearchInput'
import SummonOverlay from '../../../components/SummonOverlay'
import Overlay from '../../../components/Overlay'

const RootObjectSelectorCell = (props: any) => {
  const { canEdit, column, form, isEditing, model, onBlur, rowData, setValue, validations, value } = props

  const dataMap: any = useStore((state: any) => state.dataMap)
  const updateField: any = useStore((state: any) => state.updateField)
  const showInvalid: any = useStore((state: any) => state.showInvalid)

  const config = column?.config
  const isLocalData = size(config?.data) > 0

  const endpoint = isFunction(config?.endpoint) ? config?.endpoint({ rowData }) : config?.endpoint
  const queryKey = isFunction(config?.queryKey) ? config?.queryKey({ rowData }) : config?.queryKey
  const queryParams = config?.queryParams

  const [searchTerm, setSetSearchTerm] = React.useState('')
  const endpointRef = React.useRef(endpoint)

  const {
    data: apiData,
    isLoading,
    isRefetching,
  }: any = useGet({
    name: compact([queryKey, queryParams]).flat(),
    url: endpoint,
    params: queryParams,
    options: {
      // keepPreviousData: true,
      enabled: canEdit && isEditing && !!endpoint && !!queryKey,
    },
  })

  const data = React.useMemo(() => {
    if (size(config?.data) > 0) return config.data
    else return apiData
  }, [config?.data, apiData])

  const { formActions, formState } = useFormField({
    model: model,
    form: form,
    initialValue: value,
    validations: validations,
    isRelation: true,
    isPolymorphic: config?.isPolymorphic,
    isValid: size(validations) === 0,
  })

  const { isInvalid } = formState

  const searchData = useFuse(data, searchTerm, { keys: ['name'] })

  const isEmpty = size(data) === 0
  const idModel = `${column.model}_id`

  const [isOpen, setIsOpen] = React.useState(isEditing)
  const [selectedIds, setSelectedIds]: any = React.useState([])
  const [hideSelected, setHideSelected]: any = React.useState(!!config?.shouldHideSelected)

  const showHideSelectedToggle = !!config?.shouldHideSelected || !!config?.showHideSelectedToggle

  const handleClose = () => {
    setSetSearchTerm('')

    if (onBlur) onBlur()
  }

  React.useEffect(() => {
    if (!isOpen) return

    const formData = form.getFormValue()
    const newRestrictedIds = []

    for (const rowId in formData) {
      const rowData = formData[rowId]

      if (rowData[idModel]) newRestrictedIds.push(rowData[idModel])
    }

    setSelectedIds(newRestrictedIds)
  }, [isOpen, dataMap])

  // Sync props value with state value
  React.useEffect(() => {
    setValue(props.value)
  }, [props.value])

  // Update form state
  React.useEffect(() => {
    if (!isUndefined(value)) {
      formActions.setValue(value)
      updateField({ model, value })
    }
  }, [model, value])

  // Clear value when dependant endpoint changes
  React.useEffect(() => {
    if (!endpointRef.current || endpoint === endpointRef.current) return

    endpointRef.current = endpoint

    setValue(null)
  }, [endpoint, endpointRef.current])

  const triggerClasses = clsx(
    size(value) === 0 && 'is-empty',
    isEditing && !config?.tableSelector && 'is-editing',
    isInvalid && showInvalid && 'is-invalid',
  )

  const trigger = (
    <div className={triggerClasses} css={STYLES.trigger}>
      {value && (
        <div className="select-trigger-value">
          {!isUndefined(value.avatar) && <Avatar src={value.avatar} size={20} initials={value.name} className="select-trigger-avatar" />}
          <div className="select-trigger-label">{config?.selectTitle?.(value) || value?.name}</div>
        </div>
      )}

      {canEdit && <Glyph glyph="triangle_down" size={9} className="select-trigger-triangle" />}
    </div>
  )

  if (!canEdit) return trigger

  if (config?.tableSelector) {
    return (
      <SummonOverlay
        overlay={
          <SelectorTableOverlay
            title={column.title}
            tableSelector={config.tableSelector}
            onSelect={(item) => {
              setValue(item)
            }}
          />
        }
      >
        <div>{trigger}</div>
      </SummonOverlay>
    )
  }

  return (
    <PopoverMenu
      isOpen={isOpen}
      side={null}
      align={null}
      onOpenUpdated={(currentIsOpen: boolean) => {
        if (!currentIsOpen) handleClose()

        setIsOpen(currentIsOpen)
      }}
      trigger={trigger}
    >
      {!data || isLoading || isRefetching || isEmpty ? (
        <State isLoading={isLoading || isRefetching} isEmpty={isEmpty} title={column?.title} />
      ) : (
        <>
          {size(data) > 0 && (
            <div
              css={{
                position: 'sticky',
                top: 0,
                borderBottom: `1px solid ${COLORS.divider}`,
                background: COLORS.white,
                boxShadow: HARD_SHADOW(2),
                zIndex: 3,
              }}
            >
              <WorksheetSearchInput
                value={searchTerm}
                onChange={setSetSearchTerm}
                onClear={() => {
                  setSetSearchTerm('')
                }}
              />

              {showHideSelectedToggle && size(selectedIds) > 0 && (
                <div css={{ padding: '0.25rem 0.4rem', background: COLORS.hover }}>
                  <Flex centerY gap="0.4rem" justifyContent="flex-start">
                    <Switch
                      horizontal
                      label={null}
                      withHover={false}
                      size={100}
                      value={hideSelected}
                      onUpdate={(o) => {
                        setHideSelected(o.value)
                      }}
                    />
                    <div css={{ fontWeight: 600, fontSize: '0.85rem' }}>Hide Previously Selected</div>
                  </Flex>
                </div>
              )}
            </div>
          )}

          {searchData.map((record: any) => {
            const isActive = value?.id === record.id
            const isSelected = selectedIds.includes(record.id)
            const disabledConfig = config?.getIsOptionDisabled?.(record)
            const disabledMessage = disabledConfig?.message
            const isDisabled = isBoolean(disabledConfig) ? disabledConfig : disabledConfig?.isDisabled

            if (hideSelected && isSelected && !isActive) return null

            return (
              <SelectItem
                key={record.id}
                data={record}
                disabledMessage={disabledMessage}
                isActive={isActive}
                isDisabled={isDisabled}
                isSelected={showHideSelectedToggle && isSelected}
                selectDescription={config?.selectDescription}
                selectTitle={config?.selectTitle}
                showAvatars={config?.showAvatars}
                onClick={() => {
                  if (isDisabled) return
                  setValue(record)
                }}
              />
            )
          })}
        </>
      )}
    </PopoverMenu>
  )
}

const SelectorTableOverlay = (props: any) => {
  const { title, tableSelector, onSelect, onClose } = props

  if (!tableSelector) return null

  const TableSelectorTag = tableSelector

  return (
    <Overlay showBackdrop closeOnBackdrop onClose={onClose} position="center" maxWidth={90}>
      <Overlay.Header title={title} />
      <Overlay.Content>
        <TableSelectorTag
          onSelect={(item) => {
            onSelect?.(item)
            onClose?.()
          }}
        />
      </Overlay.Content>
    </Overlay>
  )
}

const SelectItem = (props: any) => {
  const { data, disabledMessage, isActive, isDisabled, isSelected, onClick, selectDescription, selectTitle, showAvatars } = props

  if (!data) return null

  const title = selectTitle?.(data) || data?.name
  const description = selectDescription?.(data)

  const selectItemClasses = clsx(isSelected && 'is-selected', isDisabled && 'is-disabled')

  return (
    <CustomPopoverMenuItem css={STYLES.selectItem} className={selectItemClasses} isActive={isActive} onClick={onClick}>
      <div className="select-item-graphic">{isActive && <Glyph glyph="check" size={10} color={COLORS.green} />}</div>

      {(!isUndefined(data.avatar) || showAvatars) && (
        <div className="select-item-avatar">
          <Avatar src={data.avatar} size={22} initials={title} />
        </div>
      )}

      {title && (
        <div>
          <div className="select-item-title">{title}</div>
          {description && <div className="select-item-description">{description}</div>}
        </div>
      )}

      {isDisabled && disabledMessage && (
        <Tooltip color={COLORS.red} content={disabledMessage} css={STYLES.selectItemTooltip} portal="radix" />
      )}
    </CustomPopoverMenuItem>
  )
}

const STYLES = {
  trigger: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    flex: '1 1 auto',
    padding: '0.2rem',
    paddingLeft: '0.4rem',
    paddingRight: '1.2rem',
    overflow: 'hidden',

    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,

    '.select-trigger-value': {
      display: 'flex',
      flexWrap: 'nowrap',
      flex: '1 1 auto',
      maxWidth: '100%',
    },

    '.select-trigger-avatar': {
      marginRight: '0.4rem',
    },

    '.select-trigger-label': {
      fontWeight: 500,
      flex: '1 1 auto',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    '.select-trigger-glyph': {
      marginRight: '0.5rem',
      pointerEvents: 'none',
    },

    '.select-trigger-triangle': {
      position: 'absolute',
      right: '0.25rem',
      top: '50%',
      transform: 'translateY(-50%)',
      pointerEvents: 'none',
    },

    '&.is-empty': {
      fontStyle: 'italic',
      color: COLORS.textStronglyMuted,
    },

    '&.is-editing': {
      borderRadius: 3,
      background: COLORS.white,
      boxShadow: 'var(--input-focus-box-shadow)',
    },

    '&.is-invalid': {
      display: 'block',
      background: tint(0.85, COLORS.red),
    },
  },

  selectItem: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    cursor: 'pointer',
    fontSize: '0.88rem',
    width: '100%',
    lineHeight: 'normal',
    paddingTop: '0.4rem',
    paddingBottom: '0.4rem',
    paddingRight: '1rem',
    fontWeight: 600,

    '&:hover': {
      fontWeight: 600,
      background: COLORS.hover,
    },

    '.select-item-graphic': {
      opacity: 0,
      visibility: 'hidden',
      width: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    '.select-item-avatar': {
      marginRight: '0.5rem',
    },

    '.select-item-title': {
      fontWeight: 600,
    },

    '.select-item-description': {
      fontWeight: 400,
      color: COLORS.textMuted,
    },

    '&.is-selected': {
      backgroundColor: tint(0.92, COLORS.blue),

      '&:hover': {
        backgroundColor: tint(0.92, COLORS.blue),
      },

      '.select-item-graphic': {
        opacity: 1,
        visibility: 'visible',
      },
    },

    '&.is-disabled': {
      opacity: 0.5,
      cursor: 'not-allowed !important',
      backgroundColor: tint(0.92, COLORS.gray),

      '&:hover': {
        backgroundColor: tint(0.92, COLORS.gray),
      },

      '.select-item-graphic': {
        opacity: 1,
        visibility: 'visible',
      },
    },

    '&.is-active': {
      backgroundColor: tint(0.92, COLORS.green),

      '&:hover': {
        backgroundColor: tint(0.92, COLORS.green),
      },

      '.select-item-graphic': {
        opacity: 1,
        visibility: 'visible',
      },
    },
  },

  selectItemTooltip: {
    marginLeft: 'auto',
    position: 'relative',
    zIndex: 3,
  },
}

export const ObjectSelectorCell: any = withFormContext(RootObjectSelectorCell)
