import React from 'react'
import { Input } from './Input'
import { withFormContext } from './context'
import snakeCase from 'lodash/snakeCase'

import { DEFAULT_EMPTY_VALUE } from '../../utils/constants'
import { isBlank, nicePhoneNo } from '../../utils/functions'

import LinkValue from '../LinkValue'

class PhoneInput extends Input {
  readOnlyRender = () => {
    if (isBlank(this.state.value)) return DEFAULT_EMPTY_VALUE

    return (
      <LinkValue
        href={`tel:${this.state.value}`}
        testKey={`${this.props.testKey || snakeCase(this.props.label)}_value`}
        value={nicePhoneNo(this.state.value)}
        glyph={this.props.glyph}
      />
    )
  }
}

PhoneInput.defaultProps = {
  type: 'tel',
  glyph: 'phone',
  defaultValidations: {
    length: {
      maximum: 14,
      message: 'Please use a valid phone number format (e.g. 123-456-7890)',
    },
  },
  validateOn: 'blur-change',
}

export default withFormContext(PhoneInput)
