import React from 'react'

import Form from '../../components/Forms/Form'
import Input from '../../components/Forms/Input'
import RichTextEditor from '../../components/Forms/RichTextEditor'

import { QuoteSection } from './QuoteSection'
import { QuoteIframeForm } from './QuoteIframeForm'
import { PreviewData } from './PreviewData'

export const QuotePaymentForm = (props: any) => {
  const { isEditable, formData, onValidationUpdate, onUpdate } = props

  const form = React.useRef(null)

  return (
    <Form
      isCompact
      getForm={form}
      isEditable={isEditable}
      initialModel={formData}
      onValidationUpdate={onValidationUpdate}
      onUpdate={onUpdate}
    >
      <QuoteSection
        showVariables
        title="Payment"
        onUpdate={onUpdate}
        isEditable={isEditable}
        isEnabled={formData?.use_payment === undefined || !!formData?.use_payment}
        enabledModel="use_payment"
      >
        <QuoteIframeForm model="payment" isEditable={isEditable} onUpdate={onUpdate} formData={formData} />

        <RichTextEditor label="Payment Text" model="payment_text" />
        <PreviewData isEditable={isEditable} model="payment_text" />

        <Input label="Payment Legal Acknowledgement Text" model="payment_legal_language_text" />
        <PreviewData isEditable={isEditable} model="payment_legal_language_text" />
      </QuoteSection>
    </Form>
  )
}
