import React from 'react'
import { geolocated } from 'react-geolocated'
import { useLocation, useHistory } from 'react-router-dom'
import size from 'lodash/size'

import { address, countWord } from '../../utils/functions'
import { css } from '../../theme'
import { useCreate } from '../../hooks/useNewAPI'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'
import Notifications from '../../modules/notifications'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Grid from '../../components/Grid'
import Overlay from '../../components/Overlay'
import useAnalytics from '../../hooks/useAnalytics'

import { QUICK_ADD } from '../../components/Worksheet/config'
import { Worksheet } from '../../components/Worksheet/Worksheet'
import { AllClientsTable } from '../../components/Forms/Selectors/tables/AllClientsTable'

export const RootGeolocatedLocationCheckInWorksheetOverlay = (props: any) => {
  const { coords, invalidateKeys, isGeolocationAvailable, onClose, onSuccess } = props

  const { trackEvent } = useAnalytics()

  const form = React.useRef()
  const history = useHistory()
  const location = useLocation()

  const [isValid, setIsValid] = React.useState(false)
  const [rowsCount, setRowsCount] = React.useState(0)

  const { tenant } = useSettings()

  const currentLat = coords?.latitude
  const currentLon = coords?.longitude

  const { mutateAsync: save, isLoading: isSaving }: any = useCreate({
    name: ['create-location-checks'],
    url: `/location_checks/batch`,
    invalidate: 'location-checks',
    invalidateKeys: invalidateKeys,
    onSuccess: () => {
      Notifications.send(`Successfully created ${countWord("Location Check-In's", rowsCount)}`, 'positive')

      trackEvent({ name: 'Worksheet Saved', params: { worksheetType: 'Location Check-In' } })

      if (onSuccess) onSuccess()
    },
  })

  const close = () => {
    if (onClose) return onClose()

    const url = location?.parent ? location.parent.url : location.pathname.substr(0, location.pathname.lastIndexOf('/'))
    history.push(url)
  }

  const handleSave = async () => {
    const data = form.current.getFormValue()

    if (size(data) === 0) return

    const result = []

    for (const id in data) {
      result.push({
        ...data[id],
        lat: data[id]?.address?.coords?.lat || currentLat,
        lon: data[id]?.address?.coords?.lon || currentLon,
      })
    }

    await save(result)

    close()
  }

  React.useEffect(() => {
    trackEvent({ name: 'Worksheet Opened', params: { worksheetType: 'Location Check-In' } })
  }, [])

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Client',
        model: 'resident',
        type: 'object_selector',
        config: {
          tableSelector: AllClientsTable,
          shouldHideSelected: true,
          endpoint: '/residents',
          queryKey: 'residents',
          queryParams: { status: 'current' },
          selectTitle: (data: any) => data.name,
          quickAdd: QUICK_ADD.all_clients,
          validations: {
            presence: {
              message: 'Please select a client',
            },
          },
        },
      },
      {
        title: 'Check-In Date',
        model: 'checkin_at',
        type: 'date_time',
        config: {
          defaultTo: 'now',
          validations: {
            presence: {
              message: 'Please enter a check-in date',
            },
          },
        },
      },
      {
        title: 'Category',
        model: 'category',
        type: 'select',
        config: {
          options: [
            { label: 'Location', value: 'property' },
            { label: 'Organization', value: 'organization' },
            { label: 'Meeting', value: 'meeting' },
            { label: 'Other', value: 'other' },
          ],
          validations: {
            presence: {
              message: 'Please select a category',
            },
          },
        },
      },
      {
        title: 'Location',
        model: 'location',
        type: 'object_selector',
        id: 'property',
        config: {
          isPolymorphic: true,
          endpoint: '/houses',
          queryKey: 'properties',
          queryParams: { category: 'housing,mixed_category' },
          selectTitle: (data: any) => data.name,
          selectDescription: (data: any) => address(data.address),
          getIsApplicable: ({ rowData }: any) => rowData.category === 'property',
          validations: {
            presence: {
              message: 'Please select a property',
            },
          },
        },
      },
      {
        title: 'Organization',
        model: 'location',
        id: 'organization',
        type: 'object_selector',
        config: {
          isPolymorphic: true,
          endpoint: '/organizations',
          queryKey: 'organizations',
          selectTitle: (data: any) => data.name,
          selectDescription: (data: any) => address(data.address),
          getIsApplicable: ({ rowData }: any) => rowData.category === 'organization',
          validations: {
            presence: {
              message: 'Please select an organization',
            },
          },
        },
      },
      {
        title: 'Location/Meeting Name',
        model: 'name',
        config: {
          getIsApplicable: ({ rowData }: any) => rowData.category === 'meeting' || rowData.category === 'other',
          validations: {
            presence: {
              message: 'Please enter a meeting name',
            },
          },
        },
      },
      {
        title: 'Meeting Address',
        model: 'address',
        type: 'address',
        config: {
          getIsApplicable: ({ rowData }: any) => rowData.category === 'meeting',
          validations: {
            presence: {
              message: 'Please enter a meeting address',
            },
          },
        },
      },
      {
        title: 'Notes',
        model: 'notes',
        type: 'textarea',
      },
    ] as const
  }, [])

  return (
    <Overlay showBackdrop onClose={onClose} position="center" maxWidth={140}>
      <Overlay.Header title="Create Location Check-In's" icon="recovery_navigation" />

      <Overlay.Content className="!p-4">
        <Grid gap="1rem" className="!pb-3">
          {!isGeolocationAvailable && (
            <Alert type="warning" glyph="info">
              The device you are currently using does not have support for Location Checks. Please use a different device or get in touch
              with {tenant.name} at <a href={`mailto:${tenant.email}`}>{tenant.email}</a> or{' '}
              <a href={`tel:${tenant.phone_no}`}>{tenant.phone_no}</a>
            </Alert>
          )}

          {isGeolocationAvailable && (
            <Worksheet
              asCard
              getForm={form}
              title="Location Check-In's"
              allow="create-update-delete"
              columns={columns}
              onValidationUpdate={setIsValid}
              onRowsCountUpdate={setRowsCount}
            />
          )}
        </Grid>
      </Overlay.Content>

      {isGeolocationAvailable && (
        <Overlay.Footer>
          <Button
            label={`Save ${countWord("Location Check-In's", rowsCount)}`}
            glyph="check"
            type="primary"
            color="green"
            onClick={handleSave}
            isLoading={isSaving}
            isDisabled={!isValid}
          />
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

export const GeolocatedLocationCheckInWorksheetOverlay = geolocated({
  positionOptions: {
    enableHighAccuracy: true,
  },
})(withOverlayError(RootGeolocatedLocationCheckInWorksheetOverlay))
