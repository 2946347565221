import React from 'react'
import size from 'lodash/size'

import AddressInput from '../../../components/Forms/AddressInput'
import AmountInput from '../../../components/Forms/AmountInput'
import Checkbox from '../../../components/Forms/Checkbox'
import CheckboxGroup from '../../../components/Forms/CheckboxGroup'
import DateInput from '../../../components/Forms/DateInput'
import DateTimeInput from '../../../components/Forms/DateTimeInput'
import EmailInput from '../../../components/Forms/EmailInput'
import Flex from '../../../components/Flex'
import Input from '../../../components/Forms/Input'
import NumberInput from '../../../components/Forms/NumberInput'
import PhoneInput from '../../../components/Forms/PhoneInput'
import Radio from '../../../components/Forms/Radio'
import RadioGroup from '../../../components/Forms/RadioGroup'
import RichTextEditor from '../../../components/Forms/RichTextEditor'
import SignaturePad from '../../../components/Forms/SignaturePad'
import Status from '../../../components/Status'
import Textarea from '../../../components/Forms/Textarea'
import URLInput from '../../../components/Forms/URLInput'
import YesNoRadioGroup from '../../../components/Forms/elements/YesNoRadioGroup'

import { AttachmentsInput } from './AttachmentsInput'
import { useSettings } from '../../../hooks/useSettings'
import { AuthorityFormLevelStatus } from '../AuthorityFormLevelStatus'

import { RENDERERS } from '../questions'

import clsx from 'clsx'

export const QuestionRenderer = (props: any) => {
  const { isInfoVisible, question, statusesClassName } = props

  const { isBehave } = useSettings()

  if (!question) return null

  const Tag = isBehave ? 'div' : React.Fragment

  return (
    <Tag>
      {isBehave && isInfoVisible && (
        <div className={clsx('flex items-center flex-nowrap mt-3 flex-[0_0_auto]', statusesClassName)}>
          <Status small label={question.identifier} className="!bg-text-muted !text-white" />

          <AuthorityFormLevelStatus small status={question.level} className="ml-2" />

          {size(question.states) > 0 && (
            <div className="flex items-center">
              {question.states.map((state: any) => (
                <Status key={state} small label={state} color="vividBlue" className="ml-2" />
              ))}
            </div>
          )}
        </div>
      )}

      <Question {...props} />
    </Tag>
  )
}

const Question = (props: any) => {
  const { authorityName = '[Organization Name]', isEvaluatorOnly, question, isEditable, validations } = props

  const { inputProps, inputType, model } = question

  let label =
    isEvaluatorOnly && question.evaluatorCriteria
      ? question.evaluatorCriteria
      : typeof question.label === 'function'
      ? question.label({ authorityName })
      : question.label || '[…]'

  if (typeof label === 'string' && label.startsWith('"') && label.endsWith('"')) {
    label = label.slice(1, -1)
  }

  if (RENDERERS.hasOwnProperty(question.identifier)) {
    return RENDERERS[question.identifier]({ question, isEditable, validations })
  }

  if (inputType === 'rich_text') {
    return (
      <div className="pb-2">
        <RichTextEditor withHover={false} isEditable={isEditable} validations={validations} label={label} model={model} {...inputProps} />
      </div>
    )
  }

  if (inputType === 'boolean') {
    return (
      <YesNoRadioGroup withHover={false} isEditable={isEditable} validations={validations} label={label} model={model} {...inputProps} />
    )
  }

  if (inputType === 'amount_with_frequency') {
    return (
      <Flex gap="0.75rem">
        <AmountInput withHover={false} isEditable={isEditable} validations={validations} label={label} model={model} {...inputProps} />

        <RadioGroup
          withHover={false}
          isEditable={isEditable}
          validations={validations}
          label="Frequency"
          model={`${model}_frequency`}
          layout="horizontal-dense"
        >
          <Radio value="weekly" label="Weekly" />
          <Radio value="biweekly" label="Biweekly" />
          <Radio value="monthly" label="Monthly" />
        </RadioGroup>
      </Flex>
    )
  }

  if (inputType === 'amount') {
    return <AmountInput withHover={false} isEditable={isEditable} validations={validations} label={label} model={model} {...inputProps} />
  }

  if (inputType === 'attachments') {
    return (
      <AttachmentsInput withHover={false} isEditable={isEditable} validations={validations} label={label} model={model} {...inputProps} />
    )
  }

  if (inputType === 'rich_text_attachments') {
    return (
      <>
        <div className="grid gap-4 pb-2">
          <RichTextEditor withHover={false} isEditable={isEditable} validations={validations} label={label} model={model} {...inputProps} />

          <AttachmentsInput
            withHover={false}
            isEditable={isEditable}
            validations={validations}
            label={label}
            model={`${model}_attachments`}
            {...inputProps}
          />
        </div>
      </>
    )
  }

  if (inputType === 'address') {
    return <AddressInput withHover={false} isEditable={isEditable} validations={validations} label={label} model={model} {...inputProps} />
  }

  if (inputType === 'input') {
    return <Input withHover={false} isEditable={isEditable} validations={validations} label={label} model={model} {...inputProps} />
  }

  if (inputType === 'initials') {
    return (
      <Input
        withHover={false}
        isEditable={isEditable}
        validations={validations}
        label={label}
        model={model}
        size={6}
        description="Enter your initials"
        {...inputProps}
      />
    )
  }

  if (inputType === 'textarea') {
    return <Textarea withHover={false} isEditable={isEditable} validations={validations} label={label} model={model} {...inputProps} />
  }

  if (inputType === 'radio' && size(question.options) > 0) {
    return (
      <RadioGroup
        withHover={false}
        isEditable={isEditable}
        validations={validations}
        label={label}
        model={model}
        layout="vertical-dense"
        {...inputProps}
      >
        {Object.entries(question.options).map(([value, label]: any) => (
          <Radio key={value} value={value} label={label} />
        ))}
      </RadioGroup>
    )
  }

  if (inputType === 'checkbox_group' && size(question.options) > 0) {
    return (
      <CheckboxGroup
        withHover={false}
        isEditable={isEditable}
        validations={validations}
        label={label}
        layout="vertical-dense"
        {...inputProps}
      >
        {Object.entries(question.options).map(([value, label]: any) => (
          <Checkbox key={value} label={label} model={`${model}.${value}`} />
        ))}
      </CheckboxGroup>
    )
  }

  if (inputType === 'checkbox') {
    return <Checkbox withHover={false} isEditable={isEditable} validations={validations} label={label} model={model} {...inputProps} />
  }

  if (inputType === 'date') {
    return <DateInput withHover={false} isEditable={isEditable} validations={validations} label={label} model={model} {...inputProps} />
  }

  if (inputType === 'date_time') {
    return <DateTimeInput withHover={false} isEditable={isEditable} validations={validations} label={label} model={model} {...inputProps} />
  }

  if (inputType === 'number') {
    return (
      <NumberInput
        withHover={false}
        isEditable={isEditable}
        validations={validations}
        label={label}
        model={model}
        size={6}
        {...inputProps}
      />
    )
  }

  if (inputType === 'url') {
    return <URLInput withHover={false} isEditable={isEditable} validations={validations} label={label} model={model} {...inputProps} />
  }

  if (inputType === 'email') {
    return <EmailInput withHover={false} isEditable={isEditable} validations={validations} label={label} model={model} {...inputProps} />
  }

  if (inputType === 'phone') {
    return <PhoneInput withHover={false} isEditable={isEditable} validations={validations} label={label} model={model} {...inputProps} />
  }

  if (inputType === 'signature') {
    return (
      <SignaturePad
        withHover={false}
        isEditable={isEditable}
        validations={validations}
        label={label}
        model={model}
        allowPin={false}
        showAccept={false}
        showSignedBy={false}
        {...inputProps}
      />
    )
  }

  return null
}
