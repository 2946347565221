import React from 'react'
import { useQueryClient } from 'react-query'
import size from 'lodash/size'

import { mapToArray, minutesBetween } from '../../../../../utils/functions'
import { useCreate, getPrefix } from '../../../../../hooks/useNewAPI'

import { ClinicalNoteGroupBuilderOverlay } from '../../../../../constructs/ClinicalNotes/ClinicalNoteGroupBuilderOverlay'
import { ClinicalNoteGroupOverlay } from '../../../../../constructs/ClinicalNotes/ClinicalNoteGroupOverlay'
import { ClinicalNoteOutcomeItem } from '../common/ClinicalNoteOutcomeItem'
import { EventOutcomesFooter } from '../EventOutcomesFooter'

import SummonOverlay from '../../../../SummonOverlay'
import { getClientAttendance } from '../common/functions'

type Props = {
  event: any
  outcomes?: any
}

export const GroupTherapyOutcome = ({ event, outcomes = [] }: Props) => {
  const queryClient = useQueryClient()

  if (!event) return null

  return (
    <>
      {outcomes.map((outcome: any) => {
        const data = outcome?.reference

        if (!data) return null

        return (
          <SummonOverlay
            key={outcome?.id}
            permission="clinical_notes.view"
            overlay={
              <ClinicalNoteGroupOverlay
                showBackdrop
                dataID={data?.id}
                position="center"
                initialData={data}
                onSaveSuccessful={() => {
                  queryClient.invalidateQueries([getPrefix(), 'event', event?.id])
                }}
                onDeleteSuccessful={() => {
                  queryClient.invalidateQueries([getPrefix(), 'event', event?.id])
                }}
              />
            }
          >
            <ClinicalNoteOutcomeItem data={data} />
          </SummonOverlay>
        )
      })}

      <AddMore event={event} />
    </>
  )
}

export const AddMore = ({ event, className }: Props) => {
  const { mutateAsync, isLoading: isSaving }: any = useCreate({
    name: ['event-outcomes'],
    url: `/event_outcomes`,
    invalidate: ['event', event?.id],
    invalidateKeys: ['events'],
  })

  const residents = React.useMemo(() => {
    const result = {}

    if (!event) return mapToArray(result)

    // Add the resident from the event
    if (event?.resident?.id) {
      result[event.resident.id] = event.resident
    }

    // Add residents from the event
    if (size(event?.residents) > 0) {
      for (const resident of event.residents) {
        if (resident?.id && !result.hasOwnProperty(resident.id)) {
          result[resident.id] = resident
        }
      }
    }

    // Add residents from the event attendances
    if (size(event?.event_attendances) > 0) {
      for (const attendance of event.event_attendances) {
        if (attendance?.resident?.id && !result.hasOwnProperty(attendance.resident.id)) {
          result[attendance.resident.id] = attendance.resident
        }
      }
    }

    return mapToArray(result)
  }, [event])

  if (!event?.outcomes_uuid) return null

  return (
    <SummonOverlay
      overlay={
        <ClinicalNoteGroupBuilderOverlay
          dataID="new"
          position="center"
          isSaving={isSaving}
          save={async (data: any) => {
            await mutateAsync({
              data: data,
              event_id: event?.id,
              category: 'group_clinical_notes',
            })
          }}
          initialData={{
            uuid: event.outcomes_uuid,
            topic: event.title,
            description: event.public_description,
            started_at: event.started_at,
            duration: event.start_time && event.end_time ? minutesBetween(event.start_time, event.end_time) : null,

            ...(size(residents) > 0 && {
              group_notes: residents?.map?.((resident: any) => ({
                client: resident,
                client_id: resident.id,
                ...getClientAttendance(resident.id, event.event_attendances),
              })),
            }),
          }}
        />
      }
    >
      <EventOutcomesFooter addLabel="Add Group Note" permission="clinical_notes.create" className={className} />
    </SummonOverlay>
  )
}

export default GroupTherapyOutcome
