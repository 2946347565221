import React from 'react'
import { useMedia } from 'use-media'

import { withPageError } from '../hocs/withPageError'

import { COLORS, MEDIA_QUERY, BREAKPOINT, findFeature } from '../theme'
import PageHeader from './PageHeader'
import State from './State'
import Scroll from './Scroll'
import Wrapper from './Wrapper'

const Page = ({
  actions,
  avatar,
  background,
  breakpoint,
  centerContent,
  children,
  childrenGap,
  testKey,
  description,
  emptyActions,
  emptyDescription,
  feature,
  flex,
  icon,
  glyph,
  glyphColor,
  isBlocked,
  isEmpty,
  isLoading,
  registerContainer,
  showBack,
  showHeader,
  stickyHeader,
  subtitle,
  title,
  titleAside,
  help,
  tooltip,
  tooltipColor,
  useWrapper,
  width,
  plan,
  addon,
  ...rest
}: any) => {
  const ref = React.useRef(null)

  const foundFeature = findFeature(feature)
  const isBreakpointActive = useMedia({ minWidth: BREAKPOINT[breakpoint] })

  let sTitle = title || foundFeature.title
  let sIcon = icon || foundFeature.icon
  let sTooltip = tooltip || foundFeature.tooltip

  const isProcessing = isLoading || isEmpty || isBlocked

  return (
    <Scroll
      width={width}
      flex={flex}
      centerContent={centerContent}
      ref={registerContainer && registerContainer(ref)}
      css={[
        styles,
        {
          background,
          minHeight: isProcessing && '100vh',
        },
      ]}
      {...rest}
    >
      {showHeader && (title || feature) && (
        <PageHeader
          testKey={title || feature}
          showBack={showBack}
          aside={actions}
          avatar={avatar}
          description={description}
          feature={foundFeature}
          icon={glyph ? null : sIcon}
          glyph={glyph}
          glyphColor={glyphColor}
          isEmpty={isEmpty}
          isLoading={isLoading}
          subtitle={subtitle}
          title={sTitle}
          titleAside={titleAside}
          tooltip={sTooltip}
          tooltipColor={tooltipColor}
          isSticky={isBreakpointActive}
        />
      )}

      {isProcessing ? (
        <State
          testKey={testKey}
          icon={foundFeature.icon}
          title={foundFeature.title}
          isEmpty={isEmpty}
          isBlocked={isBlocked}
          isLoading={isLoading}
          loaderColor={foundFeature.icon?.color}
          emptyActions={emptyActions || actions}
          emptyDescription={emptyDescription || sTooltip}
          loadingDescription={sTooltip}
          showLoader={!showHeader}
        />
      ) : (
        <>{useWrapper ? <Wrapper childrenGap={childrenGap}>{children}</Wrapper> : children}</>
      )}
    </Scroll>
  )
}

const styles = {
  position: 'relative',
  display: 'contents',

  [MEDIA_QUERY[1]]: {
    display: 'unset',
  },
}

Page.defaultProps = {
  useWrapper: true,
  showHeader: true,
  breakpoint: 3,
  background: COLORS.lightBackground,
}

export default withPageError(Page)
