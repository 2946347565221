import React from 'react'
import { Link, NavLink } from 'react-router-dom-v5-compat'
import { useMedia } from 'use-media'
import clsx from 'clsx'
import size from 'lodash/size'

import { daysTo, usDateShort } from '../../../utils/functions'
import { PlainSwitch } from '../../../components/Forms/Switch'
import { useSettings } from '../../../hooks/useSettings'

import Button from '../../../components/Button'
import Card from '../../../components/Card'
import Flex from '../../../components/Flex'
import Glyph from '../../../components/Glyph'
import GridTable from '../../../components/GridTable'
import Label from '../../../components/Label'
import ProblemStatus from '../../../components/Statuses/ProblemStatus'
import SmartStatus from '../../../components/SmartStatus'
import State from '../../../components/State'
import Status from '../../../components/Status'
import snakeCase from 'lodash/snakeCase'

import { DateCell, LastReviewCell, LastReviewDateScoreCell, ToggleCell, NavLinkCell, ToggleHeaderCell } from './TreeComponents'

import { STATUSES, REVIEW_STATUSES } from './TreatmentPlanReviews'
import { TREATMENT_PLAN_VIEWS } from '../constants'
import { useTreatmentPlanStore } from '../store'

export const TreatmentPlanProblems = (props: any) => {
  const { data, isLoading, problemActions, usePlanReviews, problemsShowSettings } = props

  const [formData, setFormData]: any = React.useState()

  const currentView = useTreatmentPlanStore((state: any) => state.problemsView)
  const problemReviewsVisible = useTreatmentPlanStore((state: any) => state.problemReviewsVisible)
  const setProblemReviewsVisible = useTreatmentPlanStore((state: any) => state.setProblemReviewsVisible)
  const toggleProblemReviewsVisible = useTreatmentPlanStore((state: any) => state.toggleProblemReviewsVisible)

  const isEmpty = size(data) === 0

  const Tag: any = VIEWS?.[currentView] || TableView

  React.useEffect(() => {
    const treatment_problems_attributes: any = []

    if (!data) return

    for (const problem of data) {
      treatment_problems_attributes.push({
        id: problem.id,
        name: problem.name,
        behavioral_definitions: problem.behavioral_definitions,
        status: problem.status,
        started_at: problem.started_at,
        target_at: problem.target_at,
        completed_at: problem.completed_at,
        last_treatment_review: problem.last_treatment_review,
        next_treatment_review: problem.next_treatment_review,
      })
    }

    setFormData({ treatment_problems_attributes })
  }, [data])

  return (
    <div className="grid gap-3">
      {usePlanReviews && !isEmpty && (
        <Card className="px-2 py-1.5 mt-1.5">
          <Flex gap="1rem" justifyContent="space-between">
            {currentView === TREATMENT_PLAN_VIEWS.cards && (
              <div className="flex items-center flex-nowrap">
                <Label isCompact label="Show Reviews" onClick={toggleProblemReviewsVisible} className="!flex-[0_0_auto] cursor-pointer" />
                <PlainSwitch size={100} isChecked={problemReviewsVisible} onCheckedChange={setProblemReviewsVisible} />
              </div>
            )}
          </Flex>
        </Card>
      )}

      {isLoading || isEmpty ? (
        <Card>
          <State
            title="Problems"
            icon="treatment_plans"
            isLoading={isLoading}
            isEmpty={isEmpty}
            emptyDescription="No problems added yet to this treatment plan"
            minHeight={150}
            emptyActions={problemActions}
          />
        </Card>
      ) : (
        <Tag {...props} />
      )}

      {!isEmpty && <div className="pt-1">{problemActions}</div>}
    </div>
  )
}

const TableView = (props: any) => {
  const { data, setEditLink, canEdit, planId, permissionBase, showDates } = props

  const [openIds, setOpenIds] = React.useState<any>([])

  const allIds = React.useMemo(() => {
    const result: any = []

    if (!data) return result

    for (const problem of data) {
      result.push(problem.id)
    }

    return result
  }, [data])

  const isAllOpen = size(openIds) === size(allIds)

  const toggleAllOpen = () => {
    setOpenIds(allIds)
  }

  const toggleAllClosed = () => {
    setOpenIds([])
  }

  const toggleOpen = (problemId: any) => {
    setOpenIds((c: any) => {
      if (c.includes(problemId)) {
        return c.filter((id: any) => id !== problemId)
      } else {
        return [...c, problemId]
      }
    })
  }

  const templateColumns = React.useMemo(() => {
    let res = '110px 250px 120px 150px 320px 240px'

    if (showDates) {
      res += ' repeat(3, minmax(95px, 1fr))'
    }

    return res
  }, [showDates])

  return (
    <Card>
      <GridTable testKey="problems_table" useBanding={false} templateColumns={templateColumns} className="text-[0.92rem]">
        <GridTable.Header>
          <ToggleHeaderCell
            className="mq900:sticky mq900:shadow-x-hard-2 mq900:!bg-white mq900:z-[5] mq900:left-0"
            isAllOpen={isAllOpen}
            onClick={() => {
              if (isAllOpen) {
                toggleAllClosed()
              } else {
                toggleAllOpen()
              }
            }}
          />

          <GridTable.Cell className="mq900:sticky mq900:shadow-x-hard-2 mq900:!bg-white mq900:z-[5] mq900:left-[110px]">
            Name
          </GridTable.Cell>
          <GridTable.Cell>Status</GridTable.Cell>
          <GridTable.Cell>Diagnoses</GridTable.Cell>
          <GridTable.Cell>Last Review</GridTable.Cell>
          <GridTable.Cell>Last Review Date & Score</GridTable.Cell>

          {showDates && (
            <>
              <GridTable.Cell>Start</GridTable.Cell>
              <GridTable.Cell>Target</GridTable.Cell>
              <GridTable.Cell>Completion</GridTable.Cell>
            </>
          )}
        </GridTable.Header>

        {data?.map?.((problem: any, problemIndex: number) => {
          const isOpen = openIds.includes(problem.id)
          const hasChildren = size(problem.behavioral_definitions) > 0
          const hasLinkedDiagnoses = size(problem.treatment_diagnoses) > 0

          return (
            <>
              <GridTable.Row>
                <ToggleCell
                  className="mq900:sticky mq900:shadow-x-hard-2 mq900:!bg-white mq900:z-[3] mq900:left-0"
                  isOpen={isOpen}
                  onClick={() => toggleOpen(problem.id)}
                  label={`Problem ${problemIndex + 1}`}
                  hasChildren={hasChildren}
                />

                <NavLinkCell
                  className="mq900:sticky mq900:shadow-x-hard-2 mq900:!bg-white mq900:z-[3] mq900:left-[110px]"
                  to={setEditLink?.(problem.id)}
                  label={problem.name || problem.description}
                />

                <GridTable.Cell>
                  <ProblemStatus small status={problem.status} />
                </GridTable.Cell>

                <GridTable.Cell>
                  {hasLinkedDiagnoses ? (
                    <Flex gap="0.25rem" nowrap>
                      {problem.treatment_diagnoses.map((o) => (
                        <Status key={o.id} small color="vividBlue" label={o?.diagnosis?.code} />
                      ))}
                    </Flex>
                  ) : (
                    '–'
                  )}
                </GridTable.Cell>

                <LastReviewCell reference={problem} canEdit={canEdit} planId={planId} permissionBase={permissionBase} />
                <LastReviewDateScoreCell reference={problem} />

                {showDates && (
                  <>
                    <DateCell value={problem.started_at} />
                    <DateCell value={problem.target_at} />
                    <DateCell value={problem.completed_at} />
                  </>
                )}
              </GridTable.Row>

              {isOpen && (
                <div className="grid gap-1 pt-1 pt-2 pl-2 pb-5 text-[0.9rem] pl-[35px] border border-b border-solid border-0 border-divider">
                  <div className="font-[600]">Behavioral Definitions:</div>
                  <div className="whitespace-pre">{problem.behavioral_definitions || '–'}</div>
                </div>
              )}
            </>
          )
        })}
      </GridTable>
    </Card>
  )
}

const CardsView = (props: any) => {
  const { setEditLink, canEdit, data, showDates, usePlanReviews, problemsShowSettings } = props

  const reviewsVisible = useTreatmentPlanStore((state: any) => state.problemReviewsVisible)

  const isDesktop = useMedia({ minWidth: 1500 })

  return (
    <div className={clsx(reviewsVisible && isDesktop ? 'mt-2' : '-mt-4')}>
      {reviewsVisible && isDesktop && (
        <Card className="px-3 py-1.5 sticky top-2 z-[3] font-[600]">
          <div className="grid grid-cols-[1fr_220px_220px] mq1600:grid-cols-[1fr_280px_280px] mq1800:grid-cols-[1fr_340px_340px] gap-3">
            <div>Problems</div>
            {usePlanReviews && (
              <>
                <div className="pl-3 border-l border-0 border-solid border-divider text-center">Last Reviews</div>
                <div className="pl-3 border-l border-0 border-solid border-divider text-center">Next Reviews</div>
              </>
            )}
          </div>
        </Card>
      )}

      {data?.map?.((problem: any, problemIndex: number) => {
        return (
          <CardItem
            key={problem.id}
            canEdit={canEdit}
            isDesktop={isDesktop}
            data={problem}
            showDates={showDates}
            identifier={`Problem ${problemIndex + 1}`}
            to={setEditLink?.(problem.id)}
            problemsShowSettings={problemsShowSettings}
            usePlanReviews={usePlanReviews}
          />
        )
      })}
    </div>
  )
}

const CardItem = (props: any) => {
  const { data, identifier, to, isDesktop, canEdit, showDates, usePlanReviews, problemsShowSettings } = props

  const lastReview = data?.last_treatment_review
  const nextReview = data?.next_treatment_review

  const reviewsVisible = useTreatmentPlanStore((state: any) => state.problemReviewsVisible)

  const mainContent = (
    <>
      <Flex centerY gap="0.25rem">
        {identifier && <Status small label={identifier} color="textMuted" className="!text-text" />}
        <ProblemStatus small status={data.status} />

        {showDates && (
          <div className="!ml-auto">
            <Flex centerY gap="0.75rem">
              {data.status === 'completed' && data.completed_at && problemsShowSettings?.completion_date && (
                <DateItem label="Completion" value={data.completed_at} />
              )}
              {problemsShowSettings?.start_date && <DateItem label="Start" value={data.started_at} />}
              {problemsShowSettings?.target_date && <DateItem label="Target" value={data.target_at} />}
            </Flex>
          </div>
        )}
      </Flex>

      <h3 className="text-[1.04rem] font-[700] mt-1 text-blue-500 cursor-pointer">{data.name}</h3>
      {data.behavioral_definitions && <div className="text-text-muted whitespace-pre-wrap">{data.behavioral_definitions}</div>}
    </>
  )

  if (isDesktop) {
    return (
      <div
        className={clsx(
          'pt-4',
          reviewsVisible && 'grid grid-cols-[1fr_220px_220px] mq1600:grid-cols-[1fr_280px_280px] mq1800:grid-cols-[1fr_340px_340px] gap-3',
        )}
      >
        <Card
          to={to}
          as={to ? NavLink : 'div'}
          className="block px-2 py-2 border-solid border-transparent [&.active]:!border-blue-300 [&.active]:ring-2 ring-blue-100 text-text"
        >
          {mainContent}
        </Card>

        {usePlanReviews && reviewsVisible && (
          <>
            <ReviewCard canEdit={canEdit} reference={data} data={lastReview} type="last" />
            <ReviewCard canEdit={canEdit} reference={data} data={nextReview} type="next" hideCreate={!lastReview} />
          </>
        )}
      </div>
    )
  }

  const Tag: any = to ? NavLink : 'div'

  return (
    <div className="pt-4">
      <Card testKey={`${snakeCase(data.name)}`}>
        <Tag
          to={to}
          className={clsx(
            'block border-solid border-transparent [&.active]:!border-blue-300 [&.active]:ring-2 ring-blue-100 text-text',
            reviewsVisible ? 'rounded-[6px_6px_0_0]' : 'rounded-[6px]',
          )}
        >
          <div className="p-3">{mainContent}</div>
        </Tag>

        {usePlanReviews && reviewsVisible && (
          <div className="grid grid-cols-1 mq900:grid-cols-2 border-t border-0 border-solid border-divider bg-[#F7F8FB]">
            <ReviewCard
              canEdit={canEdit}
              plain
              reference={data}
              data={lastReview}
              type="last"
              className="shadow-[0_1px_0] mq900:shadow-[1px_0_0] !shadow-divider"
            />
            <ReviewCard canEdit={canEdit} plain reference={data} data={nextReview} type="next" hideCreate={!lastReview} />
          </div>
        )}
      </Card>
    </div>
  )
}

const ReviewCard = (props: any) => {
  const { data, reference, type, plain, className, hideCreate, canEdit } = props

  const { timezone } = useSettings()

  const to = data ? `reviews/${data.id}` : undefined

  const classes = clsx(
    'block px-2 py-2 border-solid border-transparent [&.active]:!border-blue-300 [&.active]:ring-2 ring-blue-100 text-text',
    !to && '!bg-white !bg-opacity-40',
    className,
  )

  const days = daysTo(data?.dated_at, timezone)

  let daysText =
    typeof days === 'number' &&
    (days === -1
      ? `yesterday`
      : days < 0
      ? `${Math.abs(days)} days ago`
      : days === 0
      ? 'today'
      : days === 1
      ? 'tomorrow'
      : `in ${days} days`)

  const mainContent: any = (
    <>
      {data ? (
        <div>
          <Flex centerY gap="0.25rem">
            <Status small label={`${type} review`} color="textMuted" className="!text-text" />
            <SmartStatus small statuses={REVIEW_STATUSES} status={data?.status || 'draft'} />
            {data?.score && <SmartStatus small statuses={STATUSES} status={data.score} />}

            {type === 'next' && typeof days === 'number' && data?.status !== 'completed' && days < 2 && (
              <>
                <Status small glyph={days > 0 ? 'warning' : 'circle_error'} color={days > 0 ? 'orange' : 'red'} label={`Due ${daysText}`} />
              </>
            )}

            {data?.dated_at && <DateItem value={data.dated_at} />}
          </Flex>

          <h3 className="text-[1.04rem] font-[700] mt-1 text-blue-500 cursor-pointer">{data.name}</h3>
          {data.notes && <div className="text-text-muted whitespace-pre-wrap">{data.notes}</div>}
        </div>
      ) : (
        <div className="grid gap-2">
          <Status small label={`${type} review`} color="textMuted" className="!text-text" />

          <div className="text-text-muted italic text-[0.92rem]">
            {type === 'last' ? 'Not yet added' : type === 'next' ? 'Not yet planned' : 'Not yet added'}
          </div>

          {!hideCreate && canEdit && (
            <Button
              as={Link}
              label={type === 'last' ? 'Add Review' : type === 'next' ? 'Plan Next Review' : 'Add Review'}
              type="primary"
              glyph="add"
              size={100}
              link="reviews/new"
              state={{
                data: {
                  _dated_in_one_month: type === 'next',
                  status: type === 'last' ? 'completed' : type === 'next' ? 'draft' : undefined,
                  reference,
                  treatment_problem: reference,
                },
              }}
            />
          )}
        </div>
      )}
    </>
  )

  if (plain) {
    const Tag = to ? NavLink : 'div'

    return (
      <Tag
        to={to}
        className={clsx(
          'block border-solid border-transparent [&.active]:!border-blue-300 [&.active]:ring-2 ring-blue-100 text-text p-3',
          type === 'last' ? 'mq900:rounded-[0_0_0_6px]' : 'rounded-[0_0_6px_6px] mq900:rounded-[0_0_6px_0]',
          className,
        )}
      >
        {mainContent}
      </Tag>
    )
  }

  return (
    <Card to={to} as={to ? NavLink : 'div'} className={classes}>
      {mainContent}
    </Card>
  )
}

const DateItem = ({ label, value, after }: any) => {
  const { timezone } = useSettings()

  if (!label && !value) return null

  return (
    <div className="flex items-center flex-nowrap text-[0.9rem] tabular-nums min-w-[120px] text-text">
      <Glyph glyph="date" size="1em" className="mr-1" />
      {label && <div className="font-[500] mr-0.5">{label}:</div>}
      <div className={!value && 'text-text-strongly-muted'}>
        {value ? usDateShort(value, timezone) : '–'}
        {after}
      </div>
    </div>
  )
}

const VIEWS = {
  [TREATMENT_PLAN_VIEWS.table]: TableView,
  [TREATMENT_PLAN_VIEWS.cards]: CardsView,
}
