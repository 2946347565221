import React from 'react'
import clsx from 'clsx'
import isString from 'lodash/isString'
import isUndefined from 'lodash/isUndefined'

import { COLORS } from '../../../theme'
import Avatar from '../../Avatar'
import Icon from '../../Icon'
import Link from '../../Link'

import { DataTableCell } from '../DataTableCell'
import { EmptyCell } from './EmptyCell'

export const InsuranceProfileCell: React.FC = (props: any) => {
  const { value, icon, column, rowData, hoverExpand = true } = props

  const link = column?.config?.setLink?.({ rowData })

  return (
    <DataTableCell hoverExpand={hoverExpand} css={STYLES.root} {...props}>
      {Array.isArray(value) ? (
        value.map((o) => <InsuranceProfileCellInner value={o} icon={icon} link={link} />)
      ) : (
        <InsuranceProfileCellInner value={value} icon={icon} link={link} />
      )}
    </DataTableCell>
  )
}

export const InsuranceProfileCellInner = ({ value, avatar, icon, link }: any) => {
  const Tag = link ? Link : 'div'

  const linkProps = React.useMemo(() => {
    if (!link) return {}

    return { to: link }
  }, [link])

  const classes = clsx(link && 'as-link')

  if (isString(value)) {
    return (
      <Tag css={STYLES.inner} className={classes} {...linkProps}>
        {!isUndefined(avatar) && <Avatar src={avatar || ''} initials={value} size={20} css={STYLES.graphic} />}
        <span css={STYLES.name}>{value}</span>
      </Tag>
    )
  }

  if (value?.billing_name) {
    return (
      <Tag css={STYLES.inner} className={classes} {...linkProps}>
        {!isUndefined(value.avatar) && <Avatar magnify src={value.avatar} initials={value.name} size={20} css={STYLES.graphic} />}

        {icon && <Icon icon={icon} size={20} css={STYLES.graphic} />}

        <span css={STYLES.name}>{value.billing_name}</span>
      </Tag>
    )
  }

  return <EmptyCell />
}

const STYLES = {
  root: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'stretch',
    flex: '1 1 auto',
  },

  inner: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',

    '&.as-link': {
      color: COLORS.blue,
      fontWeight: 600,
    },
  },

  graphic: {
    marginRight: '0.25rem',
  },

  name: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    flex: '1 1 auto',
  },
}
