import React from 'react'
import { useParams } from 'react-router-dom'

import { daysToWords, titleCase, usDateTime } from '../../utils/functions'
import { ExportPDFButton } from '../../components/Buttons/ExportPDFButton'
import { useGet, invalidateQueries } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import AgreementSections from '../../components/Elements/AgreementSections'
import Button from '../../components/Button'
import Card from '../../components/Card'
import CardHeader from '../../components/CardHeader'
import CardMeta from '../../components/CardMeta'
import CardTitle from '../../components/CardTitle'
import Checkbox from '../../components/Forms/Checkbox'
import ContextShow from '../../components/Forms/ContextShow'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ContractStatus from '../../components/Statuses/ContractStatus'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Dialog from '../../components/Dialog'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import MultiObjectSelector from '../../components/Forms/Selectors/MultiObject/MultiObjectSelector'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import Permission from '../../components/Permission'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import SignAgreementOverlay from '../../components/Overlays/pages/Agreements/SignAgreementOverlay'
import SignatureDialog from '../../components/Dialogs/SignatureDialog'
import SignaturePad from '../../components/Forms/SignaturePad'
import SigneeRelationshipStatus from '../../components/Statuses/SigneeRelationshipStatus'
import Status from '../../components/Status'
import SummonOverlay from '../../components/SummonOverlay'
import Text from '../../components/Typography/Text'
import Textarea from '../../components/Forms/Textarea'
import Timeline from '../../components/Timeline/Timeline'

const Contract = ({ agreement, contract, reference, isPortal = false, onSignedSuccessfully }: any) => (
  <Card variant="variant-list" paddingX="0" paddingY="0.5rem" css={{ '&:last-of-type': { boxShadow: 'none !important' } }}>
    <CardHeader
      contentGap="0.35rem"
      after={
        agreement.should_sign && (
          <SummonOverlay
            overlay={
              <SignAgreementOverlay
                showBackdrop
                useContractsEndpoint
                agreement={agreement}
                contract={contract}
                onSignedSuccessfully={onSignedSuccessfully}
              />
            }
          >
            {contract.status === 'signed' ? (
              <Button label="View Agreement" glyph="tick_circle" type="default" size={200} display="inline-flex" color="green" />
            ) : (
              <Button label="Sign Agreement" glyph="signature" type="primary" size={200} display="inline-flex" />
            )}
          </SummonOverlay>
        )
      }
    >
      <CardTitle title={contract?.signee?.signer?.name} className="!text-[1rem] !font-[700]" />

      <CardMeta>
        <SigneeRelationshipStatus signee={contract?.signee?.signer} />
        {agreement.should_sign && <ContractStatus status={contract?.status} />}
      </CardMeta>

      <CardMeta className="!text-[0.9rem]">
        {contract?.status !== 'signed' && !isPortal && (
          <>
            <Text label="Email Notification: " description={daysToWords(contract?.email_sent_at)} glyph="email" glyphColor="gray" />
            <Text label="SMS Notification: " description={daysToWords(contract?.sms_sent_at)} glyph="sms" glyphColor="gray" />
          </>
        )}
        {contract?.status === 'signed' && <Text label="Signed At: " glyph="signature" description={usDateTime(contract?.signed_at)} />}
      </CardMeta>
    </CardHeader>
  </Card>
)

const RootClientAgreementOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialData,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
    updateAsync,
    refetch,
  } = useOverlay({
    name: 'agreements',
    endpoint: '/agreements',
    invalidate: 'agreements',
    invalidateKeys: ['agreements'],
    options: props,
    openAfterCreate: true,
  })

  const { resource_id }: any = useParams()

  const [shouldSign, setShouldSign] = React.useState(initialModel?.should_sign)
  const [staffShouldSign, setStaffShouldSign] = React.useState(initialModel?.request_cosigner_signature)
  const [selectedStaff, setSelectedStaff] = React.useState(null)

  const { user, tenant, timezone, isPortal } = useSettings()

  const signAgreement = async (signature) => {
    await updateAsync({
      [signature.model]: signature.value,
    })

    cancel()
  }

  const { data: client }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  React.useEffect(() => {
    if (!data) return

    setStaffShouldSign(data.request_cosigner_signature)
    setSelectedStaff(data.cosigner)
  }, [data])

  const isTypeOfAdmin = initialModel?.agreement_type === 'admin'

  let signees = []
  for (let i = 0; i < initialModel?.contracts?.length; i++) {
    if (initialModel?.contracts?.[i].signee?.reference?.type !== 'resident') {
      signees.push(initialModel.contracts[i].signee)
    }
  }

  // if signature was originally required and is now unchecked in edit mode
  const contractsSigned = initialModel?.contracts?.filter((o: any) => o.status === 'signed').length || 0
  const contractsLength = initialModel?.contracts?.length || 0
  const userAndCosignerDifferent = initialModel?.cosigner?.id !== user?.id
  const isTrialing = tenant?.plan_status === 'trialing'

  if (isOverlayLoading || !client) {
    return <OverlayLoader position="right" maxWidth={82} />
  }

  return (
    <Overlay
      showBackdrop={isEditable || props.showBackdrop}
      position="right"
      onClose={close}
      maxWidth={82}
      closeWrapper={(element, onClose) => (
        <Dialog
          glyph="delete"
          title="Close without saving?"
          message="All changes will be lost. This action cannot be undone."
          yesColor="red"
          yesLabel="Yes, Close Without Saving"
          onYes={onClose}
          skip={!isEditable || !onClose}
        >
          {element}
        </Dialog>
      )}
    >
      <Overlay.Header title={initialModel?.name} icon="legal_agreement_alt" />

      {!isEditable && !isTrialing && (
        <Permission permission="clients.actions.export">
          <Overlay.SubHeader>
            <ExportPDFButton url={`/agreements/${id}/pdf`} />
          </Overlay.SubHeader>
        </Permission>
      )}

      <Overlay.Content>
        <Form
          useLocalModel
          isEditable={isEditable}
          getForm={form}
          timezone={timezone}
          onValidationUpdate={onValidationUpdate}
          initialModel={initialModel}
          linked={{
            agreement_template_id: initialData?.id,
            reference_id: client?.id,
            reference_type: 'Resident',
            template_type: 'custom',
            variant: 'client',
            ...(isNew && isTypeOfAdmin && { agreement_type: 'admin' }),
          }}
        >
          <Section title="Agreement Details">
            <FormSection maxWidth="100%">
              <Input
                label="Agreement Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter an agreement name',
                  },
                }}
              />

              <Select allowEmpty label="Review Cycle" model="review_cycle">
                <Option label="Daily" value="daily" />
                <Option label="Weekly" value="weekly" />
                <Option label="Monthly" value="monthly" />
                <Option label="Quarterly" value="quarterly" />
                <Option label="Semi-annually" value="semi_annually" />
                <Option label="Annually" value="annually" />
              </Select>

              <Flex gap={16} horizontal stretchChildrenX alignItems="flex-end">
                <DateTimeInput
                  defaultToNow
                  css={{ maxWidth: 430 }}
                  model="dated_at"
                  label="Effective Date"
                  validations={{
                    presence: {
                      message: 'Please enter a date and time',
                    },
                  }}
                />

                <Checkbox className="!mb-[12px]" label="Send Internal Email Reminders" model="should_send_effective_date_reminders" />
              </Flex>

              <ContextShow when="should_send_effective_date_reminders" is={true}>
                <CheckboxGroup
                  label="Send Reminders Options"
                  layout="vertical-dense"
                  validations={{
                    presence: {
                      message: 'Please select at least one option',
                    },
                  }}
                >
                  <Checkbox label="3 months before effective date" model="send_reminders.effective_date.three_months" />
                  <Checkbox label="1 month before effective date" model="send_reminders.effective_date.one_month" />
                  <Checkbox label="2 weeks before effective date" model="send_reminders.effective_date.two_weeks" />
                  <Checkbox label="1 week before effective date" model="send_reminders.effective_date.one_week" />
                  <Checkbox label="1 day before effective date" model="send_reminders.effective_date.one_day" />
                </CheckboxGroup>
              </ContextShow>

              <Divider />

              <Flex gap={16} horizontal stretchChildrenX alignItems="flex-end">
                <DateTimeInput css={{ maxWidth: 430 }} model="expired_at" label="Expiry Date" />
                <Checkbox className="!mb-[12px]" label="Send Internal Email Reminders" model="should_send_expiry_date_reminders" />
              </Flex>

              <ContextShow when="should_send_expiry_date_reminders" is={true}>
                <CheckboxGroup
                  label="Send Reminders Options"
                  layout="vertical-dense"
                  validations={{
                    presence: {
                      message: 'Please select at least one option',
                    },
                  }}
                >
                  <Checkbox label="3 months before expiry" model="send_reminders.expiry_date.three_months" />
                  <Checkbox label="1 month before expiry" model="send_reminders.expiry_date.one_month" />
                  <Checkbox label="2 weeks before expiry" model="send_reminders.expiry_date.two_weeks" />
                  <Checkbox label="1 week before expiry" model="send_reminders.expiry_date.one_week" />
                  <Checkbox label="1 day before expiry" model="send_reminders.expiry_date.one_day" />
                </CheckboxGroup>
              </ContextShow>

              <Divider />

              <Flex gap={16} horizontal stretchChildrenX alignItems="flex-end">
                <DateTimeInput css={{ maxWidth: 430 }} model="next_review_at" label="Next Review Date" />
                <Checkbox className="!mb-[12px]" label="Send Internal Email Reminders" model="should_send_next_review_date_reminders" />
              </Flex>

              <ContextShow when="should_send_next_review_date_reminders" is={true}>
                <CheckboxGroup
                  label="Send Reminders Options"
                  layout="vertical-dense"
                  validations={{
                    presence: {
                      message: 'Please select at least one option',
                    },
                  }}
                >
                  <Checkbox label="3 months before next review" model="send_reminders.next_review_date.three_months" />
                  <Checkbox label="1 month before next review" model="send_reminders.next_review_date.one_month" />
                  <Checkbox label="2 weeks before next review" model="send_reminders.next_review_date.two_weeks" />
                  <Checkbox label="1 week before next review" model="send_reminders.next_review_date.one_week" />
                  <Checkbox label="1 day before next review" model="send_reminders.next_review_date.one_day" />
                </CheckboxGroup>
              </ContextShow>

              <Divider />

              <DateTimeInput model="reviewed_at" label="Last Review Date" />

              <Textarea useQuickText label="Notes" model="notes" />
            </FormSection>
          </Section>

          <Divider />

          <FormSection maxWidth="100%">
            <div>
              <AgreementSections
                model="sections"
                validations={{
                  presence: {
                    message: 'Please add at least one section',
                  },
                }}
              />
            </div>
          </FormSection>

          {initialModel?.cosigner_signature && (
            <Section>
              <SignaturePad
                isValid
                label="Supervisor Signature"
                person={initialModel?.cosigner}
                allowPin={false}
                model="cosigner_signature"
                value={initialModel?.cosigner_signature}
                signedAtModel="cosigner_signed_at"
              />
            </Section>
          )}

          <Section
            title="Staff"
            aside={
              <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="linethrough">
                <Checkbox
                  label="Require Staff signature"
                  model="request_cosigner_signature"
                  onUpdate={({ value }) => {
                    setStaffShouldSign(value)
                  }}
                />
              </CheckboxGroup>
            }
          >
            <Flex alignItems="center" gap={8} justifyContent="space-between">
              <OverlaySelector
                isPolymorphic
                disableLink
                className="!grow !shrink !basis-[240px]"
                label="Staff"
                blankLabel="Select Staff…"
                icon="employees"
                type="employees.active"
                model="cosigner"
                selectTitle={(data) => data.name}
                selectDescription={(data) => titleCase(data.position)}
                value={selectedStaff}
                onUpdate={({ object }) => {
                  setSelectedStaff(object)
                }}
                validations={
                  staffShouldSign
                    ? {
                        presence: {
                          message: 'Please select a staff member',
                        },
                      }
                    : {}
                }
              />

              {!isPortal && initialModel?.request_cosigner_signature && initialModel?.cosigner && !initialModel?.cosigner_signature && (
                <SignatureDialog
                  isDisabled={userAndCosignerDifferent}
                  allowPin={false}
                  model="cosigner"
                  title="Sign Agreement"
                  yesLabel="Sign"
                  onYes={signAgreement}
                >
                  <Button
                    fullWidth
                    label="Sign Agreement…"
                    glyph="signature"
                    type="default"
                    size={200}
                    isDisabled={isLoading || isSaving || userAndCosignerDifferent}
                  />
                </SignatureDialog>
              )}

              {initialModel?.cosigner_signature && (
                <Button label="Agreement Signed" glyph="tick_circle" type="tick_circle" color="green" size={200} />
              )}
            </Flex>
          </Section>

          <Divider />

          <Section
            title="Signees"
            aside={
              <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="linethrough">
                <Checkbox label="Require Signees signature" model="should_sign" onUpdate={(state) => setShouldSign(state.value)} />
              </CheckboxGroup>
            }
            description={
              !isNew && shouldSign && <Status label={`${contractsSigned} / ${contractsLength} Signatures Received`} color="green" />
            }
          >
            {isNew && (
              <FormSection maxWidth="100%" className="!mt-2">
                <Card>
                  <CardHeader contentGap="0.35rem">
                    <CardTitle title={client?.name} className="!text-[1rem] !font-[700]" />
                    <SigneeRelationshipStatus signee={client} />
                  </CardHeader>
                </Card>

                <MultiObjectSelector
                  maxWidth="500px"
                  icon="contacts"
                  label="Other Signees"
                  model="signees"
                  type="client.signees"
                  value={signees}
                  dependentValue={client?.id}
                  selectTitle={(data) => data.signer.name}
                  selectDescription={(data) => data.signer?.relationship}
                />
              </FormSection>
            )}

            {!isNew &&
              initialModel?.contracts?.map((contract: any) => (
                <Contract
                  key={contract.id}
                  isPortal={isPortal}
                  agreement={data}
                  contract={contract}
                  reference={initialModel?.reference}
                  onSignedSuccessfully={async () => {
                    await refetch?.()
                    invalidateQueries(null, 'agreements')
                  }}
                />
              ))}
          </Section>

          {!isNew && (
            <>
              <Divider />
              <Section headingType="h2" title="Timeline">
                <Timeline isLoadingRecord={isLoading} recordID={initialModel?.id} recordType={initialModel?.type} disableLinks={isPortal} />
              </Section>
            </>
          )}
        </Form>
      </Overlay.Content>

      {!isPortal && (
        <Overlay.Footer>
          {isEditable && (
            <>
              <Button
                glyph="check"
                label="Save Agreement"
                type="primary"
                color="green"
                isLoading={isSaving}
                onClick={save}
                isDisabled={isInvalid || (staffShouldSign && !selectedStaff)}
                flex="100 1 240px"
                permission="agreements.create"
              />

              {!isNew && <Button glyph="cross" label="Cancel" type="default" isDisabled={isSaving} onClick={cancel} />}
            </>
          )}

          {!isEditable && (
            <>
              {!contractsSigned && (
                <Button
                  label="Edit"
                  glyph="edit"
                  type="default"
                  isDisabled={isLoading}
                  onClick={edit}
                  flex="100 1 auto"
                  permission="agreements.edit"
                />
              )}

              <DeleteDialog
                title="Delete Agreement?"
                message="Are you sure you want to delete this agreement? This action cannot be undone."
                onYes={deleteRecord}
              >
                <Button
                  label="Delete"
                  type="default"
                  glyph="delete"
                  color="red"
                  isLoading={isDeleting}
                  fullWidth
                  permission="agreements.delete"
                />
              </DeleteDialog>
            </>
          )}
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

export const ClientAgreementOverlay = withOverlayError(RootClientAgreementOverlay)
