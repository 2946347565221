import React from 'react'
import { tint } from 'polished'
import { useLocation, useHistory } from 'react-router-dom'
import size from 'lodash/size'

import { COLORS } from '../../theme'
import { invalidateQueries } from '../../hooks/useNewAPI'
import { titleCase, countWord } from '../../utils/functions'
import { useGet, useCreate } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import { ExportPDFButton } from '../../components/Buttons/ExportPDFButton'
import AddendumCard from '../../components/AddendumCard'
import AddendumOverlay from '../Overlays/AddendumOverlay'
import Alert from '../../components/Alert'
import Attachments from '../../components/Forms/Attachments'
import Avatar from '../../components/Avatar'
import Button from '../../components/Button'
import Card from '../../components/Card'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import Chotomate from '../../components/Chotomate'
import ClinicalNoteStatus from '../../components/Statuses/ClinicalNoteStatus'
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog'
import ContextShow from '../../components/Forms/ContextShow'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Grid from '../../components/Grid'
import HelpTagIframe from '../../components/Help/HelpTagIframe'
import Icon from '../../components/Icon'
import Input from '../../components/Forms/Input'
import Loader from '../../components/Loader'
import MiniRichTextEditor from '../../components/Forms/MiniRichTextEditor'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import Permission from '../../components/Permission'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import SignatureDialog from '../../components/Dialogs/SignatureDialog'
import SignaturePad from '../../components/Forms/SignaturePad'
import SummonOverlay from '../../components/SummonOverlay'
import TextareaDialog from '../../components/Dialogs/TextareaDialog'
import Timeline from '../../components/Timeline/Timeline'

import EventAttendancePresentStatusSelect from '../../components/Elements/EventAttendancePresentStatusSelect'
import EventAttendanceAbsentStatusSelect from '../../components/Elements/EventAttendanceAbsentStatusSelect'

import { ClinicalNotesGroupWorksheetOverlay } from './ClinicalNotesGroupWorksheetOverlay'
import { TreatmentPlanSelector } from '../../components/Forms/TreatmentPlanSelector'
import { InsuranceBillingFormSection } from '../RCM/components/InsuranceBillingFormSection'

import { FormFutureDateWarning } from '../Misc/FormFutureDateWarning'

export const RootClinicalNoteGroupOverlay = (props: any) => {
  const { position = 'right', showBackdrop, hideSessionNotes } = props

  const {
    cancel,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialData,
    initialModel,
    invalidateKeys,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isRefetching,
    isSaving,
    isUpdating,
    onValidationUpdate,
    parent,
    queryKey,
    saveWithData,
    updateAsync,
  } = useOverlay({
    name: 'clinical-notes',
    endpoint: '/clinical_notes',
    invalidate: 'clinical-notes',
    invalidateKeys: props.invalidateKeys,
    options: props,
    onDeleteSuccessful: props.onDeleteSuccessful,
    onSaveSuccessful: props.onSaveSuccessful,
    onAfterClose: (args) => {
      // open the newly created record
      if (!args.isNew || !args?.data?.id || !!props?.onClose) return

      if (args?.location?.pathname?.endsWith('/gn/new')) {
        args.navigate(args?.location?.pathname.replace('/gn/new', `/gn/${args.data.id}`))
      }
    },
  })

  const location = useLocation()
  const history = useHistory()

  const close = () => {
    if (props.type === 'summon' && props.onClose) return props.onClose()

    const url = location?.parent?.url || location.pathname.substring(0, location.pathname.lastIndexOf('/gn/'))

    history.push(url)
  }

  const { isBehave, isOwner, tenant, timezone, user, isEHRApp, defaultSupervisor, systemPrefs }: any = useSettings()

  const isRequiredSupervisorNonEditable = systemPrefs?.require_clinical_notes_supervisor
  const [clientId, setClientId] = React.useState<string | null>(null)

  const sessionNotesCount = size(data?.session_notes)
  const hasSessionNotes = sessionNotesCount > 0

  const [isBatchEditing, setIsBatchEditing] = React.useState(false)
  const [sessionNoteData, setSessionNoteData]: any = React.useState(null)
  const [supervisor, setSupervisor] = React.useState<any>(null)
  const [requireSupervisor, setRequireSupervisor] = React.useState(true)

  React.useEffect(() => {
    if (!!data) setRequireSupervisor(data.require_supervisor)
  }, [data?.require_supervisor])

  const { data: sessionNotes, isLoading: isLoadingSessionNotes } = useGet({
    name: ['clinical-notes', id, 'session-notes'],
    url: `/clinical_notes`,
    params: {
      category: 'group_note',
      uuid: data?.uuid,
    },
    options: { enabled: !!(data?.uuid && hasSessionNotes && isBatchEditing) },
  })

  const { mutateAsync: closeClinicalNote, isLoading: isClosingClinicalNote } = useCreate({
    name: ['clinical_notes', id],
    url: `/clinical_notes/${id}/close`,
    invalidate: 'clinical-notes',
  })

  let finalSessionNotesData: any = []

  if (sessionNotesCount > 0 && sessionNotes) {
    finalSessionNotesData = Array.isArray(sessionNotes) ? sessionNotes : [sessionNotes]
  }

  if (isOverlayLoading) {
    return <OverlayLoader position={position} />
  }

  const save = () => {
    saveWithData({
      ...form.current.getFormValue(),
      ...(isNew && initialData?.uuid && { uuid: initialData?.uuid }),
    })
  }

  const signOff = async (signature: any) => {
    updateAsync({
      [signature.model]: signature.value,
    })

    props.onSaveSuccessful?.()
    close()
  }

  const closeOff = async () => {
    try {
      await closeClinicalNote({})

      close()
    } catch (error) {
      console.error(error)
    }
  }

  const sendForReview = async () => {
    updateAsync({ status: 'pending_review' })

    props.onSaveSuccessful?.()
    cancel()
  }

  const requestUpdates = async (updates: any) => {
    updateAsync({ status: 'updates_required', rejected_reason: updates.value })

    props.onSaveSuccessful?.()
    cancel()
  }

  const handleBatchEdit = () => {
    setIsBatchEditing(true)
  }

  const handleBatchEditClose = () => {
    setIsBatchEditing(false)
  }

  const isSupervisor = initialModel.supervisor?.id === user.id
  const isTrialing = tenant?.plan_status === 'trialing'

  return (
    <Overlay
      maxWidth={45}
      isDirty={isEditable}
      onClose={close}
      position={props.position}
      showBackdrop={isNew || isEditable || showBackdrop}
      closeOnBackdropClick={!isEditable}
    >
      <Overlay.Header
        icon="clinical_notes"
        title="Group Note"
        subtitle={<ClinicalNoteStatus status={initialModel?.status} />}
        help={<HelpTagIframe id="clinical_notes_group" />}
      />

      {!isEditable && !isTrialing && (
        <Permission permission="clients.actions.export">
          <Overlay.SubHeader>
            <ExportPDFButton url={`/clinical_notes/${id}/pdf`} />
          </Overlay.SubHeader>
        </Permission>
      )}

      <Overlay.Content>
        <Chotomate ready name="group_note_overlay" />

        <Form
          getForm={form}
          timezone={timezone}
          initialModel={{
            ...initialModel,
            ...(isNew && defaultSupervisor && { supervisor: defaultSupervisor }),
          }}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          linked={isNew && { category: 'group_note' }}
          key={`updated-${data?.updated_at}`}
        >
          {initialModel?.status === 'updates_required' && (
            <Section>
              <Alert type="negative" glyph="note">
                <MiniRichTextEditor useDictation fullwidth label="Updates Requested before Sign-Off" model="rejected_reason" />
              </Alert>
            </Section>
          )}

          <Section headingType="h2" title="Session Details">
            <FormSection maxWidth="100%">
              {!isNew && <ObjectSelector isEditable={false} model="author" label="Author" type="employees" icon="employees" />}

              <Flex gap="1rem">
                <DateTimeInput
                  defaultToNow
                  className="flex-[1_1_auto]"
                  model="started_at"
                  label="Session Start Date and Time"
                  validations={{
                    presence: {
                      message: 'Please enter a session date and time',
                    },
                  }}
                />

                <Input
                  model="duration"
                  label="Duration"
                  suffix="mins"
                  type="number"
                  min={1}
                  size={4}
                  validations={{
                    presence: {
                      message: 'Please enter a session duration',
                    },
                    numericality: {
                      greaterThanOrEqualTo: 1,
                      message: 'The duration must be higher than 0 minutes',
                    },
                  }}
                />
              </Flex>

              <FormFutureDateWarning dateLabel="Session Start Date and Time" model="started_at" />

              {!isNew && !isEditable && <DateTimeInput label="Session End Date and Time" model="ended_at" isEditable={false} />}

              <Flex gap="1rem" alignItems="flex-end">
                <ObjectSelector
                  isPolymorphic
                  css={{ flex: '1 1 auto' }}
                  model="supervisor"
                  label="Clinical Supervisor"
                  type="clinical_supervisors"
                  icon="employees"
                  selectTitle={(data) => data?.name}
                  selectDescription={(data) => titleCase(data?.position)}
                  validations={
                    requireSupervisor && {
                      presence: {
                        message: 'Please select a clinical supervisor',
                      },
                    }
                  }
                  onUpdate={({ object }) => {
                    setSupervisor(object)
                  }}
                  value={isEditable ? supervisor : data?.supervisor}
                />

                <CheckboxGroup label="Supervisor" tooltip={isRequiredSupervisorNonEditable ? 'Required from Settings' : null}>
                  <Checkbox
                    isEditable={isRequiredSupervisorNonEditable ? false : isEditable}
                    defaultChecked
                    trueIcon="check"
                    falseIcon="cross"
                    falseStyle="none"
                    label="Require Supervisor"
                    model="require_supervisor"
                    onChange={(state: any) => {
                      const nextValue = state.value
                      setRequireSupervisor(nextValue)

                      if (nextValue === false) {
                        setSupervisor(null)
                      }
                    }}
                  />
                </CheckboxGroup>

                {/* {tenant?.is_billed && (
                  <CheckboxGroup label="Insurance" trueIcon="check" falseIcon="cross" falseStyle="faded">
                    <Checkbox defaultValue={false} label="Billed Service" model="is_billable" />
                  </CheckboxGroup>
                )} */}
              </Flex>

              {!isNew && <DateTimeInput isEditable={false} model="supervisor_signed_at" label="Signed-off At" />}

              {tenant?.is_billed && <InsuranceBillingFormSection showPlaceOfService isNew={isNew} initialModel={initialModel} />}
            </FormSection>
          </Section>

          <Divider />

          {/* 
          <ContextShow when="is_billable" is={true}>
            <Section headingType="h2" title="Billing Details">
              <FormSection maxWidth="100%">
                <ObjectSelector
                  dependentValue={parent?.id}
                  model="insurance_service"
                  label="Rendered Insurance Service"
                  type="client.insurance_services"
                  icon="billing"
                  searchKeys={['code', 'short_description', 'long_description']}
                  selectTitle={(o: any) => `${o?.code} - ${o?.short_description}`}
                  selectDescription={(o: any) => o?.long_description}
                  groupBy={{
                    accessor: 'service_type',
                    sections: {
                      in_network: 'In Network',
                      out_of_network: 'Out of Network',
                      global_oon: 'Global Out of Network',
                    },
                  }}
                  validations={{
                    presence: {
                      message: 'Please select a service',
                    },
                  }}
                />

                <MiniRichTextEditor useDictation useQuickText model="billing_notes" label="Billing Notes" />
              </FormSection>
            </Section>

            <Divider />
          </ContextShow> */}

          <Section headingType="h2" title="Session Notes">
            <FormSection maxWidth="100%">
              <OverlaySelector
                isEditable={isNew}
                model="client"
                type="clients.current"
                label="Client"
                icon="clients"
                selectTitle={(data) => data.name}
                selectDescription={(data) => data.behave_id}
                validations={{
                  presence: {
                    message: 'Please select a client',
                  },
                }}
                onUpdate={({ object }) => {
                  if (!object) return

                  setClientId(object.id)
                }}
              />

              <TreatmentPlanSelector clientId={clientId} />

              <Input model="topic" label="Group Topic" />
              <MiniRichTextEditor useDictation useQuickText model="description" label="Group Discussion" />

              {isEHRApp && !isNew && !isEditable && hasSessionNotes && !hideSessionNotes && (
                <>
                  <Card>
                    <Flex centerY gap="0.5rem" justifyContent="space-between" className="!p-2">
                      <Flex centerY gap="0.5rem">
                        <Icon icon="clinical_notes" size={20} />
                        <div>
                          <span className="!font-[600]">{countWord('Notes', sessionNotesCount + 1)}</span> in this session
                        </div>

                        {isRefetching && <Loader size={18} />}
                      </Flex>

                      <Button
                        label="Batch Edit All Notes…"
                        glyph="edit"
                        size={200}
                        type="minimal"
                        onClick={handleBatchEdit}
                        isLoading={isLoadingSessionNotes}
                        isDisabled={isRefetching}
                        permission="clinical_notes.edit"
                      />
                    </Flex>

                    {data && (
                      <>
                        <SessionRow isActive={!sessionNoteData} data={data} />

                        {data.session_notes.map((note: any) => (
                          <SessionRow
                            key={note.id}
                            data={note}
                            onClick={() => {
                              setSessionNoteData(note)
                            }}
                            isActive={note.id === sessionNoteData?.id}
                          />
                        ))}
                      </>
                    )}

                    <div css={STYLES.sessionFooter}>
                      <AddSessionNoteOverlay initialData={data} isRefetching={isRefetching} />
                    </div>
                  </Card>

                  {sessionNotes && (
                    <SummonOverlay
                      isOpen={isBatchEditing && size(sessionNotes) > 0}
                      onClose={handleBatchEditClose}
                      overlay={
                        <ClinicalNotesGroupWorksheetOverlay
                          initialData={finalSessionNotesData}
                          onSuccess={close}
                          invalidateKeys={[queryKey, ['session-notes', id]]}
                        />
                      }
                    />
                  )}

                  <SummonOverlay
                    isOpen={!!sessionNoteData}
                    onClose={() => {
                      setSessionNoteData(null)
                    }}
                    overlay={
                      <ClinicalNoteGroupOverlay
                        showBackdrop
                        hideSessionNotes
                        position="center"
                        dataID={sessionNoteData?.id}
                        initialData={sessionNoteData}
                      />
                    }
                  />
                </>
              )}

              <Divider />

              <Flex gap="1rem">
                {/* <Input model="treatment_goal" label="Treatment Goal Addressed 4" className="!flex-auto" /> */}

                <RadioGroup
                  label="Presence"
                  defaultValue={true}
                  model="is_attending"
                  layout="horizontal-dense"
                  trueIcon="check"
                  falseIcon="cross"
                  validations={{
                    presence: {
                      message: 'Please set presence',
                    },
                  }}
                >
                  <Radio label="Present" value={true} />
                  <Radio label="Absent" value={false} />
                </RadioGroup>

                <ContextShow when="is_attending" is={true}>
                  <div className="!flex-auto">
                    <EventAttendancePresentStatusSelect label="Present Status" model="present_status" />
                  </div>
                </ContextShow>

                <ContextShow when="is_attending" is={false}>
                  <div className="!flex-auto">
                    <EventAttendanceAbsentStatusSelect label="Absent Status" model="absent_status" />
                  </div>
                </ContextShow>
              </Flex>

              <ContextShow when="is_attending" is={true}>
                <RadioGroup model="format" label="Note Type" layout="horizontal-dense" defaultValue={'individual_assessment'}>
                  <Radio label="DAP" value="dap" />
                  <Radio label="SOAP" value="soap" />
                  <Radio label="GIRP" value="girp" />
                  <Radio label="Individual Assessment" value="individual_assessment" />
                </RadioGroup>

                <ContextShow when="format" is={'individual_assessment'}>
                  <MiniRichTextEditor
                    useDictation
                    useQuickText
                    model="assessment"
                    label="Individual Assessment"
                    validations={{
                      presence: {
                        message: 'Please provide an individual assessment',
                      },
                    }}
                  />
                </ContextShow>

                <ContextShow when="format" is={'dap'}>
                  <MiniRichTextEditor useDictation useQuickText model="data" label="Data" />
                  <MiniRichTextEditor useDictation useQuickText model="assessment" label="Assessment" />
                  <MiniRichTextEditor useDictation useQuickText model="plan" label="Plan" />
                </ContextShow>

                <ContextShow when="format" is={'soap'}>
                  <MiniRichTextEditor useDictation useQuickText model="subjective" label="Subjective" />
                  <MiniRichTextEditor useDictation useQuickText model="objective" label="Objective" />
                  <MiniRichTextEditor useDictation useQuickText model="assessment" label="Assessment" />
                  <MiniRichTextEditor useDictation useQuickText model="plan" label="Plan" />
                </ContextShow>

                <ContextShow when="format" is={'girp'}>
                  <MiniRichTextEditor useDictation useQuickText model="goals" label="Goals" />
                  <MiniRichTextEditor useDictation useQuickText model="intervention" label="Intervention" />
                  <MiniRichTextEditor useDictation useQuickText model="response" label="Response" />
                  <MiniRichTextEditor useDictation useQuickText model="plan" label="Plan" />

                  <div className="!hidden">
                    {/* keep assessment model data when toggling between different note types */}
                    <MiniRichTextEditor model="assessment" label="Individual Assessment" />
                  </div>
                </ContextShow>
              </ContextShow>

              <ContextShow when="is_attending" is={false}>
                <MiniRichTextEditor useDictation useQuickText model="absentee_note" label="Absentee Note" />
              </ContextShow>
            </FormSection>
          </Section>

          <Divider />

          <Section headingType="h2" title="Signatures">
            <FormSection maxWidth="100%" layout="vertical">
              <SignaturePad
                allowPin
                person={data?.signed_by}
                label="Staff Signature"
                model="employee_signature"
                signedAtModel="author_signed_at"
                showAccept={false}
              />

              <SignaturePad
                allowPin
                isEditable={false}
                person={data?.supervisor}
                label="Supervisor Signature"
                model="supervisor_signature"
                signedAtModel="supervisor_signed_at"
                showAccept={false}
              />
            </FormSection>
          </Section>

          <Divider />

          <Section headingType="h2" title="Attachments" description="Upload the files related to this note">
            <FormSection maxWidth="100%">
              <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
            </FormSection>
          </Section>

          {(data?.status === 'signed_off' || data?.status === 'closed') && (
            <>
              <Divider />

              <Section headingType="h2" title="Addendums">
                <Grid gap="1rem">
                  {data?.addendums?.map((addendum: any) => {
                    return (
                      <AddendumCard
                        key={addendum.id}
                        data={addendum}
                        onUpdate={() => {
                          invalidateQueries(queryKey, invalidateKeys)
                        }}
                      />
                    )
                  })}
                </Grid>

                <SummonOverlay
                  overlay={
                    <AddendumOverlay
                      reference={data}
                      onUpdate={() => {
                        invalidateQueries(queryKey, invalidateKeys)
                      }}
                    />
                  }
                >
                  <Button size={200} label="Add New Addendum" glyph="add" type="primary" display="inline-flex" className="!mt-4" />
                </SummonOverlay>
              </Section>
            </>
          )}

          {!isNew && (
            <>
              <Divider />

              <Section headingType="h2" title="Timeline">
                <Timeline isLoadingRecord={isLoading} recordID={initialModel?.id} recordType={initialModel?.type} />
              </Section>
            </>
          )}
        </Form>
      </Overlay.Content>

      {/* no more actions if signed off */}
      {!isBehave && !isOwner && (initialModel?.status === 'signed_off' || initialModel?.status === 'closed') ? null : (
        <Overlay.Footer>
          {isEditable && (
            <>
              <Button
                label="Save"
                glyph="check"
                type="primary"
                color="green"
                isLoading={isSaving}
                onClick={save}
                isDisabled={isInvalid}
                flex="100 1 auto"
              />
              {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
            </>
          )}

          {!isEditable && (
            <>
              {initialModel?.status !== 'signed_off' && (
                <Button
                  glyph="edit"
                  label="Edit Clinical Note"
                  testKey="edit_clinical_note_button"
                  type="default"
                  isDisabled={isLoading}
                  onClick={edit}
                  flex="100 1 auto"
                  permission="clinical_notes.edit"
                />
              )}

              <DeleteDialog
                title="Delete Clinical Note?"
                message="Are you sure you want to delete this clinical note? This action cannot be undone."
                onYes={deleteRecord}
              >
                <Button
                  glyph="delete"
                  label="Delete"
                  testKey="delete_clinical_note_button"
                  type="default"
                  color="red"
                  isLoading={isDeleting}
                  fullWidth
                  permission="clinical_notes.delete"
                />
              </DeleteDialog>

              {requireSupervisor &&
                !isSupervisor &&
                initialModel?.status !== 'pending_review' &&
                (initialModel?.status !== 'signed_off' || initialModel?.status !== 'closed') && (
                  <Button
                    fullWidth
                    label="Send for Review"
                    type="primary"
                    color="green"
                    glyph="check"
                    isDisabled={isUpdating || isInvalid}
                    onClick={sendForReview}
                  />
                )}

              {requireSupervisor && isSupervisor && initialModel?.status !== 'signed_off' && (
                <>
                  {initialModel?.status === 'pending_review' && (
                    <TextareaDialog
                      model="rejected_reason"
                      title="What updates should be made?"
                      onYes={requestUpdates}
                      yesLabel="Ask For Updates"
                    >
                      <Button fullWidth label="Request Updates…" glyph="note" type="negative" isDisabled={isUpdating} />
                    </TextareaDialog>
                  )}

                  <SignatureDialog model="supervisor" title="Sign Off as Supervisor" yesLabel="Apply Signature & Sign Off" onYes={signOff}>
                    <Button
                      fullWidth
                      label="Sign Off as Supervisor…"
                      glyph="signature"
                      type="primary"
                      color="green"
                      isDisabled={isUpdating || isInvalid}
                    />
                  </SignatureDialog>
                </>
              )}

              {!requireSupervisor && initialModel?.status !== 'closed' && (
                <ConfirmDialog
                  title="Close Clinical Note?"
                  message="Are you sure you want to close the Clinical Note?"
                  yesColor="green"
                  onYes={closeOff}
                >
                  <Button
                    fullWidth
                    label="Close Clinical Note…"
                    glyph="close"
                    color="green"
                    type="primary"
                    isLoading={isClosingClinicalNote}
                  />
                </ConfirmDialog>
              )}
            </>
          )}
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

const SessionRow = ({ isActive, data, onClick }: any) => {
  if (!data) return null

  return (
    <div css={STYLES.sessionRow} className={isActive && 'is-active'} onClick={onClick}>
      <Flex nowrap centerY>
        <Avatar src={data.client?.avatar} initials={data.client?.name} size={22} css={STYLES.sessionRowAvatar} />
        <div>
          <div css={STYLES.sessionRowTitle}>{data.client?.name}</div>
          <div css={STYLES.sessionRowDescription}>#{data.client?.behave_id}</div>
        </div>
      </Flex>

      <ClinicalNoteStatus small status={data.status} />
    </div>
  )
}

const AddSessionNoteOverlay = ({ initialData, isRefetching }: any) => {
  if (!initialData?.id || !initialData?.uuid) return null

  return (
    <Permission permission="clinical_notes.edit">
      <SummonOverlay
        overlay={
          <ClinicalNoteGroupOverlay
            dataID="new"
            position="center"
            invalidateKeys={[['clinical-note', initialData.id]]}
            initialData={{
              uuid: initialData.uuid,
              started_at: initialData.started_at,
              duration: initialData.duration,
              supervisor: initialData.supervisor,
              supervisor_id: initialData.supervisor_id,
              topic: initialData.topic,
              description: initialData.description,
              treatment_goal: initialData.treatment_goal,
            }}
          />
        }
      >
        <Button label="Add Client to Session…" glyph="add" size={200} type="primary" display="inline-flex" isDisabled={isRefetching} />
      </SummonOverlay>
    </Permission>
  )
}

const STYLES = {
  sessionRow: {
    '--title-color': COLORS.blue,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0.25rem 0.75rem',
    borderTop: `1px solid ${COLORS.divider}`,
    fontSize: '0.95rem',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: COLORS.hover,
    },

    '&.is-active': {
      '--title-color': COLORS.text,

      cursor: 'default',
      boxShadow: `inset 3px 0 0 ${COLORS.blue}`,
      background: `${tint(0.9, COLORS.vividBlue)} !important`,
    },
  },

  sessionRowAvatar: {
    marginRight: '0.5rem',
  },

  sessionRowTitle: {
    fontWeight: 600,
    color: 'var(--title-color)',
  },

  sessionRowDescription: {
    fontWeight: 400,
    color: COLORS.textMuted,
    fontSize: '0.9em',
  },

  sessionFooter: {
    padding: '0.55rem 0.75rem',
    borderTop: `1px solid ${COLORS.divider}`,
  },
}

export const ClinicalNoteGroupOverlay = withOverlayError(RootClinicalNoteGroupOverlay)
