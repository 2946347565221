import React from 'react'

import SmartStatus from '../../components/SmartStatus'

export const STATUSES = {
  draft: {
    label: 'Draft',
    color: 'gray',
  },
  pending: {
    label: 'Pending Review',
    color: 'yellow',
  },
  updates_required: {
    label: 'Updates Required',
    color: 'orange',
  },
  in_progress: {
    label: 'In Progress',
    color: 'blue',
  },
  approved: {
    label: 'Approved',
    color: 'paleGreen',
  },
  rejected: {
    label: 'Rejected',
    color: 'red',
  },
  closed: {
    label: 'Closed',
    color: 'green',
  },
  signed_off: {
    label: 'Signed Off',
    color: 'green',
  },
}

export const AuthorityFormStatus = ({ status, statuses = STATUSES, ...rest }) => {
  return <SmartStatus inline small status={status} statuses={statuses} {...rest} />
}
