import React from 'react'
import { useMedia } from 'use-media'
import { useParams } from 'react-router-dom-v5-compat'
import clsx from 'clsx'
import debounce from 'lodash/debounce'
import snakeCase from 'lodash/snakeCase'
import size from 'lodash/size'

import { PlainSwitch } from '../../../components/Forms/Switch'
import { useOverlay } from '../../../hooks/useOverlay'
import { useSettings } from '../../../hooks/useSettings'

import Button from '../../../components/Button'
import Card from '../../../components/Card'
import Checkbox from '../../../components/Forms/Checkbox'
import ContextShow from '../../../components/Forms/ContextShow'
import DeleteDialog from '../../../components/Dialogs/DeleteDialog'
import Divider from '../../../components/Divider'
import Form from '../../../components/Forms/Form'
import FormSection from '../../../components/Forms/FormSection'
import Input from '../../../components/Forms/Input'
import Label from '../../../components/Label'
import Markup from '../../../components/Markup'
import Option from '../../../components/Forms/Option'
import Overlay from '../../../components/Overlay'
import OverlayLoader from '../../../components/OverlayLoader'
import Section from '../../../components/Section'
import Select from '../../../components/Forms/Select'
import SmartRichTextEditor from '../../../components/Forms/SmartRichTextEditor'
import State from '../../../components/State'
import Status from '../../../components/Status'
import Switch from '../../../components/Forms/Switch'
import TableArrayForm from '../../../components/Forms/TableArrayForm'
import Tooltip from '../../../components/Tooltip'
import TreeItem from '../../../components/TreeItem'

import { AUTHORITY_FORMS, CATEGORIES, SUBCATEGORIES } from '../forms'
import { AuthorityFormLevelStatus } from '../AuthorityFormLevelStatus'
import { CustomQuestion } from './CustomQuestion'
import { getFormSchema } from '../schemas'
import { QuestionRenderer } from './QuestionRenderer'

export const DataFormPreview = ({ schema, settings, authorityName, isEvaluatorOnly, isInfoVisible }: any) => {
  const previewForm = React.useRef(null)

  const [isLoading, setIsLoading] = React.useState(true)

  React.useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 500)
  }, [])

  if (isLoading || !schema) return <State isLoading />

  return (
    <Form isCompact getForm={previewForm} className="[--field-max-width:100%]">
      {Object.keys(schema).map((categoryId: any, index) => {
        const section = schema[categoryId]
        const category = CATEGORIES[categoryId]

        const isAllHidden = Object.keys(section).every((subcategoryId) => {
          const sectionSettings = settings?.[categoryId]?.[subcategoryId]
          return !sectionSettings?.show
        })

        if (isAllHidden) return null

        return (
          <React.Fragment key={categoryId}>
            <Section title={category?.name} headingType="h2">
              <div className="grid gap-5 grid-cols-1">
                {Object.keys(section).map((subcategoryId: any) => {
                  const questions = section[subcategoryId]
                  const model = `${categoryId}.${subcategoryId}`
                  const subcategory = SUBCATEGORIES[subcategoryId]
                  const sectionSettings = settings?.[categoryId]?.[subcategoryId]

                  if (!questions || !sectionSettings || !sectionSettings.show) return null

                  return (
                    <FormSection key={model}>
                      <h3 id={subcategoryId}>{subcategory?.name || 'No Subcategory'}</h3>

                      {sectionSettings?.custom_text_before && <Markup value={sectionSettings?.custom_text_before} />}

                      {sectionSettings?.custom_questions_before?.map?.((question: any) => {
                        return <CustomQuestion key={question._id} question={question} sectionModel={section.model} />
                      })}

                      {questions?.map?.((question) => {
                        const fieldSettings = sectionSettings?.fields?.[question.identifier]

                        if (!fieldSettings?.show) return null

                        return (
                          <QuestionRenderer
                            isEditable
                            key={question.identifier}
                            authorityName={authorityName}
                            isEvaluatorOnly={isEvaluatorOnly}
                            question={question}
                            validations={fieldSettings?.required ? { presence: { message: 'This field is required' } } : undefined}
                            isInfoVisible={isInfoVisible}
                          />
                        )
                      })}

                      {sectionSettings?.custom_questions_after?.map?.((question: any) => {
                        return <CustomQuestion key={question._id} question={question} sectionModel={section.model} />
                      })}
                    </FormSection>
                  )
                })}
              </div>
            </Section>

            {index > 0 && <Divider />}
          </React.Fragment>
        )
      })}
    </Form>
  )
}
