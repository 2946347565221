import React from 'react'

import { titleCase, address } from '../../utils/functions'

import Button from '../../components/Button'
import Form from '../../components/Forms/Form'
import MultiObjectSelector from '../../components/Forms/Selectors/MultiObject/MultiObjectSelector'
import Grid from '../../components/Grid'
import Flex from '../../components/Flex'
import Input from '../../components/Forms/Input'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'
import Status from '../../components/Status'

import { withOverlayError } from '../../hocs/withOverlayError'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'

import FeeScheduleTable from './components/FeeScheduleTable'

const RootFeeScheduleOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    edit,
    form,
    archive,
    unArchive,
    initialModel,
    isArchiving,
    isEditable,
    isOverlayLoading,
    isSaving,
    isValid,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'insurance_new_fee_schedules',
    endpoint: '/insurance_new_fee_schedules',
    invalidate: 'insurance_new_fee_schedules',
    options: props,
  })

  const { online, timezone }: any = useSettings()

  if (isOverlayLoading) return <OverlayLoader showBackdrop position="center" />

  return (
    <Overlay showBackdrop position="center" maxWidth={110} onClose={close} isDirty={isEditable}>
      <Overlay.Header
        icon="insurance"
        title="Fee Schedule"
        subtitle={data?.status && <Status label={data?.status} color={data?.status === 'active' ? 'green' : 'grey'} />}
      />

      <Overlay.Content>
        <Form
          getForm={form}
          initialModel={initialModel}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          timezone={timezone}
        >
          <Section>
            <Grid gap="1rem" maxWidth="100%">
              <Input
                label="Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a fee schedule name',
                  },
                }}
              />

              <Flex stretchChildrenX gap="1rem">
                <MultiObjectSelector
                  label="Insurance Payers"
                  model="insurance_local_payers"
                  icon="insurance"
                  type="insurance_local_payers"
                  selectTitle={(data: any) => data?.name}
                  selectDescription={(data: any) => data?.notes}
                />

                <MultiObjectSelector
                  label="Healthcare Locations"
                  model="houses"
                  icon="properties"
                  type="insurance.locations"
                  selectTitle={(data: any) => data?.name}
                  selectDescription={(data: any) => address(data?.address)}
                />

                <MultiObjectSelector
                  label="Healthcare Providers"
                  model="employees"
                  icon="employees"
                  type="insurance.providers"
                  selectTitle={(data: any) => data?.name}
                  selectDescription={(data: any) => titleCase(data?.position)}
                />
              </Flex>

              <FeeScheduleTable value={data?.insurance_new_fee_schedule_services} model="insurance_new_fee_schedule_services" />
            </Grid>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer online={online}>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              flex="100 1 auto"
              onClick={save}
              isLoading={isSaving}
              isDisabled={!isValid}
            />
            <Button label="Cancel" glyph="cross" onClick={cancel} isDisabled={isSaving} />
          </>
        )}

        {!isEditable && (
          <>
            <Button label="Edit" glyph="edit" onClick={edit} flex="100 1 auto" permission="settings.fee_schedules.edit" />

            {data?.status === 'archived' ? (
              <Button
                label="Un-Archive"
                glyph="delete"
                type="default"
                color="gray"
                onClick={() => unArchive()}
                isLoading={isArchiving}
                permission="settings.fee_schedules.edit"
              />
            ) : (
              <Button
                label="Archive"
                glyph="delete"
                type="default"
                color="gray"
                onClick={() => archive()}
                isLoading={isArchiving}
                permission="settings.fee_schedules.edit"
              />
            )}
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const FeeScheduleOverlay = withOverlayError(RootFeeScheduleOverlay)
