import React from 'react'

import Button from '../../components/Button'
import Card from '../../components/Card'
import DataArray from '../../components/Forms/DataArray'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Textarea from '../../components/Forms/Textarea'

export const LocationTestimonials = (props: any) => {
  const { model = 'testimonials' } = props

  return (
    <DataArray model={model}>
      {({ orderedIds, add, remove, isEditable }) => (
        <>
          {orderedIds?.map((id: string, idx: number) => (
            <Card key={id} className="px-4 py-3 mb-4">
              <div className="flex items-center justify-between mb-2">
                <h4 className="mb-1">{`Testimonial #${idx + 1}`}</h4>
                <Button type="minimal" color="red" glyph="delete" label="Remove" onClick={() => remove(id)} size={100} />
              </div>

              <FormSection maxWidth="100%">
                <Textarea isCompact label="Testimonial" model={`${id}.testimonial`} />
                <Input isCompact label="Name / Attribution" model={`${id}.name`} />
              </FormSection>
            </Card>
          ))}

          {isEditable && (
            <div>
              <Button label={`Add New Testimonial`} glyph="add" type="default" display="inline-flex" size={200} onClick={add} />
            </div>
          )}
        </>
      )}
    </DataArray>
  )
}
