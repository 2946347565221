import {
  ORGANIZATION_DETAILS,
  LOCATION_DETAILS,
  LOCATION_CERTIFICATIONS,
  LOCATION_DEMOGRAPHICS,
  LOCATION_FACILITY_DETAILS,
  LOCATION_FINANCIALS,
  LOCATION_POLICIES,
  LOCATION_TREATMENT,
  LOCATION_MARKETING,
  LOCATION_TREATMENT_DETAILS,
  LOCATION_DOCUMENTATION,
  LOCATION_MEDICATION_MANAGEMENT,
  LOCATION_STAFF_DEVELOPMENT,
  PROGRAM_OPERATIONS,
} from './constants'

export const DIRECTORIES_DATA = {
  ...ORGANIZATION_DETAILS,
  ...LOCATION_DETAILS,
  ...LOCATION_CERTIFICATIONS,
  ...LOCATION_DEMOGRAPHICS,
  ...LOCATION_FACILITY_DETAILS,
  ...LOCATION_FINANCIALS,
  ...LOCATION_POLICIES,
  ...LOCATION_TREATMENT,
  ...LOCATION_MARKETING,
  ...LOCATION_TREATMENT_DETAILS,
  ...LOCATION_DOCUMENTATION,
  ...LOCATION_MEDICATION_MANAGEMENT,
  ...LOCATION_STAFF_DEVELOPMENT,
  ...PROGRAM_OPERATIONS,
} as const
