import React from 'react'
import { transparentize } from 'polished'
import { useLocation } from 'react-router-dom'
import size from 'lodash/size'

import { invalidateQueries, useUpdate } from '../hooks/useNewAPI'
import { MEDIA_QUERY } from '../theme'
import { titleCase, daysToWords, usDateTime } from '../utils/functions'
import { useDataFormOverlay } from '../hooks/useDataFormOverlay'
import { useSettings } from '../hooks/useSettings'

import { ExportPDFButton } from '../components/Buttons/ExportPDFButton'
import AddendumCard from '../components/AddendumCard'
import AddendumOverlay from './Overlays/AddendumOverlay'
import Alert from '../components/Alert'
import Button from '../components/Button'
import Card from '../components/Card'
import CardHeader from '../components/CardHeader'
import CardMeta from '../components/CardMeta'
import CardTitle from '../components/CardTitle'
import Checkbox from '../components/Forms/Checkbox'
import CheckboxGroup from '../components/Forms/CheckboxGroup'
import ClientProfileHeader from '../components/ClientProfileHeader'
import ContextShow from '../components/Forms/ContextShow'
import DataFormStatus from '../components/Statuses/DataFormStatus'
import DateTimeInput from '../components/Forms/DateTimeInput'
import DeleteDialog from '../components/Dialogs/DeleteDialog'
import Dialog from '../components/Dialog'
import Divider from '../components/Divider'
import Form from '../components/Forms/Form'
import FormSection from '../components/Forms/FormSection'
import Grid from '../components/Grid'
import HelpTagIframe from '../components/Help/HelpTagIframe'
import Input from '../components/Forms/Input'
import MultiObjectSelector from '../components/Forms/Selectors/MultiObject/MultiObjectSelector'
import Nav from '../components/Nav'
import ObjectSelector from '../components/Forms/Selectors/Object/ObjectSelector'
import OverlaySelector from '../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import Overlay from '../components/Overlay'
import OverlayLoader from '../components/OverlayLoader'
import PageGrid from '../components/PageGrid'
import Permission from '../components/Permission'
import ScrollMenu from '../components/ScrollMenu'
import ScrollView from '../components/ScrollView'
import Section from '../components/Section'
import SignatureDialog from '../components/Dialogs/SignatureDialog'
import SignaturePad from '../components/Forms/SignaturePad'
import SmartTextarea from '../components/Forms/SmartTextarea'
import Status from '../components/Status'
import SummonOverlay from '../components/SummonOverlay'
import Switch from '../components/Forms/Switch'
import Text from '../components/Typography/Text'
import TextareaDialog from '../components/Dialogs/TextareaDialog'
import TooltipButton from '../components/TooltipButton'
import snakeCase from 'lodash/snakeCase'
import { InlineInsuranceBillingFormSection } from './RCM/components/InlineInsuranceBillingFormSection'

import { TreatmentPlanSelector } from '../components/Forms/TreatmentPlanSelector'
import { FormFutureDateWarning } from './Misc/FormFutureDateWarning'

type Props = {
  testKey?: string

  // data form setup
  category: string
  subcategory: string
  title: string
  version?: string

  // data form options
  enableClientWorkflow?: boolean
  requestAuthorSignature?: boolean
  requestClientSignature?: boolean
  requestSupervisorSignature?: boolean
  requireSupervisor?: boolean
  signoffWithoutSupervisor?: boolean
  disableSignOff?: boolean
  enableEmployeeSignatures?: boolean
  enableTreatmentPlanSelector?: boolean

  // overlay props
  children?: React.ReactNode
  defaultDebounce?: number
  header?: React.ReactNode
  helpID?: string
  icon: string
  isFullyMinimized?: boolean
  isMinimized?: boolean
  maxWidth?: number
  minimizeEnabled?: boolean
  parent?: Parent
  stackIndex?: number
  type?: string
  onClose?: () => void
  clientPortalCanEdit?: boolean
  client?: any
  permissionName?: string
}

type Parent = {
  id: string
  type: string
}

const buildPermission = (permission_name?: string, type?: string) => {
  return permission_name ? `${permission_name}.${type}` : true
}

export const DataFormOverlay: React.FC<Props> = (props) => {
  const {
    // data form setup
    category,
    subcategory,
    title,
    version,
    testKey,

    // data form options
    enableEmployeeSignatures = false,
    enableClientWorkflow = false,
    requestAuthorSignature = true,
    requestClientSignature = true,
    requestSupervisorSignature = true,
    requireSupervisor = true,
    signoffWithoutSupervisor = false,
    clientPortalCanEdit = false,
    disableSignOff = false,
    enableTreatmentPlanSelector = false,

    // overlay props
    children,
    defaultDebounce = 300,
    header,
    helpID,
    icon,
    isFullyMinimized,
    isMinimized,
    maxWidth = 85,
    parent,
    stackIndex,
    position = 'right',
    showBackdrop,
  } = props

  const location: any = useLocation()

  const { isPortal, isBehave, isOwner, tenant, timezone, user, defaultSupervisor } = useSettings()

  const {
    cancel,
    client: apiClient,
    close,
    data,
    deleteRecord,
    edit,
    form,
    handleMinimize,
    handleFullyMinimize,
    highlightRequired,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onDateUpdate,
    onValidationUpdate,
    requestClientUpdates,
    requestEmployeeUpdates,
    save,
    sendForSupervisorReview,
    signOff,
    signOffWithoutSupervisor,
    updateStatusTo,
    queryKey,
    invalidateKeys,
  } = useDataFormOverlay({ title, category, subcategory, version, options: props, Overlay: DataFormOverlay })

  const client = props?.client || data?.client || apiClient
  const [isBillable, setIsBillable] = React.useState(false)

  const clientShouldComplete = enableClientWorkflow && data?.client_should_complete
  const isSupervisor = user?.id === data?.supervisor?.id
  const isTrialing = tenant?.plan_status === 'trialing'
  const permission_name = props.permissionName || location?.permission_name
  const requireEndDate = subcategory == 'history_and_physical' || subcategory == 'history_and_physical_follow_up'
  const isSignedOff = data?.status === 'signed_off'

  const hasFormContracts = size(data?.form_contracts) > 0

  if (isOverlayLoading) return <OverlayLoader showBackdrop={showBackdrop || isEditable} position={position} maxWidth={maxWidth} />

  return (
    <Overlay
      testKey={testKey}
      showBackdrop={showBackdrop || isEditable}
      minimizeEnabled
      position={position}
      onClose={close}
      maxWidth={maxWidth}
      isDirty={isEditable}
      isMinimized={isMinimized}
      isFullyMinimized={isFullyMinimized}
      onMinimize={handleMinimize}
      parent={parent}
      onFullyMinimize={handleFullyMinimize}
      stackIndex={stackIndex}
      closeOnBackdrop={!isEditable}
      closeWrapper={(element: any, onClose: any) => (
        <Dialog
          glyph="check"
          title="Close without saving?"
          message="All changes will be lost. This action cannot be undone."
          yesColor="red"
          yesLabel="Yes, Close Without Saving"
          skip={!isEditable || !onClose}
          onYes={onClose}
        >
          {element}
        </Dialog>
      )}
    >
      {header || (
        <Overlay.Header
          icon={icon}
          title={data?.name || title}
          titleAside={!disableSignOff && <DataFormStatus status={data?.status} />}
          description={<ClientProfileHeader client={client} />}
          help={helpID && <HelpTagIframe id={helpID} />}
        />
      )}

      {!isEditable && !isTrialing && (
        <Permission permission="clients.actions.export">
          <Overlay.SubHeader>
            <ExportPDFButton url={`/data_forms/${id}/pdf`} />
          </Overlay.SubHeader>
        </Permission>
      )}

      <ScrollView>
        <PageGrid scroll breakpoint={3} className="overlay-page-grid" css={STYLES.pageGrid}>
          <Nav
            top="0"
            breakpoint={3}
            title={' '}
            headingSize={300}
            desktopProps={{ title: null }}
            className="!bg-white shadow-right-hard-1 mq1024:max-w-[300px] [&_header]:!shadow-none [&_header]:!shadow-transparent [&_header]:!border-transparent"
          >
            <div className="opacity-80 text-[0.9rem] uppercase font-[700] tracking-[1px] mb-1">Table of Contents</div>
            <ScrollMenu />
          </Nav>

          <Overlay.Content>
            <Form
              getForm={form}
              initialModel={{
                ...initialModel,
                ...(isNew && defaultSupervisor && { supervisor: defaultSupervisor }),
              }}
              timezone={timezone}
              defaultDebounce={defaultDebounce}
              isEditable={isEditable}
              onValidationUpdate={onValidationUpdate}
              key={`updated-${data?.updated_at}`}
            >
              <Section
                scrollview={{
                  id: 'general',
                  name: 'General',
                }}
                headingType="h2"
                title="General"
              >
                <FormSection>
                  {!isNew && (
                    <>
                      <ObjectSelector
                        label="Author"
                        blankLabel="Select Staff…"
                        icon="employees"
                        type="employees"
                        model="author"
                        selectTitle={(data) => data?.name}
                        selectDescription={() => null}
                        isEditable={false}
                        disableLink={isPortal}
                      />
                    </>
                  )}

                  <Input
                    label="Name"
                    model="name"
                    defaultValue={title}
                    isEditable={isEditable && !isPortal}
                    validations={{
                      presence: {
                        message: 'Please enter a form name',
                      },
                    }}
                  />

                  {requireSupervisor && (
                    <ObjectSelector
                      isPolymorphic
                      label="Supervisor"
                      blankLabel="Select Supervisor…"
                      icon="employees"
                      type="clinical_supervisors"
                      model="supervisor"
                      selectTitle={(data) => data?.name}
                      selectDescription={() => null}
                      disableLink={isPortal}
                      isEditable={isEditable && !isPortal}
                      validations={{
                        presence: {
                          message: 'Please select a Supervisor',
                        },
                      }}
                    />
                  )}

                  <DateTimeInput
                    defaultToNow
                    model="dated_at"
                    label="Start Date and Time"
                    isEditable={isEditable && !isPortal}
                    onUpdate={onDateUpdate}
                    validations={{
                      presence: {
                        message: 'Please enter a date and time',
                      },
                    }}
                  />

                  <FormFutureDateWarning dateLabel="Start Date and Time" model="dated_at" />

                  <DateTimeInput
                    model="ended_at"
                    label="End Date and Time"
                    isEditable={isEditable && !isPortal}
                    onUpdate={onDateUpdate}
                    validations={
                      requireEndDate && {
                        presence: {
                          message: 'Please enter a date and time',
                        },
                      }
                    }
                  />

                  {!isPortal && enableTreatmentPlanSelector && <TreatmentPlanSelector clientId={client?.id} />}
                </FormSection>
              </Section>

              <Divider />

              {!isPortal && enableClientWorkflow && (
                <>
                  <Section headingType="h2" title="Client">
                    <FormSection>
                      <CheckboxGroup trueIcon="check" falseStyle="faded" falseIcon="cross">
                        <Checkbox label="Request client to complete this form" model="client_should_complete" />
                      </CheckboxGroup>

                      <ContextShow when="client_should_complete" is={true}>
                        <OverlaySelector
                          isPolymorphic
                          label="Reviewing Staff"
                          blankLabel="Select Reviewing Staff…"
                          icon="employees"
                          type="employees.active"
                          model="reviewing_employee"
                          selectTitle={(data: any) => data?.name}
                          selectDescription={() => null}
                          disableLink={isPortal}
                          validations={{
                            presence: {
                              message: 'Please select a Reviewing Staff',
                            },
                          }}
                        />
                      </ContextShow>
                    </FormSection>
                  </Section>

                  <Divider />
                </>
              )}

              {!isPortal && data?.status === 'employee_updates_required' && (
                <>
                  <Section>
                    <Alert type="negative" glyph="note">
                      <SmartTextarea
                        useDictation
                        useQuickText
                        fullWidth
                        label="Updates Requested before Sign-Off"
                        model="requested_updates"
                      />
                    </Alert>
                  </Section>

                  <Divider />
                </>
              )}

              {data?.status === 'client_updates_required' && (
                <>
                  <Section>
                    <Alert type="negative" glyph="note">
                      <SmartTextarea
                        useDictation
                        useQuickText
                        fullWidth
                        label="Client Updates Requested before Sign-Off"
                        model="client_updates_requested"
                        isEditable={isEditable && !isPortal}
                      />
                    </Alert>
                  </Section>

                  <Divider />
                </>
              )}

              {tenant.is_billed && !isPortal && (
                <>
                  <Section
                    headingType="h2"
                    title="Billing"
                    description="Use this section to record the billable service provided to the Client"
                    aside={<Switch defaultValue={false} model="is_billable" onUpdate={(state: any) => setIsBillable(state.value)} />}
                  >
                    <InlineInsuranceBillingFormSection isNew={isNew} isBillable={isBillable} initialModel={initialModel} />
                  </Section>

                  <Divider />
                </>
              )}

              {typeof children === 'function' ? children({ client, data, isEditable, isNew }) : children}

              {(requestClientSignature || requestAuthorSignature || (requireSupervisor && requestSupervisorSignature)) && (
                <>
                  <Divider />

                  <Section
                    scrollview={{
                      id: 'signatures',
                      name: 'Signatures',
                    }}
                    headingType="h2"
                    title="Signatures"
                  >
                    <FormSection layout="vertical" maxWidth={600}>
                      {requestClientSignature && (
                        <SignaturePad
                          label="Client Signature"
                          person={client}
                          model="client_signature"
                          signedAtModel="client_signed_at"
                          allowPin={false}
                        />
                      )}

                      {data?.author_signature && (
                        <SignaturePad
                          label="Author Signature"
                          person={data?.author}
                          allowPin={false}
                          model="author_signature"
                          signedAtModel="author_signed_at"
                        />
                      )}

                      {data?.supervisor_signature && (
                        <SignaturePad
                          label="Supervisor Signature"
                          person={data?.supervisor}
                          allowPin={false}
                          model="supervisor_signature"
                          signedAtModel="supervisor_signed_at"
                        />
                      )}

                      {!requestClientSignature && !data?.author_signature && !data?.supervisor_signature && (
                        <>
                          <p>No signatures added yet</p>
                        </>
                      )}
                    </FormSection>
                  </Section>
                </>
              )}

              {enableEmployeeSignatures && (
                <>
                  <Divider />

                  <Section
                    title="Staff Signatures"
                    scrollview={{
                      id: 'staff_signatures',
                      name: 'Staff Signatures',
                    }}
                    headingType="h2"
                    commentsModel="data.staff_signatures"
                  >
                    {isEditable && (
                      <MultiObjectSelector
                        icon="employees"
                        label="Staff"
                        model="employees"
                        type="employees"
                        selectTitle={(data: any) => data?.name}
                        selectDescription={(data: any) => titleCase(data?.position)}
                        value={data?.form_contracts?.map((o: any) => o?.signer)}
                      />
                    )}

                    {!isEditable && hasFormContracts && (
                      <div>
                        {data?.form_contracts?.map?.((contract: any) => (
                          <DataFormContract key={contract.id} contract={contract} queryKey={queryKey} />
                        ))}
                      </div>
                    )}
                  </Section>
                </>
              )}
            </Form>

            {isSignedOff && (
              <>
                <Divider />

                <Section
                  headingType="h2"
                  title="Addendums"
                  scrollview={{
                    id: 'addendums',
                    name: 'Addendums',
                  }}
                >
                  <Grid gap="1rem">
                    {data?.addendums.map((addendum: any) => {
                      return (
                        <AddendumCard
                          key={addendum.id}
                          data={addendum}
                          isSupervisor={isSupervisor}
                          onUpdate={() => {
                            invalidateQueries(queryKey, invalidateKeys)
                          }}
                        />
                      )
                    })}
                  </Grid>

                  <SummonOverlay
                    overlay={
                      <AddendumOverlay
                        reference={data}
                        onUpdate={() => {
                          invalidateQueries(queryKey, invalidateKeys)
                        }}
                      />
                    }
                  >
                    <Button size={200} label="Add New Addendum" glyph="add" type="primary" display="inline-flex" className="!mt-4" />
                  </SummonOverlay>
                </Section>
              </>
            )}
          </Overlay.Content>
        </PageGrid>
      </ScrollView>

      {data?.status !== 'signed_off' && !isPortal && (
        <Overlay.Footer>
          {isEditable && (
            <>
              <Button
                label={disableSignOff ? 'Save' : isNew ? 'Save as Draft' : 'Save Changes'}
                glyph="check"
                type="primary"
                color="green"
                onClick={save}
                isLoading={isSaving}
                isDisabled={isSaving}
                flex="100 1 auto"
                permission={buildPermission(permission_name, 'create')}
              />
              {isInvalid && (
                <Button
                  label="Highlight Required Fields"
                  glyph="view"
                  type="default"
                  color="orange"
                  onClick={highlightRequired}
                  isDisabled={isSaving}
                />
              )}
              {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
            </>
          )}

          {!isEditable && (
            <>
              {(data?.status === 'employee_draft' || data?.status === 'client_draft') && (
                <>
                  <Button
                    glyph="edit"
                    label={`Edit ${title}`}
                    type="default"
                    isDisabled={isLoading || isSaving}
                    onClick={edit}
                    flex="3 1 auto"
                    permission={buildPermission(permission_name, 'edit')}
                  />

                  {requireSupervisor && !disableSignOff && !isSupervisor && !signoffWithoutSupervisor && (
                    <SignatureDialog
                      model="author"
                      title="Sign & Send for Review"
                      yesLabel="Apply Signature & Send for Review"
                      onYes={sendForSupervisorReview}
                      isDisabled={isLoading || isInvalid || isSaving}
                      onDisabledClick={highlightRequired}
                    >
                      <TooltipButton
                        fullWidth
                        label="Sign & Send for Supervisor Review…"
                        glyph="signature"
                        type="default"
                        isDisabled={isLoading || isInvalid || isSaving}
                        permission={buildPermission(permission_name, 'edit')}
                        show={isInvalid}
                        message="Click to highlight required fields"
                      />
                    </SignatureDialog>
                  )}

                  {isSupervisor && !disableSignOff && (
                    <SignatureDialog
                      model="supervisor"
                      title="Sign Off as Supervisor"
                      yesLabel="Apply Signature & Sign Off"
                      onYes={signOff}
                      isDisabled={isLoading || isInvalid || isSaving}
                      onDisabledClick={highlightRequired}
                    >
                      <TooltipButton
                        fullWidth
                        label="Sign Off as Supervisor…"
                        glyph="signature"
                        type="primary"
                        color="green"
                        isDisabled={isLoading || isInvalid || isSaving}
                        permission={buildPermission(permission_name, 'edit')}
                        show={isInvalid}
                        message="Click to highlight required fields"
                      />
                    </SignatureDialog>
                  )}

                  {(signoffWithoutSupervisor || (!requireSupervisor && !requestSupervisorSignature)) && !disableSignOff && (
                    <SignatureDialog
                      model="author"
                      title="Sign Off"
                      yesLabel="Apply Signature & Sign Off"
                      onYes={signOffWithoutSupervisor}
                      isDisabled={isLoading || isInvalid || isSaving}
                      onDisabledClick={highlightRequired}
                    >
                      <TooltipButton
                        fullWidth
                        label="Sign Off"
                        glyph="signature"
                        type="primary"
                        color="green"
                        isDisabled={isLoading || isInvalid || isSaving}
                        permission={buildPermission(permission_name, 'edit')}
                        show={isInvalid}
                        message="Click to highlight required fields"
                      />
                    </SignatureDialog>
                  )}

                  <DeleteDialog
                    title={`Delete ${title}?`}
                    message={`Are you sure you want to delete this ${title}? This action cannot be undone.`}
                    onYes={deleteRecord}
                  >
                    <Button
                      glyph="delete"
                      label="Delete"
                      type="default"
                      color="red"
                      isDisabled={isLoading || isSaving}
                      fullWidth
                      permission={buildPermission(permission_name, 'delete')}
                    />
                  </DeleteDialog>
                </>
              )}

              {(data?.status === 'pending' ||
                data?.status === 'draft' ||
                data?.status === 'pending_review' ||
                data?.status === 'in_progress' ||
                data?.status === 'updates_required' ||
                data?.status === 'approved' ||
                data?.status === 'rejected' ||
                data?.status === 'employee_pending_review' ||
                data?.status === 'supervisor_pending_review') && (
                <>
                  <Button
                    glyph="edit"
                    label={`Edit ${title}`}
                    type="default"
                    isDisabled={isLoading || isSaving}
                    onClick={edit}
                    flex="3 1 auto"
                    permission={buildPermission(permission_name, 'edit')}
                  />

                  {clientShouldComplete && (
                    <TextareaDialog
                      model="requested_updates"
                      title="What updates should be made?"
                      onYes={requestClientUpdates}
                      yesLabel="Ask For Client Updates"
                    >
                      <Button
                        label="Request Client Updates…"
                        type="default"
                        color="orange"
                        glyph="asterisk"
                        isDisabled={isLoading}
                        permission={buildPermission(permission_name, 'edit')}
                      />
                    </TextareaDialog>
                  )}

                  {isSupervisor && (
                    <TextareaDialog
                      model="requested_updates"
                      title="What updates should be made?"
                      onYes={requestEmployeeUpdates}
                      yesLabel="Ask For Staff Updates"
                    >
                      <Button
                        label="Request Staff Updates…"
                        type="default"
                        color="orange"
                        glyph="asterisk"
                        isDisabled={isLoading}
                        permission={buildPermission(permission_name, 'edit')}
                      />
                    </TextareaDialog>
                  )}

                  {!isSupervisor && requireSupervisor && (
                    <TooltipButton
                      fullWidth
                      label="Re-Send for Supervisor Review"
                      glyph="signature"
                      type="default"
                      isDisabled={isLoading || isInvalid || isSaving}
                      onClick={() => updateStatusTo('supervisor_pending_review')}
                      onDisabledClick={highlightRequired}
                      permission={buildPermission(permission_name, 'edit')}
                      show={isInvalid}
                      message="Click to highlight required fields"
                    />
                  )}

                  {isSupervisor && (
                    <SignatureDialog
                      model="supervisor"
                      title="Sign Off as Supervisor"
                      yesLabel="Apply Signature & Sign Off"
                      onYes={signOff}
                      isDisabled={isLoading || isInvalid || isSaving}
                      onDisabledClick={highlightRequired}
                    >
                      <TooltipButton
                        fullWidth
                        label="Sign Off as Supervisor…"
                        glyph="signature"
                        type="primary"
                        color="green"
                        isDisabled={isLoading || isInvalid || isSaving}
                        permission={buildPermission(permission_name, 'edit')}
                        show={isInvalid}
                        message="Click to highlight required fields"
                      />
                    </SignatureDialog>
                  )}

                  {(signoffWithoutSupervisor || (!requireSupervisor && !requestSupervisorSignature)) && (
                    <SignatureDialog
                      model="author"
                      title="Sign Off"
                      yesLabel="Apply Signature & Sign Off"
                      onYes={signOffWithoutSupervisor}
                      isDisabled={isLoading || isInvalid || isSaving}
                      onDisabledClick={highlightRequired}
                    >
                      <TooltipButton
                        fullWidth
                        label="Sign Off"
                        glyph="signature"
                        type="primary"
                        color="green"
                        isDisabled={isLoading || isInvalid || isSaving}
                        permission={buildPermission(permission_name, 'edit')}
                        show={isInvalid}
                        message="Click to highlight required fields"
                      />
                    </SignatureDialog>
                  )}

                  <DeleteDialog
                    title={`Delete ${title}?`}
                    message={`Are you sure you want to delete this ${title}? This action cannot be undone.`}
                    onYes={deleteRecord}
                  >
                    <Button
                      glyph="delete"
                      label="Delete"
                      type="default"
                      color="red"
                      isLoading={isDeleting}
                      isDisabled={isLoading || isSaving}
                      fullWidth
                      permission={buildPermission(permission_name, 'delete')}
                    />
                  </DeleteDialog>
                </>
              )}

              {(data?.status === 'employee_updates_required' || data?.status === 'client_updates_required') && (
                <>
                  <Button
                    glyph="edit"
                    label={`Edit ${title}`}
                    type="default"
                    isDisabled={isLoading || isSaving}
                    onClick={edit}
                    flex="3 1 auto"
                    permission={buildPermission(permission_name, 'edit')}
                  />
                  <DeleteDialog
                    title={`Delete ${title}?`}
                    message={`Are you sure you want to delete this ${title}? This action cannot be undone.`}
                    onYes={deleteRecord}
                  >
                    <Button
                      fullWidth
                      glyph="delete"
                      label="Delete"
                      type="default"
                      color="red"
                      isDisabled={isLoading || isSaving}
                      permission={buildPermission(permission_name, 'delete')}
                    />
                  </DeleteDialog>

                  {!isSupervisor && requireSupervisor && (
                    <TooltipButton
                      fullWidth
                      label="Send for Supervisor Review"
                      glyph="signature"
                      type="default"
                      isDisabled={isLoading || isInvalid || isSaving}
                      onClick={() => updateStatusTo('supervisor_pending_review')}
                      onDisabledClick={highlightRequired}
                      permission={buildPermission(permission_name, 'edit')}
                      show={isInvalid}
                      message="Click to highlight required fields"
                    />
                  )}

                  {isSupervisor && (
                    <SignatureDialog
                      model="supervisor"
                      title="Sign Off as Supervisor"
                      yesLabel="Apply Signature & Sign Off"
                      onYes={signOff}
                      isDisabled={isLoading || isInvalid || isSaving}
                      onDisabledClick={highlightRequired}
                    >
                      <TooltipButton
                        fullWidth
                        label="Sign Off as Supervisor…"
                        glyph="signature"
                        type="primary"
                        color="green"
                        isDisabled={isLoading || isInvalid || isSaving}
                        permission={buildPermission(permission_name, 'edit')}
                        show={isInvalid}
                        message="Click to highlight required fields"
                      />
                    </SignatureDialog>
                  )}
                </>
              )}
            </>
          )}
        </Overlay.Footer>
      )}

      {data?.status !== 'signed_off' && isPortal && clientShouldComplete && (
        <Overlay.Footer>
          {isEditable && (
            <>
              <Button
                label="Save Changes"
                type="primary"
                color="green"
                glyph="check"
                onClick={save}
                isLoading={isSaving}
                isDisabled={isSaving}
                flex="100 1 auto"
              />
              <Button label="Cancel" glyph="cross" onClick={cancel} isDisabled={isSaving} />
            </>
          )}

          {!isEditable && (
            <>
              <Button label="Edit" glyph="edit" onClick={edit} />

              {(data?.status === 'client_draft' || data?.status === 'client_updates_required') && (
                <Button
                  label="Send For Review"
                  type="primary"
                  color="green"
                  glyph="check"
                  onClick={() => updateStatusTo('employee_pending_review')}
                  isLoading={isLoading}
                  isDisabled={isLoading}
                />
              )}
            </>
          )}
        </Overlay.Footer>
      )}

      {data?.status === 'signed_off' && (isBehave || isOwner) && (
        <Overlay.Footer>
          {!isEditable && (
            <>
              <DeleteDialog
                title={`Delete ${title}?`}
                message={`Are you sure you want to delete this ${title}? This action cannot be undone.`}
                onYes={deleteRecord}
              >
                <Button
                  fullWidth
                  glyph="delete"
                  label="Delete"
                  type="default"
                  color="red"
                  isDisabled={isLoading || isSaving}
                  permission={buildPermission(permission_name, 'delete')}
                />
              </DeleteDialog>
            </>
          )}
        </Overlay.Footer>
      )}

      {clientPortalCanEdit && isPortal && data?.status !== 'signed_off' && (
        <Overlay.Footer>
          {isEditable && (
            <>
              <Button
                label={isNew ? 'Save' : 'Save Changes'}
                glyph="check"
                type="primary"
                color="green"
                onClick={save}
                isLoading={isSaving}
                isDisabled={isSaving}
                flex="100 1 auto"
                permission={buildPermission(permission_name, isNew ? 'create' : 'edit')}
              />
              {isInvalid && (
                <Button
                  label="Highlight Required Fields"
                  glyph="view"
                  type="default"
                  color="orange"
                  onClick={highlightRequired}
                  isDisabled={isSaving}
                />
              )}
              {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
            </>
          )}

          {!isEditable && (
            <>
              <Button
                glyph="edit"
                label={`Edit ${title}`}
                type="default"
                isDisabled={isLoading || isSaving}
                onClick={edit}
                flex="3 1 auto"
                permission={buildPermission(permission_name, 'edit')}
              />
            </>
          )}
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

const DataFormContract = ({ contract, queryKey }: any) => {
  const { mutateAsync: signAsync, isLoading: isSaving } = useUpdate({
    name: ['sign-form-contract', contract?.id],
    url: `/form_contracts/${contract?.id}`,
    invalidate: queryKey,
  })

  const { timezone } = useSettings()

  if (!contract || !contract?.signer) return null

  const didSign = contract.status === 'signed'
  const employee = contract.signer

  return (
    <Card testKey={`${snakeCase(employee.name)}`} variant="variant-list" paddingX="0" paddingY="0.5rem" css={STYLES.formContract}>
      <CardHeader
        contentGap="0.35rem"
        after={
          !didSign && (
            <SignatureDialog
              model="employee"
              title={`${employee.name} Signature`}
              yesLabel="Save Signature"
              onYes={async ({ model, value }: any) => {
                if (!model || !value) return

                const signatureKey = model?.replace('employee_', '')

                await signAsync({
                  [signatureKey]: value,
                })
              }}
            >
              <Button label="Add Signature" glyph="signature" type="primary" size={200} display="inline-flex" />
            </SignatureDialog>
          )
        }
      >
        <CardTitle title={employee.name} className="!text-[1rem] !font-[700]" />

        <CardMeta>
          <Status label={titleCase(employee.position)} color="blue" />
          <Status label={didSign ? 'Signed' : 'Awaiting Signature'} color={didSign ? 'green' : 'gray'} />
        </CardMeta>

        <CardMeta className="!text-[0.9rem]">
          {!didSign && (
            <>
              <Text label="Email Notification: " description={daysToWords(contract?.email_sent_at)} glyph="email" glyphColor="gray" />
              <Text label="SMS Notification: " description={daysToWords(contract?.sms_sent_at)} glyph="sms" glyphColor="gray" />
            </>
          )}

          {didSign && contract.signed_at && (
            <Text label="Signed At: " glyph="signature" description={usDateTime(contract?.signed_at, timezone)} />
          )}
        </CardMeta>
      </CardHeader>
    </Card>
  )
}

const STYLES = {
  pageGrid: {
    flex: '1 1 auto',
  },

  nav: {
    [MEDIA_QUERY[3]]: {
      maxWidth: 300,
    },
  },

  formContract: {
    '&:last-of-type': { boxShadow: 'none !important' },
  },
}
