import React from 'react'

import Divider from '../../components/Divider'
import Form from '../../components/Forms/Form'
import Input from '../../components/Forms/Input'
import RichTextEditor from '../../components/Forms/RichTextEditor'

import { QuoteSection } from './QuoteSection'
import { QuoteIframeForm } from './QuoteIframeForm'
import { PreviewData } from './PreviewData'

export const QuoteSigningForm = (props: any) => {
  const { isEditable, formData, onValidationUpdate, onUpdate } = props

  const form = React.useRef(null)

  return (
    <Form
      isCompact
      getForm={form}
      isEditable={isEditable}
      initialModel={formData}
      onValidationUpdate={onValidationUpdate}
      onUpdate={onUpdate}
    >
      <QuoteSection
        showVariables
        title="Signing"
        onUpdate={onUpdate}
        isEditable={isEditable}
        isEnabled={formData?.use_signing === undefined || !!formData?.use_signing}
        enabledModel="use_signing"
      >
        <QuoteIframeForm model="signing" isEditable={isEditable} onUpdate={onUpdate} formData={formData} />

        <RichTextEditor label="Signature Legal Language Text" model="signature_legal_language_text" />
        <PreviewData isEditable={isEditable} model="signature_legal_language_text" />

        <Input label="Signature Acknowledgement Legal Text" model="signature_acknowledgement_legal_text" />
        <PreviewData isEditable={isEditable} model="signature_acknowledgement_legal_text" />
      </QuoteSection>

      <Divider className="!my-0" />

      <QuoteSection
        showVariables
        title="Service Terms"
        onUpdate={onUpdate}
        isEditable={isEditable}
        isEnabled={!!formData?.use_service_terms}
        enabledModel="use_service_terms"
      >
        <RichTextEditor label="Service Terms" model="service_terms_text" />
        <PreviewData isEditable={isEditable} model="service_terms_text" />
      </QuoteSection>

      <Divider className="!my-0" />

      <QuoteSection
        showVariables
        title="Terms & Conditions"
        onUpdate={onUpdate}
        isEditable={isEditable}
        isEnabled={!!formData?.use_terms_and_conditions}
        enabledModel="use_terms_and_conditions"
      >
        <RichTextEditor label="Terms & Conditions" model="terms_and_conditions_text" />
        <PreviewData isEditable={isEditable} model="terms_and_conditions_text" />
      </QuoteSection>

      <Divider className="!my-0" />

      <QuoteSection
        showVariables
        title="Privacy Policy"
        onUpdate={onUpdate}
        isEditable={isEditable}
        isEnabled={!!formData?.use_privacy_policy}
        enabledModel="use_privacy_policy"
      >
        <RichTextEditor label="Privacy Policy" model="privacy_policy_text" />
        <PreviewData isEditable={isEditable} model="privacy_policy_text" />
      </QuoteSection>

      <Divider className="!my-0" />

      <QuoteSection
        showVariables
        title="Software Access Agreement"
        onUpdate={onUpdate}
        isEditable={isEditable}
        isEnabled={!!formData?.use_software_access_agreement}
        enabledModel="use_software_access_agreement"
      >
        <RichTextEditor label="Software Access Agreement" model="software_access_agreement_text" />
        <PreviewData isEditable={isEditable} model="software_access_agreement_text" />
      </QuoteSection>

      <Divider className="!my-0" />

      <QuoteSection
        showVariables
        title="Business Associate Agreement"
        onUpdate={onUpdate}
        isEditable={isEditable}
        isEnabled={!!formData?.use_business_associate_agreement}
        enabledModel="use_business_associate_agreement"
      >
        <RichTextEditor label="Business Associate Agreement" model="business_associate_agreement_text" />
        <PreviewData isEditable={isEditable} model="business_associate_agreement_text" />
      </QuoteSection>

      <Divider className="!my-0" />

      <QuoteSection
        showVariables
        title="Software Terms Of Use Agreement"
        onUpdate={onUpdate}
        isEditable={isEditable}
        isEnabled={!!formData?.use_software_terms_of_use_agreement}
        enabledModel="use_software_terms_of_use_agreement"
      >
        <RichTextEditor label="Software Terms Of Use Agreement" model="software_terms_of_use_agreement_text" />
        <PreviewData isEditable={isEditable} model="software_terms_of_use_agreement_text" />
      </QuoteSection>
    </Form>
  )
}
