import React from 'react'
import kebabCase from 'lodash/kebabCase'
import { NavLink, Route, useParams } from 'react-router-dom-v5-compat'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { AuthorityFormAddDropdown } from '@behavehealth/constructs/Authority/AuthorityFormAddDropdown'
import { AuthorityFormStatus } from '@behavehealth/constructs/Authority/AuthorityFormStatus'

import Button from '@behavehealth/components/Button'
import Dropdown from '@behavehealth/components/Dropdown'
import DropdownItem from '@behavehealth/components/DropdownItem'
import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'
import Status from '@behavehealth/components/Status'

import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { useRouteURL } from '@behavehealth/hooks/useRouteURL'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { AuthorityGrievanceFormSubmission } from '@behavehealth/constructs/Authority/AuthorityGrievanceFormSubmission'

export const GrievanceForms = () => {
  const { url } = useRouteURL()

  return (
    <>
      <GrievanceFormsIndex />

      <AnimatedRoutes>
        <Route path={`:id`} element={<AuthorityGrievanceFormSubmission useV6Router back={url} />} />
        <Route path={`:formId/:id`} element={<AuthorityGrievanceFormSubmission useV6Router back={url} />} />
      </AnimatedRoutes>
    </>
  )
}

const GrievanceFormsIndex = () => {
  const { url } = useRouteURL()
  const { resource_id }: any = useParams()

  const tableProps = useDataTable({
    name: ['organization', resource_id, 'authority_complaint_submissions'],
    endpoint: `/organizations/${resource_id}/authority_complaint_submissions`,
    updateDeleteEndpoint: '/authority_complaint_submissions',
    params: { category: 'complaint' },
    enabled: !!resource_id,
    localStorageKey: 'authority_grievance_forms_v1',
  })

  const columns = React.useMemo(
    () => [
      {
        width: 260,
        id: 'name',
        model: 'name',
        title: 'Name',
        formatValue: ({ value, data }: any) => <MainCell as={NavLink} to={`${data.slug}/${data.id}`} value={value} />,
      },
      {
        width: 200,
        model: 'status',
        title: 'Status',
        formatValue: ({ value }: any) => <Status label="Draft" />,
      },
      {
        title: 'Tags',
        model: 'tags',
        type: 'tags',
        featureFlag: 'tags',
        editPermission: 'form_submissions.edit',
        disableSort: true,
      },
      {
        title: 'Date Submitted',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Last Edited',
        model: 'updated_at',
        type: 'date_time',
      },
    ],
    [],
  )

  return (
    <Page
      title="Grievance Forms"
      icon="grievance_incident_forms"
      actions={<AuthorityFormAddDropdown category="complaint" setLink={(form) => `${url}/${form.id}/new`} />}
    >
      <Grid>
        <DataTable
          asCard
          title="Grievance Forms"
          icon="grievance_incident_forms"
          columns={columns}
          {...tableProps}
          batchActionsConfig={[
            {
              type: 'delete',
              // permission: 'grievance_forms.delete',
              action: async ({ ids }: any) => {},
            },
          ]}
        />
      </Grid>
    </Page>
  )
}
