import { CATEGORIES, SUBCATEGORIES } from './forms'
import { QUESTIONS } from './questions'

export const getFormSchema = (formId: any) => {
  const sections: any = {}

  for (const question of QUESTIONS) {
    if (question.formId !== formId) continue

    const categoryId = CATEGORIES[question.categoryId]?.id || 'uncategorized'
    const subcategoryId = SUBCATEGORIES[question.subcategoryId]?.id || 'uncategorized'

    if (!sections[categoryId]) {
      sections[categoryId] = {}
    }

    if (!sections[categoryId][subcategoryId]) {
      sections[categoryId][subcategoryId] = []
    }

    sections[categoryId][subcategoryId].push(question)
  }

  return sections
}
