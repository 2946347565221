import React from 'react'
import { opacify } from 'polished'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import compact from 'lodash/compact'
import pluralize from 'pluralize'
import produce from 'immer'
import size from 'lodash/size'

import { COLORS, FEATURES } from '../../theme'
import { request } from '../../modules/axios'
import { useSettings } from '../../hooks/useSettings'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Flex from '../../components/Flex'
import Glyph from '../../components/Glyph'
import Grid from '../../components/Grid'
import Icon from '../../components/Icon'
import Link from '../../components/Link'
import State from '../../components/State'

import { NotionRenderer } from '../../components/Notion/NotionRenderer'
import type { NotionPage as INotionPage } from './NotionBrowser'

type Props = {
  id: string
  navigateTo?: (item: INotionPage) => void
}

const RESOURCE_ID_KEYS: any = {
  admissions: 'admission_id',
  alumni: 'alumnus_id',
  clients: 'client_id',
  employees: 'employee_id',
  locations: 'property_id',
  organizations: 'organization_id',
  payers: 'local_payer_id',
  programs: 'program_id',
}

const RESOURCES: any = Object.keys(RESOURCE_ID_KEYS)

export const NotionPage: React.FC<Props> = (props) => {
  const { id, navigateTo } = props

  const { isBehave } = useSettings()

  const [data, setData] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [error, setError] = React.useState(false)

  const resourceIDs = useSelector((state: any) => state.me?.tenant?.setup_helper)

  const getNotionData = async () => {
    setIsLoading(true)

    try {
      const response = await request.get(`${process.env.BH_NOTION_API_BASE}/api/page/${id}`, {
        headers: null,
      })
      setData(response.data)
    } catch (err) {
      console.error(err)
      setError(true)
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    if (!id) return

    getNotionData()
  }, [id])

  const components = React.useMemo(
    () => ({
      link: ({ className, children, href = '' }: any) => {
        if (href.includes('app.behavehealth.com')) {
          const to = href.split('app.behavehealth.com')[1]
          const feature = getFeatureBySlug(to) || FEATURES['default']

          const segments = to.replace('/', '').split('/')
          const isResourceLink = size(segments) >= 2 && RESOURCES.includes(segments[0])
          const isEHR = window?.location?.hostname === 'app.behavehealth.com' || window?.location?.hostname === 'app.localhost'

          if (!isEHR) {
            return (
              <span css={styles.linkWrapper}>
                <Icon icon={feature.icon} size={16} css={styles.linkIcon} />
                <a className={className} href={href} target="_blank">
                  {children}
                </a>
                <Glyph glyph="external_link" color={COLORS.blue} size={14} css={styles.externalLinkGlyph} />
              </span>
            )
          }

          if (isEHR && isResourceLink && resourceIDs) {
            const idKey = RESOURCE_ID_KEYS[segments[0]]
            const id = resourceIDs?.[idKey]
            const isDisabled = !id
            const resourceSegments: any = produce(segments, (draft) => {
              draft[1] = id
            })
            const resourceLink = id ? `/${resourceSegments.join('/')}` : `/${segments[0]}`

            return (
              <span css={styles.linkWrapper} className={isDisabled ? 'is-disabled' : ''}>
                <Icon icon={feature.icon} size={16} css={styles.linkIcon} />
                <Link className={className} children={children} to={resourceLink} />
              </span>
            )
          }

          return (
            <span css={styles.linkWrapper}>
              <Icon icon={feature.icon} size={16} css={styles.linkIcon} />
              <Link className={className} children={children} to={to} />
            </span>
          )
        }

        return <a className={className} children={children} href={href} target="_blank" />
      },
      pageLink: (pageLinkProps: any) => {
        const { className, children, href = '' } = pageLinkProps

        const pageLinkID = href.replace('/', '')
        const isActive = pageLinkID === id

        const handleClick = () => {
          navigateTo?.({ id: pageLinkID, title: getPageLinkTitle(children) })
        }

        return (
          <Link
            children={children}
            css={styles.notionPageLink}
            onClick={handleClick}
            className={clsx(isActive && 'is-active', className)}
          />
        )
      },
    }),
    [id],
  )

  if (isLoading || !data) {
    return <State isLoading />
  }

  return (
    <div css={styles.root}>
      {error ? (
        <Grid gap="1rem" className="!mt-4">
          <Alert type="negative">There has been an error while loading this page. Please try refreshing to try again.</Alert>
          <Button label="Refresh" glyph="reset" type="link" display="inline-flex" size={100} onClick={getNotionData} />
        </Grid>
      ) : (
        <>
          {isBehave && (
            <Flex justifyContent="flex-end">
              <Button
                label="Refresh"
                glyph="reset"
                type="link"
                size={100}
                onClick={getNotionData}
                css={{ marginTop: '0.75rem', float: 'right' }}
                display="inline-flex"
              />
            </Flex>
          )}
          <NotionRenderer fullPage disableHeader showTableOfContents darkMode={false} recordMap={data} components={components} />
        </>
      )}
    </div>
  )
}

const getPageLinkTitle = (children: any) => {
  if (!children) return

  let result = 'Page'

  const block = children.props?.block

  if (block) {
    const icon = block.format?.page_icon
    const title = block.properties?.title?.[0]

    result = compact([icon, title]).join(' ')
  }

  return result
}

const getFeatureBySlug = (slug: string) => {
  var arr: any = slug.split('/')

  for (var i = arr.length - 1; i >= 0; i--) {
    const item = arr[i].replaceAll('-', '_')
    const itemPlural = pluralize(item)

    if (item in FEATURES) return FEATURES[item]
    if (itemPlural in FEATURES) return FEATURES[itemPlural]
  }
}

const styles = {
  root: {
    '.notion.notion-app': {
      background: 'none',
    },

    'main.notion-page': {
      width: '100% !important',
      maxWidth: 'var(--notion-max-width) !important',
      marginTop: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },

    '.notion-simple-table': {
      borderRadius: 7,
      background: 'white',
      boxShadow: `rgba(72, 88, 95, 0.05) 0px 0px 0px 1px inset`,
      overflow: 'hidden',
      border: 'none !important',

      td: {
        border: `1px solid ${COLORS.divider}`,
      },

      'tr:first-child td': {
        background: '#F7F8FB !important',
      },

      'tr td:first-child': {
        background: '#F7F8FB !important',
      },
    },

    '.notion-simple-table-row': {},

    '.notion-page-icon-wrapper': {
      justifyContent: 'flex-start',

      img: {
        maxWidth: '48px !important',
        maxHeight: '48px !important',
        width: '48px !important',
        height: '48px !important',
      },
    },

    '.notion-page-no-cover.notion-page-has-image-icon': {
      paddingTop: '64px !important',
    },
  },

  linkWrapper: {
    display: 'inline-flex',
    alignItems: 'center',

    '&.is-disabled': {
      '.notion-link': {
        cursor: 'default',
        color: COLORS.textMuted,

        '&:hover': {
          borderColor: 'transparent',
        },
      },
    },
  },

  linkIcon: {
    marginRight: '0.25rem',
  },

  notionPageLink: {
    color: COLORS.blue,
    fontWeight: 600,

    span: {
      border: 'none',
    },

    '&.is-active': {
      color: COLORS.text,
      cursor: 'default',

      '&:hover': {
        border: 'none !important',
        textDecoration: 'none !important',
      },
    },
  },

  notionLink: {
    display: 'inline-flex',
    alignItems: 'center',
    color: COLORS.blue,
    fontWeight: 600,

    '&:hover': {
      border: 'none !important',
      textDecoration: 'none !important',
    },

    '.glyph': {
      marginLeft: '0.5em',
    },

    span: {
      border: 'none',
    },
  },

  externalLinkGlyph: {
    display: 'inline-flex',
    marginLeft: '0.25rem',
  },
}
