import React from 'react'
import { Route, Switch, Redirect, useRouteMatch, useLocation } from 'react-router-dom'

import AnimatedSwitch from '@behavehealth/components/AnimatedSwitch'
import Button from '@behavehealth/components/Button'
import Page from '@behavehealth/components/Page'
import Tabs from '@behavehealth/components/Tabs'

import { EOBOverlay } from '@behavehealth/constructs/RCM/EOBOverlay'
import { ERAOverlay } from '@behavehealth/constructs/RCM/ERAOverlay'
import { PaymentOverlay } from '@behavehealth/constructs/RCM/PaymentOverlay'

import Payments from './payments'
import EOBs from './eobs'
import ERAs from './eras'

export const PostingRCM = () => {
  const { url } = useRouteMatch()
  const location = useLocation()

  const { pathname } = location

  const paymentsSelected = pathname.includes(`${url}/payments`)
  const eobSelected = pathname.includes(`${url}/eobs`)

  return (
    <>
      <Page
        feature="posting"
        title="Posting"
        breakpoint="0"
        actions={
          <>
            {paymentsSelected && (
              <Button
                label="Add Payment"
                glyph="add"
                type="primary"
                link={`${location.pathname}/new`}
                permission="insurance_posting.create"
              />
            )}
            {eobSelected && (
              <Button
                label="Insurance EOBs"
                glyph="add"
                type="primary"
                link={`${location.pathname}/new`}
                permission="insurance_posting.create"
              />
            )}
          </>
        }
      >
        <Tabs>
          <Tabs.List className="-mt-4 mb-4">
            <Tabs.Item label="EOBs" to={`${url}/eobs`} />
            <Tabs.Item label="ERAs" to={`${url}/eras`} />
            <Tabs.Item label="Payments" to={`${url}/payments`} />
          </Tabs.List>
        </Tabs>

        <Switch>
          <Route path={`${url}/payments`} component={Payments} />
          <Route path={`${url}/eobs`} component={EOBs} />
          <Route path={`${url}/eras`} component={ERAs} />

          <Redirect exact from={url} to={`${url}/payments`} />
        </Switch>
      </Page>

      <AnimatedSwitch className="overlays" animation="animation" timeout={{ enter: 400, exit: 400 }}>
        <Route path={`${url}/payments/:id`} component={PaymentOverlay} />
        <Route path={`${url}/eobs/:id`} component={EOBOverlay} />
        <Route path={`${url}/eras/:id`} component={ERAOverlay} />
      </AnimatedSwitch>
    </>
  )
}
