import React from 'react'
import { tint } from 'polished'
import clsx from 'clsx'
import compact from 'lodash/compact'
import isFunction from 'lodash/isFunction'
import isUndefined from 'lodash/isUndefined'
import produce from 'immer'
import size from 'lodash/size'

import { arrayToMapWithKey } from '../../../utils/functions'
import { COLORS } from '../../../theme'
import { useFormField } from '../../Forms/hooks/useFormField'
import { useFuse } from '../../../hooks/useFuse'
import { useGet } from '../../../hooks/useNewAPI'
import { useStore } from '../utils/useStore'
import { withFormContext } from '../../Forms/context'

import Avatar from '../../../components/Avatar'
import Button from '../../../components/Button'
import Glyph from '../../../components/Glyph'
import State from '../../../components/State'
import SummonOverlay from '../../../components/SummonOverlay'
import Overlay from '../../../components/Overlay'

import { PopoverMenu, CustomPopoverMenuItem } from '../../../components/PopoverMenu'

import { WorksheetSearchInput } from '../WorksheetSearchInput'

const RootMultiObjectSelectorCell = (props: any) => {
  const { canEdit, column, form, isEditing, model, onBlur, rowData, setValue, validations, value } = props

  const config = column?.config
  const isLocalData = size(config?.data) > 0

  const endpoint = isFunction(config?.endpoint) ? config?.endpoint({ rowData }) : config?.endpoint
  const queryKey = isFunction(config?.queryKey) ? config?.queryKey({ rowData }) : config?.queryKey
  const queryParams = config?.queryParams

  const [searchTerm, setSetSearchTerm] = React.useState('')

  const { data: apiData, isLoading }: any = useGet({
    name: compact([queryKey, queryParams]).flat(),
    url: endpoint,
    params: queryParams,
    options: {
      keepPreviousData: true,
      enabled: canEdit && isEditing && !config?.data && !!endpoint && !!queryKey,
    },
  })

  const data = React.useMemo(() => {
    if (size(config?.data) > 0) return config.data
    else return apiData
  }, [config?.data, apiData])

  const dataMap = React.useMemo(() => {
    return arrayToMapWithKey(data, 'id')
  }, [data])

  const searchData = useFuse(data, searchTerm, { keys: ['name'] })

  const initialSelectedIds = size(value) > 0 ? value.map((item: any) => item.id) : []

  const [selectedIds, setSelectedIds]: any = React.useState(initialSelectedIds)

  const isEmpty = size(data) === 0

  const { formActions, formState } = useFormField({
    model: model,
    form: form,
    initialValue: value,
    validations: validations,
    isValid: size(validations) === 0,
    isRelations: config?.isRelations || !isLocalData,
  })

  const { isInvalid } = formState

  const updateField: any = useStore((state: any) => state.updateField)
  const showInvalid: any = useStore((state: any) => state.showInvalid)

  const [isOpen, setIsOpen] = React.useState(isEditing)

  const handleClose = () => {
    if (!selectedIds || !dataMap) return

    const result = []

    for (const id of selectedIds) {
      result.push({ ...dataMap[id], checked: true })
    }

    setValue(result)
    setSetSearchTerm('')

    if (onBlur) onBlur()
  }

  // Sync props value with state value
  React.useEffect(() => {
    setValue(props.value)
  }, [props.value])

  // Update form state
  React.useEffect(() => {
    if (!isUndefined(value)) {
      formActions.setValue(value)
      updateField({ model, value })
    }
  }, [model, value])

  const triggerClasses = clsx(
    size(value) === 0 && 'is-empty',
    isEditing && !config?.tableSelector && 'is-editing',
    isInvalid && showInvalid && 'is-invalid',
  )

  const displayValueClasses = clsx(size(value) === 0 && 'is-empty', isEditing && 'is-editing', isInvalid && showInvalid && 'is-invalid')

  if (!canEdit) return null

  const trigger = (
    <div css={STYLES.trigger} className={triggerClasses}>
      {canEdit && <Glyph glyph="triangle_down" size={9} className="select-trigger-triangle" />}
    </div>
  )

  return (
    <>
      {config?.tableSelector ? (
        <SummonOverlay
          overlay={
            <SelectorTableOverlay
              title={column.title}
              tableSelector={config.tableSelector}
              onSelect={(items) => {
                const selected = [items].flat()

                if (size(value) === 0) {
                  setValue(selected)
                  return
                }

                const addedIds = value?.map((item: any) => item?.id) || []
                const newItems: any = []

                for (const item of selected) {
                  if (!addedIds.includes(item.id)) newItems.push(item)
                }

                setValue((prev: any) => [...prev, ...newItems])
              }}
            />
          }
        >
          <div>{trigger}</div>
        </SummonOverlay>
      ) : (
        <PopoverMenu
          isOpen={isOpen}
          side={null}
          align={null}
          closeOnItemClick={false}
          onOpenUpdated={(currentIsOpen: boolean) => {
            if (!currentIsOpen) handleClose()

            setIsOpen(currentIsOpen)
          }}
          trigger={trigger}
        >
          {!data || isLoading || isEmpty ? (
            <State isLoading={isLoading} isEmpty={isEmpty} title={column?.title} />
          ) : (
            <>
              {size(data) > 0 && (
                <WorksheetSearchInput
                  value={searchTerm}
                  onChange={setSetSearchTerm}
                  onClear={() => {
                    setSetSearchTerm('')
                  }}
                />
              )}

              {searchData.map((record: any) => (
                <SelectItem
                  key={record.id}
                  data={record}
                  isActive={selectedIds.includes(record.id)}
                  selectTitle={config?.selectTitle}
                  selectDescription={config?.selectDescription}
                  onClick={() => {
                    setSelectedIds(
                      produce((draft: any) => {
                        const index = draft.indexOf(record.id)

                        if (index === -1) draft.push(record.id)
                        else draft.splice(index, 1)
                      }),
                    )
                  }}
                />
              ))}
            </>
          )}
        </PopoverMenu>
      )}

      {size(value) > 0 && (
        <div css={STYLES.displayValue} className={displayValueClasses}>
          {value.map((record: any) => {
            const title = config?.selectTitle?.(record) || record?.name

            return (
              <div key={record.id} css={STYLES.displayValueInner}>
                <div css={STYLES.displayValueGraphic}>
                  {!isUndefined(record?.avatar) && <Avatar src={record.avatar} initials={title} size={20} />}
                </div>
                {title}
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}

const SelectorTableOverlay = (props: any) => {
  const { title, tableSelector, onSelect, onClose } = props

  const [rows, setRows] = React.useState([])

  if (!tableSelector) return null

  const TableSelectorTag = tableSelector

  return (
    <Overlay showBackdrop closeOnBackdrop onClose={onClose} position="center" maxWidth={90}>
      <Overlay.Header title={title} />

      <Overlay.Content>
        <TableSelectorTag
          canBatchSelect
          useMainCellSelect
          onSelect={(item) => {
            onSelect?.(item)
            onClose?.()
          }}
          onRowSelectionUpdate={setRows}
        />
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label="Add Selected"
          type="primary"
          color="green"
          onClick={() => {
            onSelect?.(rows)
            onClose?.()
          }}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

const SelectItem = (props: any) => {
  const { data, selectTitle, selectDescription, isActive, onClick } = props

  if (!data) return null

  const title = selectTitle?.(data) || data?.name
  const description = selectDescription?.(data)

  return (
    <CustomPopoverMenuItem css={STYLES.selectItem} isActive={isActive} onClick={onClick}>
      <div className="select-item-graphic">
        <Glyph glyph="check" size={10} color={COLORS.green} />
      </div>

      {!isUndefined(data.avatar) && (
        <div className="select-item-avatar">
          <Avatar src={data.avatar} size={22} initials={title} />
        </div>
      )}

      {title && (
        <div>
          <div className="select-item-title">{title}</div>
          {description && <div className="select-item-description">{description}</div>}
        </div>
      )}
    </CustomPopoverMenuItem>
  )
}

const STYLES = {
  trigger: {
    display: 'flex',
    flex: '1 1 auto',
    padding: '0.2rem',
    paddingLeft: '0.4rem',
    paddingRight: '1.2rem',
    overflow: 'hidden',

    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 10,

    '.select-trigger-triangle': {
      position: 'absolute',
      right: '0.25rem',
      top: '50%',
      transform: 'translateY(-50%)',
      pointerEvents: 'none',
    },

    '&.is-editing': {
      borderRadius: 3,
      // background: COLORS.white,
      boxShadow: 'var(--input-focus-box-shadow)',
    },

    '&.is-empty': {
      fontStyle: 'italic',
      color: COLORS.textStronglyMuted,
    },

    '&.is-invalid': {
      display: 'block',
      background: tint(0.85, COLORS.red),
    },
  },

  selectItem: {
    display: 'flex',
    flexWrap: 'nowrap',
    cursor: 'pointer',
    fontSize: '0.88rem',
    width: '100%',
    lineHeight: 'normal',
    paddingTop: '0.4rem',
    paddingBottom: '0.4rem',
    paddingRight: '1rem',
    fontWeight: 600,

    '&:hover': {
      fontWeight: 600,
      background: COLORS.hover,
    },

    '.select-item-graphic': {
      opacity: 0,
      visibility: 'hidden',
      width: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    '.select-item-avatar': {
      marginRight: '0.5rem',
    },

    '.select-item-title': {
      fontWeight: 600,
    },

    '.select-item-description': {
      fontWeight: 400,
      color: COLORS.textMuted,
    },

    '&.is-active': {
      backgroundColor: tint(0.92, COLORS.green),

      '&:hover': {
        backgroundColor: tint(0.92, COLORS.green),
      },

      '.select-item-graphic': {
        opacity: 1,
        visibility: 'visible',
      },
    },
  },

  displayValue: {
    '.wrapping-wrap &': {
      display: 'grid',
      gridGap: '0.25rem',
    },

    '.wrapping-clip &': {
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      marginRight: '1rem',

      '& > *': {
        marginLeft: '0.55rem',
      },
    },
  },

  displayValueInner: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },

  displayValueGraphic: {
    marginRight: '0.4rem',
  },
}

export const MultiObjectSelectorCell: any = withFormContext(RootMultiObjectSelectorCell)
