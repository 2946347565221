import React from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import size from 'lodash/size'
import startCase from 'lodash/startCase'

import { useAPI } from '../../../hooks'
import withSettings from '../../../hocs/withSettings'

import Alert from '../../../components/Alert'
import Button from '../../../components/Button'
import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import Grid from '../../../components/Grid'
import State from '../../../components/State'
import Status from '../../../components/Status'
import SummonOverlay from '../../../components/SummonOverlay'
import Table from '../../../components/Table/Table'
import TableCell from '../../../components/Table/TableCell'
import Workflow from '../../../components/Workflow/Workflow'

import { ContactOverlay } from '../../Contacts/ContactOverlay'

const columns = (client: any) => [
  {
    width: 180,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'name',
    Header: 'Name',
    Cell: ({ value, row }: any) => (
      <SummonOverlay overlay={<ContactOverlay showBackdrop dataID={row.original.id} reference={client} />}>
        <TableCell.MainLink label={value} avatar="" />
      </SummonOverlay>
    ),
  },
  {
    width: 130,
    accessor: 'relationship',
    Header: 'Relationship',
    Cell: ({ value }: any) => <TableCell value={startCase(value)} />,
  },
  {
    width: 200,
    accessor: 'is_emergency',
    Header: 'Emergency Contact',
    Cell: ({ value }: any) => {
      if (!value) return 'Non-emergency'
      return <Status color="yellow" label="Emergency Contact" />
    },
  },
  {
    width: 180,
    accessor: 'roi',
    Header: 'Release of Information',
    Cell: ({ value }: any) => {
      if (!value) return 'Not allowed'
      return <Status color="green" label="R.O.I. Allowed" />
    },
  },
  {
    width: 210,
    accessor: 'preferred_contact_method',
    Header: 'Preferred Contact Method',
    Cell: ({ value }: any) => {
      if (!value) return <TableCell.NotApplicable />
      return <TableCell.GlyphValue glyph={value === 'any' ? null : value} value={startCase(value)} />
    },
  },
]

const AddContactButton = ({ client }: any) => {
  return (
    <SummonOverlay overlay={<ContactOverlay dataID="new" reference={client} />}>
      <Button label="Add Contact" glyph="add" type="primary" display="inline-flex" permission="contacts.create" />
    </SummonOverlay>
  )
}

const Contacts = ({ client, setStatus }: any) => {
  const { resource_id }: any = useParams()

  const { goNext }: any = React.useContext(Workflow.Context)
  const { isRequired }: any = React.useContext(Workflow.PanelContext)

  const contacts = useSelector((state: any) => state.data?.contacts?.data)
  const loading = useSelector((state: any) => state.data?.contacts?.loading)
  const isEmpty = size(contacts) === 0

  useAPI('contacts', `/residents/${resource_id}/contacts`)

  const onContinue = async () => {
    setStatus('completed')
    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  React.useEffect(() => {
    isEmpty ? setStatus('todo') : setStatus('completed')
  }, [isEmpty])

  if (isEmpty || loading) {
    return (
      <State
        isLoading={loading}
        isEmpty={isEmpty}
        title="Contacts"
        icon="contacts"
        emptyDescription="To have additional contacts as signers or payers please add them as a contact."
        emptyActions={<AddContactButton client={client} />}
      />
    )
  }

  return (
    <Grid gap="1rem">
      <Alert contrast glyph="info">
        To have additional contacts as signers or payers please add them as a contact.
      </Alert>

      <Card>
        <Table
          title="Contacts"
          icon="contacts"
          localStorageKey="clients_contacts"
          data={contacts}
          columns={columns(client)}
          isLoading={loading}
          showSettings={false}
        />

        <CardContent>
          <AddContactButton />
        </CardContent>
      </Card>

      <Workflow.Buttons>
        <Workflow.ContinueButton onClick={onContinue} />
        {!isRequired && <Workflow.SkipButton onClick={onSkip} />}
      </Workflow.Buttons>
    </Grid>
  )
}

export default withSettings(Contacts)
