import React from 'react'
import { useParams } from 'react-router-dom'

import { withOverlayError } from '../../hocs/withOverlayError'
import { useGet } from '../../hooks/useNewAPI'
import { FEATURES } from '../../theme'

import ClaimTypeStatus from '../../components/Statuses/ClaimTypeStatus'
import ClientProfileHeader from '../../components/ClientProfileHeader'
import Flex from '../../components/Flex'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'

import { ClaimOverlayForm } from './ClaimOverlay/ClaimOverlayForm'
import { ClientTagSelector } from '../Tags/ClientTagSelector'

import ClaimFlagStatus from './ClaimFlagStatus'
import ClaimStatus from './ClaimStatus'

const RootNewClaimPreviewOverlay = (props: any) => {
  const params: any = useParams()

  const id = props?.dataID || params.id
  const isNew = id === 'new'

  const { data, isLoading } = useGet({
    name: ['insurance_claims', id, 'summary'],
    url: `/insurance_claims/${id}/summary`,
    options: { enabled: !isNew },
  })

  const close = () => {
    if (props.onClose) {
      return props.onClose()
    }
  }

  if (isLoading) return <OverlayLoader position="right" maxWidth={100} onClose={close} />

  return (
    <Overlay fullheight showBackdrop={props.showBackdrop} maxWidth={100} onClose={close}>
      <Overlay.Header
        icon={FEATURES.claims.icon}
        title={isNew ? 'Claim' : `Claim #${data?.claim?.identifier}`}
        titleAside={
          !isNew && (
            <Flex gap="0.5rem">
              <ClaimTypeStatus status={data?.claim?.category} />
              <ClaimStatus status={data?.claim?.status} />
              <ClaimFlagStatus status={data?.claim?.flagged_status} />
            </Flex>
          )
        }
        description={
          data?.claim?.client && (
            <div className="mt-1">
              <Flex gap="0.5rem">
                <div>
                  <ClientProfileHeader client={data.claim.client} />
                </div>

                <div>
                  <ClientTagSelector client={data.claim.client} />
                </div>
              </Flex>
            </div>
          )
        }
      />

      <ClaimOverlayForm id={id} canEdit={false} />
    </Overlay>
  )
}

export const NewClaimPreviewOverlay = withOverlayError(RootNewClaimPreviewOverlay)
