import React from 'react'
import { Link } from 'react-router-dom-v5-compat'

import { useOverlay } from '@behavehealth/hooks/useOverlay'
import { useSettings } from '@behavehealth/hooks/useSettings'

import AddressSelectorInput from '../../components/Forms/AddressSelectorInput'
import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import DateInput from '../../components/Forms/DateInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import EmailInput from '../../components/Forms/EmailInput'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Label from '../../components/Label'
import MultiObjectSelector from '../../components/Forms/Selectors/MultiObject/MultiObjectSelector'
import NumberInput from '../../components/Forms/NumberInput'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import PhoneInput from '../../components/Forms/PhoneInput'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import Textarea from '../../components/Forms/Textarea'

import { DIRECTORIES_DATA } from '../AuthorityDirectories/data'

export const AuthorityOrganizationProgramOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'organization_directory_programs',
    endpoint: '/organization_directory_programs',
    invalidate: 'organization_directory_programs',
    options: props,
    disableParentRequest: true,
    onSaveSuccessful: props.onSaveSuccessful,
    onDeleteSuccessful: props.onDeleteSuccessful,
  })

  const { referenceID } = props

  const { timezone } = useSettings()

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  return (
    <Overlay showBackdrop={isEditable} onClose={close}>
      <Overlay.Header title="Program" icon="programs" />

      <Overlay.Content>
        <Form
          getForm={form}
          timezone={timezone}
          initialModel={initialModel}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          linked={
            isNew && {
              organization_id: referenceID,
            }
          }
        >
          <Section title="Program Details" headingType="h2">
            <FormSection maxWidth="100%">
              <Input
                label="Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please add a name',
                  },
                }}
              />

              <CheckboxGroup withToggle trueIcon="check" falseIcon="cross" falseStyle="none">
                <Checkbox
                  label="Share this Program in Directories"
                  description="Allow the program to be shared in Directories"
                  model="is_shared_with_directories"
                />
              </CheckboxGroup>

              <Alert small contrast icon="directories">
                <b>Note:</b> go to{' '}
                <Link to="/settings/directories" target="_blank">
                  Settings → Directories
                </Link>{' '}
                to manage your directories and the data points that are shared.
              </Alert>

              <Textarea label="Description" model="description" />

              <MultiObjectSelector
                label="Linked Directory Locations"
                model="organization_directory_locations"
                type="organization.organization_directory_locations"
                icon="locations"
                dependentValue={referenceID}
                selectTitle={(data: any) => data.name}
              />

              <EmailInput label="Email" model="email" />
              <PhoneInput label="Phone" model="phone_no" />

              <CheckboxGroup
                withToggle
                label="Genders/Identities Served"
                layout="vertical-dense"
                trueIcon="check"
                falseIcon="cross"
                falseStyle="faded"
              >
                <Checkbox label="Men" model="gender.men_only" />
                <Checkbox label="Women" model="gender.women_only" />
                <Checkbox label="Gender Neutral" model="gender.gender_neutral" />
                <Checkbox label="Transgender Men" model="gender.transgender_men" />
                <Checkbox label="Transgender Women" model="gender.transgender_women" />
                <Checkbox label="Non-Binary" model="gender.non_binary" />
                <Checkbox label="Intersex" model="gender.intersex" />
                <Checkbox label="LGBTQIA+ Friendly" model="gender.lgbtqia_friendly" />
              </CheckboxGroup>

              <div>
                <Label label="Capacity" />
                <Flex gap="0.75rem">
                  <NumberInput size={4} suffix="Used Capacity" model="capacity_used" />
                  <NumberInput size={4} suffix="Total Capacity" model="capacity_max" />
                </Flex>
              </div>

              <AddressSelectorInput label="Address" model="address" />

              {!isNew && (
                <DateInput isEditable={false} label="Last Updated" value={data?.updated_at} tooltip={DIRECTORIES_DATA.updated_at.tooltip} />
              )}
            </FormSection>
          </Section>

          <Divider />

          <Section title="Program Operations" headingType="h2">
            <FormSection maxWidth="100%">
              <Textarea
                label="Daily Schedule Details"
                model="daily_schedule_details"
                tooltip={DIRECTORIES_DATA.daily_schedule_details.tooltip}
              />

              <CheckboxGroup
                withToggle
                label="Meal Service Type"
                tooltip={DIRECTORIES_DATA.meal_service_type.tooltip}
                layout="vertical-dense"
                trueIcon="check"
                falseIcon="cross"
                falseStyle="faded"
              >
                {Object.entries(DIRECTORIES_DATA.meal_service_type.options).map(([value, label]) => (
                  <Checkbox key={value} label={label} model={`meal_service_type.${value}`} />
                ))}
              </CheckboxGroup>

              <CheckboxGroup
                withToggle
                label="Technology Policy"
                model="technology_policy"
                tooltip={DIRECTORIES_DATA.technology_policy.tooltip}
                layout="vertical-dense"
                trueIcon="check"
              >
                {Object.entries(DIRECTORIES_DATA.technology_policy.options).map(([model, label]) => (
                  <Checkbox key={model} model={`technology_policy.${model}`} label={label} />
                ))}
              </CheckboxGroup>

              <Input label="Hours of Operation" model="hours_of_operation" tooltip={DIRECTORIES_DATA.hours_of_operation.tooltip} />

              <Input
                label="Staff Coverage Schedule"
                model="staff_coverage_schedule"
                tooltip={DIRECTORIES_DATA.staff_coverage_schedule.tooltip}
              />

              <RadioGroup
                label="Weekend Operations"
                model="weekend_operations"
                tooltip={DIRECTORIES_DATA.weekend_operations.tooltip}
                layout="vertical-dense"
              >
                {Object.entries(DIRECTORIES_DATA.weekend_operations.options).map(([value, label]) => (
                  <Radio key={value} value={value} label={label} />
                ))}
              </RadioGroup>

              <RadioGroup
                label="Holiday Operations"
                model="holiday_operations"
                tooltip={DIRECTORIES_DATA.holiday_operations.tooltip}
                layout="vertical-dense"
              >
                {Object.entries(DIRECTORIES_DATA.holiday_operations.options).map(([value, label]) => (
                  <Radio key={value} value={value} label={label} />
                ))}
              </RadioGroup>

              <CheckboxGroup
                withToggle
                label="Facility Operation Type"
                model="facility_operation_type"
                tooltip={DIRECTORIES_DATA.facility_operation_type.tooltip}
                layout="vertical-dense"
                trueIcon="check"
              >
                {Object.entries(DIRECTORIES_DATA.facility_operation_type.options).map(([model, label]) => (
                  <Checkbox key={model} model={`facility_operation_type.${model}`} label={label} />
                ))}
              </CheckboxGroup>
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />

            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              glyph="edit"
              label="Edit Location"
              type="default"
              isDisabled={isLoading}
              onClick={edit}
              flex="100 1 auto"
              // permission="__PERMISSION__.edit"
            />

            <DeleteDialog
              title="Delete Location?"
              message="Are you sure you want to delete this Location? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={isDeleting}
                fullWidth
                // permission="__PERMISSION__.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}
