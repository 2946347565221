import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import { usDateTime } from '../../../utils/functions'
import { useAPIwithInitialClear } from '../../../hooks/useAPI'
import withSettings from '../../../hocs/withSettings'

import Card from '../../../components/Card'
import EVOBCoverageStatus from '../../../components/Statuses/EVOBCoverageStatus'
import EVOBStatus from '../../../components/Statuses/EVOBStatus'
import FormSection from '../../../components/Forms/FormSection'
import State from '../../../components/State'
import SummonOverlay from '../../../components/SummonOverlay'
import Table from '../../../components/Table/Table'
import TableCell from '../../../components/Table/TableCell'
import Workflow from '../../../components/Workflow/Workflow'

import { EVOBOverlay } from '../../EVOB/EVOBOverlay'

const columns = (timezone: string) => [
  {
    width: 260,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'created_at',
    Header: 'Date Requested',
    Cell: ({ value, row }: any) => {
      return (
        <SummonOverlay overlay={<EVOBOverlay showBackdrop dataID={row.original.id} initialData={row.original} />}>
          <TableCell.MainLink label={usDateTime(value, timezone)} />
        </SummonOverlay>
      )
    },
  },
  {
    width: 160,
    accessor: 'status',
    Header: 'eVOB Status',
    Cell: ({ value }: any) => <EVOBStatus status={value} />,
  },
  {
    width: 160,
    accessor: 'insurance_eligibility_summary.plan_coverage_summary.status',
    Header: 'Coverage Status',
    Cell: ({ value }: any) => {
      if (!value) return <TableCell.Empty />
      return <EVOBCoverageStatus status={value} />
    },
  },
  {
    width: 160,
    accessor: 'date_of_service',
    Header: 'Date of Service',
  },
  {
    width: 160,
    accessor: 'payer_name',
    Header: 'Insurance Company',
  },
]

const InsuranceEvobs = ({ setStatus, timezone }: any) => {
  const match = useRouteMatch()

  const { goNext }: any = React.useContext(Workflow.Context)
  const { resource_id }: any = match.params

  const data = useSelector((state: any) => state.data.insurance_evobs?.data)
  const loading = useSelector((state: any) => state.data.insurance_evobs?.loading)
  const isEmpty = size(data) === 0

  useAPIwithInitialClear('insurance_evobs', `/residents/${resource_id}/insurance_evobs`, false)

  const onContinue = () => {
    setStatus('completed')
    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  if (isEmpty || loading) {
    return (
      <Card>
        <State isLoading={loading} isEmpty={isEmpty} title="Insurance eVOBs" icon="financials" emptyDescription="No eVOBs added yet" />
      </Card>
    )
  }

  return (
    <FormSection maxWidth="100%">
      <Card>
        <Table icon="financials" title="Insurance eVOBs" showSettings={false} data={data} columns={columns(timezone)} />
      </Card>

      <Workflow.Buttons>
        <Workflow.ContinueButton onClick={onContinue} />
        <Workflow.SkipButton onClick={onSkip} />
      </Workflow.Buttons>
    </FormSection>
  )
}

export default withSettings(InsuranceEvobs)
