import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import produce from 'immer'

import { countWord, mapToArray } from '../../utils/functions'
import { useCreate } from '../../hooks/useNewAPI'
import Notifications from '../../modules/notifications'
import { PAYMENT_METHODS_LIST } from '../../utils/constants'

import Button from '../../components/Button'
import Glyph from '../../components/Glyph'
import Grid from '../../components/Grid'
import Overlay from '../../components/Overlay'
import useAnalytics from '../../hooks/useAnalytics'

import { QUICK_ADD } from '../../components/Worksheet/config'
import { Worksheet } from '../../components/Worksheet/Worksheet'

import { AllClientsTable } from '../../components/Forms/Selectors/tables/AllClientsTable'

export const PaymentWorksheetOverlay = (props: any) => {
  const { onClose, onSuccess, invalidateKeys } = props

  const { trackEvent } = useAnalytics()

  const form = React.useRef()
  const history = useHistory()
  const location = useLocation()

  const [isValid, setIsValid] = React.useState(false)
  const [rowsCount, setRowsCount] = React.useState(0)
  const [data, setData]: any = React.useState(null)

  const { mutateAsync: save, isLoading: isSaving }: any = useCreate({
    name: ['create-payments'],
    url: `/financial_transactions/batch`,
    invalidate: 'financial-transactions',
    invalidateKeys: invalidateKeys,
    onSuccess: () => {
      Notifications.send(`Successfully created ${countWord('Payments', rowsCount)}`, 'positive')

      trackEvent({ name: 'Worksheet Saved', params: { worksheetType: 'Payment' } })

      if (onSuccess) onSuccess()
    },
  })

  const close = () => {
    if (onClose) return onClose()

    const url = location?.parent ? location.parent.url : location.pathname.substr(0, location.pathname.lastIndexOf('/'))
    history.push(url)
  }

  const handleSave = async () => {
    const dataArray = mapToArray(data)

    const formData = form.current.getFormValue()

    const result = produce(dataArray, (draft: any) => {
      for (const record of draft) {
        Object.assign(record, formData[record._id])

        record.category = 'payment'

        if (record.client) {
          record.financial_plan_id = record.client?.financial_plan?.id
        }

        if (record.reference_category === 'client' && record.client) {
          record.reference_id = record.client.id
          record.reference_type = record.client.type
        }

        if (record.payed_with?.model) {
          record.payed_with = record.payed_with.model
        }

        delete record._id
        delete record.client
        delete record.reference
        delete record.payment_method
      }
    })

    await save(result)

    close()
  }

  React.useEffect(() => {
    trackEvent({ name: 'Worksheet Opened', params: { worksheetType: 'Payment' } })
  }, [])

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Client',
        id: 'client_reference',
        model: 'client',
        type: 'object_selector',
        config: {
          quickAdd: QUICK_ADD.all_clients,
          tableSelector: AllClientsTable,
          isPolymorphic: true,
          endpoint: '/residents',
          queryKey: 'residents',
          queryParams: { status: 'current' },
          getIsOptionDisabled: (data: any) =>
            data?.financial_plan?.id
              ? { isDisabled: false }
              : {
                  isDisabled: true,
                  message:
                    'The data required to create Payments could not be loaded from our servers. To try again, please close this Overlay and refresh. If this problem persists, please get in touch with Support.',
                },
          validations: {
            presence: {
              message: 'Please select a client',
            },
          },
        },
      },
      {
        title: 'Name',
        model: 'name',
        config: {
          validations: {
            presence: {
              message: 'Please enter a charge name',
            },
          },
        },
      },
      {
        title: 'Amount',
        model: 'amount',
        type: 'amount',
        config: {
          validations: {
            presence: {
              message: 'Please enter an amount',
            },
            numericality: {
              greaterThan: 0,
              message: 'Please enter an amount',
            },
          },
        },
      },
      {
        title: 'Payment Date',
        model: 'transactioned_at',
        type: 'date_time',
        config: {
          defaultTo: 'now',
          validations: {
            presence: {
              message: 'Please enter a payment date',
            },
          },
        },
      },
      {
        title: 'Payed With',
        model: 'payed_with',
        type: 'object_selector',
        config: {
          data: PAYMENT_METHODS_LIST,
          selectTitle: (data: any) => (
            <>
              <Glyph glyph={data.glyph} size={18} /> {data.name}
            </>
          ),
          validations: {
            presence: {
              message: 'Please select a payment method',
            },
          },
        },
      },
      {
        title: 'Payer Type',
        model: 'reference_category',
        type: 'select',
        config: {
          getIsApplicable: ({ rowData }: any) => rowData?.payed_with && rowData?.payed_with?.model !== 'online',
          options: [
            { label: 'Client', value: 'client' },
            { label: 'Contact', value: 'contact' },
            { label: 'Organization', value: 'organization' },
          ],
        },
      },
      {
        title: 'Contact Payer',
        id: 'contact_payer',
        model: 'reference',
        type: 'object_selector',
        config: {
          isPolymorphic: true,
          endpoint: ({ rowData }: any) => rowData?.client?.id && `/residents/${rowData.client.id}/contacts`,
          queryKey: ({ rowData }: any) => rowData?.client?.id && ['client', rowData?.client?.id, 'contacts'],
          getIsApplicable: ({ rowData }: any) =>
            rowData?.reference_category === 'contact' && rowData?.payed_with && rowData?.payed_with?.model !== 'online',
        },
      },
      {
        title: 'Organization Payer',
        id: 'organization_payer',
        model: 'reference',
        type: 'object_selector',
        config: {
          isPolymorphic: true,
          endpoint: '/organizations',
          queryKey: 'organizations',
          getIsApplicable: ({ rowData }: any) =>
            rowData?.reference_category === 'organization' && rowData?.payed_with && rowData?.payed_with?.model !== 'online',
        },
      },
      {
        title: 'Payment Method',
        model: 'payment_method',
        type: 'object_selector',
        config: {
          endpoint: ({ rowData }: any) => rowData?.client?.id && `/residents/${rowData.client.id}/payment_methods`,
          queryKey: ({ rowData }: any) => rowData?.client?.id && ['client', rowData?.client?.id, 'payment-methods'],
          selectDescription: (data: any) => data?.reference?.name,
          getIsApplicable: ({ rowData }: any) => rowData?.payed_with && rowData?.payed_with?.model === 'online',
        },
      },
      {
        title: 'Payment Reference',
        model: 'payment_reference',
      },
      {
        title: 'Send Notifications',
        id: 'send_notifications',
        type: 'multi_select',
        config: {
          options: [
            { label: 'SMS', model: 'should_sms' },
            { label: 'Email', model: 'should_email' },
          ],
        },
      },
      {
        title: 'Notes',
        model: 'notes',
        type: 'textarea',
      },
      // {
      //   title: '',
      //   model: '',
      //   config: {
      //     getIsApplicable: ({ rowData }: any) => false,
      //   },
      // },
      // {
      //   title: '',
      //   model: '',
      //   config: {
      //     getIsApplicable: ({ rowData }: any) => false,
      //   },
      // },
    ] as const
  }, [])

  return (
    <Overlay showBackdrop onClose={onClose} position="center" maxWidth={140}>
      <Overlay.Header title="Create Payments" icon="financials" />

      <Overlay.Content className="!p-4">
        <Grid gap="1rem" className="!pb-3">
          <Worksheet
            asCard
            getForm={form}
            title="Payments"
            allow="create-update-delete"
            columns={columns}
            onValidationUpdate={setIsValid}
            onRowsCountUpdate={setRowsCount}
            onDataUpdate={setData}
          />
        </Grid>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label={`Save ${countWord('Payments', rowsCount)}`}
          glyph="check"
          type="primary"
          color="green"
          onClick={handleSave}
          isLoading={isSaving}
          isDisabled={!isValid}
        />
      </Overlay.Footer>
    </Overlay>
  )
}
