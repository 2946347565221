import React from 'react'
import { useHistory } from 'react-router-dom'
import size from 'lodash/size'

import { countWord } from '../../../utils/functions'
import { DataTable } from '../../DataTable/DataTable'
import { MainCell } from '../../DataTable/cells/MainCell'
import { useCreate } from '../../../hooks/useNewAPI'
import { useDataTable } from '../../DataTable/useDataTable'

import Button from '../../Button'
import Overlay from '../../Overlay'
import Section from '../../Section'
import TemplateLegalTooltip from '../TemplateLegalTooltip'

const CODE_TYPES: any = {
  cpt: 'CPT',
  hcpc_mod: 'HCPC/MOD',
  hcpcs: 'HCPCS',
}

const InsuranceCodeTemplatesImportOverlay = () => {
  const history = useHistory()

  const [selectedRows, setSelectedRows]: any = React.useState([])

  const tableProps = useDataTable({
    name: ['insurance_codes_templates'],
    endpoint: '/internal_templates',
    params: {
      category: 'insurance',
      subcategory: 'insurance_code',
    },
    localStorageKey: 'insurance_codes_templates_v1',
  })

  const createMutation = useCreate({
    name: ['import-insurance'],
    url: '/internal_templates/import',
    invalidate: 'insurance_codes',
  })

  const selectedRowsCount = size(selectedRows)
  const hasSelectedRows = selectedRowsCount >= 1

  const onImport = async () => {
    try {
      const ids = selectedRows.map((o: any) => o.id)

      await createMutation.mutateAsync({
        ids: ids,
        category: 'insurance',
        subcategory: 'insurance_code',
      })

      history.goBack()
    } catch (errors) {
      console.debug(errors)
    }
  }

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          return <MainCell id={data.id} value={value} />
        },
      },
      {
        title: 'Procedure Code',
        model: 'data.code',
      },
      {
        title: 'Code Type',
        model: 'data.code_type',
        formatValue: ({ value }: any) => {
          if (!value) return null

          return CODE_TYPES[value] || null
        },
      },
      {
        title: 'Revenue Code',
        model: 'data.revenue_code',
      },
      {
        title: 'Suggested Price',
        model: 'data.price',
        type: 'amount',
      },
      {
        title: 'Unit',
        model: 'data.unit',
        type: 'title',
      },
      {
        title: 'Description',
        model: 'data.description',
        type: 'title',
      },
      {
        title: 'Notes',
        model: 'data.notes',
        type: 'title',
      },
    ],
    [],
  )

  return (
    <Overlay showBackdrop position="center" maxWidth={97}>
      <Overlay.Header title="Import Insurance Codes" icon="insurance" titleAside={<TemplateLegalTooltip />} />

      <Overlay.Content>
        <Section>
          <DataTable
            {...tableProps}
            asCard
            canBatchSelect
            title="Insurance Codes"
            icon="insurance"
            className="!mt-4"
            columns={columns}
            onRowSelectionUpdate={setSelectedRows}
          />
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label={hasSelectedRows ? `Import ${countWord('Codes', selectedRowsCount)}` : 'Select Codes to Import'}
          isDisabled={!hasSelectedRows}
          type="primary"
          color="green"
          isLoading={createMutation.isLoading}
          onClick={onImport}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export default InsuranceCodeTemplatesImportOverlay
