import React from 'react'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { apiGet, apiUpdate } from '@behavehealth/modules/api'
import { withPageError } from '@behavehealth/hocs/withPageError'
import { useSettings } from '@behavehealth/hooks/useSettings'

import { Employee } from '@behavehealth/declarations'

import { Accordion, Form, HelpTagIframe, PageHeader, Button, SummonOverlay } from '@behavehealth/components'

import StaffPermissionsList from '@behavehealth/components/Permissions/StaffPermissionsList'
import ApplyPermissionTemplateOverlay from '@behavehealth/components/Permissions/ApplyPermissionTemplateOverlay'

const Permissions: React.FC = () => {
  const match = useRouteMatch()

  const record: Employee = useSelector((state) => state.data?.employees?.data?.[match?.params?.resource_id])
  const isLoading = useSelector((state) => state.data?.employees?.loading)

  const { isBehave } = useSettings()

  // if (!record) return null

  return (
    <div className="grid grid-cols-[100%]">
      <PageHeader
        isLoading={isLoading}
        title="Staff Permissions"
        icon="permissions"
        titleAside={<HelpTagIframe id="settings_permissions" />}
        aside={
          <SummonOverlay overlay={<ApplyPermissionTemplateOverlay record={record} />}>
            <Button label="Apply Staff Permissions…" permission="employees.permissions.edit" type="primary" />
          </SummonOverlay>
        }
      />

      <div className="p-4 grid grid-cols-[100%] overflow-hidden mq660:overflow-visible">
        <Accordion
          isOpen
          minimal
          activateEditMode
          glyph="lock"
          title="Permissions"
          initialModel={record?.permissions}
          permission="employees.permissions.view"
          editPermission="employees.permissions.edit"
          onSubmit={async (data: any) => {
            await apiUpdate({
              name: 'employees',
              url: `/employees/${record.id}`,
              params: {
                permissions: data,
              },
            })

            await apiGet({ url: '/me', reducer: 'me' })
          }}
        >
          <Form className="!overflow-auto">
            <StaffPermissionsList />
          </Form>
        </Accordion>
      </div>
    </div>
  )
}

export default withPageError(Permissions)
