import React from 'react'
import { useParams } from 'react-router-dom-v5-compat'
import size from 'lodash/size'
import snakeCase from 'lodash/snakeCase'
import camelCase from 'lodash/camelCase'

import { useOverlay } from '../../hooks/useOverlay'
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard'
import { useSettings } from '../../hooks/useSettings'

import Accordions from '../../components/Accordions'
import Card from '../../components/Card'
import Button from '../../components/Button'
import Divider from '../../components/Divider'
import Form from '../../components/Forms/Form'
import Textarea from '../../components/Forms/Textarea'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'
import Json from '../../components/Json'
import Label from '../../components/Label'
import Status from '../../components/Status'
import Flex from '../../components/Flex'

import { AUTHORITY_FORMS, CATEGORIES, SUBCATEGORIES } from './forms'
import { QUESTIONS } from './questions'
import { getFormSchema } from './schemas'

import { QuestionRenderer } from './components/QuestionRenderer'
import FormSection from '../../components/Forms/FormSection'

import { AuthorityFormLevelStatus } from './AuthorityFormLevelStatus'
import { titleCase } from '../../utils/functions'
import { INPUT_STYLES } from '../../theme'

const DELIMITER = '~#\n'

const TSV_HEADERS = {
  identifier: ({ identifier }) => identifier,
  label: ({ labelText, label, evaluatorCriteria }) => labelText || label || evaluatorCriteria || '[🚨 Missing Label]',
  inputType: ({ inputType, render }) => (render ? 'custom' : inputType),
  options: ({ inputType, options }) => {
    if (size(options) > 0) {
      return Object.values(options).join(' / ')
    }

    if (inputType === 'boolean') {
      return 'Yes / No'
    }

    return ''
  },
  tooltip: ({ tooltip }) => tooltip,
  formId: ({ formId }) => formId,
  level: ({ level }) => level,
  categoryId: ({ categoryId }) => categoryId,
  subcategoryId: ({ subcategoryId }) => subcategoryId,
  evaluatorCriteria: ({ evaluatorCriteria }) => evaluatorCriteria,
}

const BASE_HEADERS = [
  'LABEL',
  'IDENTIFIER',
  'INPUT TYPE',
  'OPTIONS',
  'TOOLTIP',
  'FORM ID',
  'LEVEL',
  'CATEGORY ID',
  'SUBCATEGORY ID',
  'EVALUATOR CRITERIA',
]

const HEADERS = [
  'LABEL',
  'IDENTIFIER',
  'INPUT TYPE',
  'OPTIONS',
  'TOOLTIP',
  'FORM ID',
  'LEVEL',
  'CATEGORY ID',
  'SUBCATEGORY ID',
  'EVALUATOR CRITERIA',
  'Virginia',
  'West Virginia',
  'Michigan',
  'Maine',
  'Texas',
  'Rhode Island',
  'Washington',
  'Massachusetts',
  'Delaware',
  'New Hampshire',
  'Florida',
  'Pennsylvania',
  'Montana',
  'Tennessee',
  'Missouri',
  'Colorado',
  'California',
  'Connecticut',
  'Ohio',
  'North Carolina',
  'Minnesota',
  'Georgia',
  'Arizona',
  'Vermont',
  'Wisconsin',
  'Illinois',
  '~#',
]

export const AuthorityFormQuestionsDebug = (props: any) => {
  const { close, form, isEditable } = useOverlay({
    name: 'TODO',
    endpoint: '/TODO',
    invalidate: 'TODO',
    options: props,
  })

  const jsonForm = React.useRef()
  const previewForm = React.useRef()

  const { tenant, isBehave } = useSettings()
  const authorityName = props.authorityName || tenant?.name

  const currentTSV = React.useMemo(() => {
    const headerIds: any = BASE_HEADERS.map((header) => header)
    const rows: any = []

    // headers.push(`${DELIMITER}`)

    for (const question of QUESTIONS) {
      const cells = []

      for (const header of BASE_HEADERS) {
        const headerId = camelCase(header)
        const headerRender = TSV_HEADERS[headerId]
        const value = headerRender?.(question) || question[headerId] || ''

        cells.push(value)
      }

      rows.push(cells.join('\t'))
      rows.push('\n')
    }

    return `${headerIds.join('\t')}\n${rows.join('')}`
  }, [TSV_HEADERS])

  const [tsvText, setTsvText] = React.useState('')
  const [parsedJSON, setParsedJSON] = React.useState<any>(null)
  const [didParse, setDidParse] = React.useState(false)
  const [didCopyTSV, setDidCopyTSV] = React.useState(false)

  const { copy: copyJson, didCopy: didCopyJson } = useCopyToClipboard({
    text: JSON.stringify(parsedJSON, null, 2),
    notification: 'TSV copied!',
    dismissAfter: 2000,
  })

  const { copy: copyTSV } = useCopyToClipboard({
    text: tsvText,
    notification: 'TSV copied!',
    dismissAfter: 2000,
  })

  const { copy: copyCurrentTSV, didCopy: didCopyCurrentTSV } = useCopyToClipboard({
    text: currentTSV,
    notification: 'Current TSV copied!',
    dismissAfter: 2000,
  })

  return (
    <Overlay onClose={close} showBackdrop={isEditable} position="right" maxWidth={140}>
      <Overlay.Header icon={'authority_forms'} title={'Authority Form Questions Debug'} />

      <Overlay.Content>
        <Section
          title="Convert TSV to JSON"
          titleAside={
            <Button
              label={didParse ? 'Parsing...' : 'TSV → JSON'}
              color="text"
              glyph="reset"
              className="!ml-5"
              type="primary"
              size={200}
              onClick={() => {
                setDidParse(true)

                try {
                  const res = tsvToJson(tsvText)
                  setParsedJSON(res)
                } catch (error) {
                  console.error('Error parsing TSV to JSON', error)
                  setParsedJSON({ error: error.message })
                } finally {
                  setTimeout(() => setDidParse(false), 2000)
                }
              }}
            />
          }
        >
          <div className="grid grid-cols-1 mq900:grid-cols-3 gap-5">
            <div className="grid grid-cols-1 grid-rows-1 content-start">
              <Label
                label="TSV"
                className=""
                after={
                  <Button
                    label={didCopyTSV ? 'TSV Copied' : 'Copy TSV'}
                    glyph={didCopyTSV ? 'check' : 'copy'}
                    color={didCopyTSV ? 'green' : 'blue'}
                    size={200}
                    onClick={copyTSV}
                  />
                }
              />

              <textarea
                labelAfter={
                  <Button
                    label={didCopyTSV ? 'TSV Copied' : 'Copy TSV'}
                    glyph={didCopyTSV ? 'check' : 'copy'}
                    color={didCopyTSV ? 'green' : 'blue'}
                    size={200}
                    onClick={copyTSV}
                  />
                }
                value={tsvText}
                onChange={({ target }) => setTsvText(target.value)}
                minRows={18}
                maxRows={18}
                className="!h-[900px]"
                css={INPUT_STYLES}
              />
            </div>

            <div className="grid grid-cols-1">
              <Label label="Preview" />

              <Card className="!overflow-auto w-full h-[900px] text-[0.8rem] px-4 pb-4 whitespace-pre-wrap">
                <Form getForm={jsonForm} className="w-full grid grid-cols-[100%]">
                  {parsedJSON?.map?.((question: any, index) => {
                    const formConfig = AUTHORITY_FORMS[question.formId]

                    return (
                      <React.Fragment key={question.identifier}>
                        {index > 0 && <Divider />}
                        <QuestionRenderer
                          isEditable
                          isInfoVisible
                          authorityName={authorityName}
                          isEvaluatorOnly={formConfig?.isEvaluatorOnly}
                          question={question}
                          statusesClassName="mb-1 mt-2"
                        />
                      </React.Fragment>
                    )
                  })}
                </Form>
              </Card>
            </div>

            <div className="grid grid-cols-1 grid-rows-1">
              <Label
                label="JSON"
                className=""
                after={
                  <Button
                    label={didCopyJson ? 'JSON Copied' : 'Copy JSON'}
                    glyph={didCopyJson ? 'check' : 'copy'}
                    color={didCopyJson ? 'green' : 'blue'}
                    size={200}
                    onClick={copyJson}
                  />
                }
              />
              <Card className="w-full h-[900px] text-[0.8rem] px-4 !overflow-auto">
                {parsedJSON && <pre>{JSON.stringify(parsedJSON, null, 2)}</pre>}
              </Card>
            </div>
          </div>
        </Section>

        <Divider />

        <Section
          title="Current Schema Preview"
          titleAside={
            <Button
              label={didCopyCurrentTSV ? 'Current TSV Copied' : 'Copy Current TSV'}
              glyph={didCopyCurrentTSV ? 'check' : 'copy'}
              color={didCopyCurrentTSV ? 'green' : 'blue'}
              onClick={copyCurrentTSV}
              size={200}
              className="!ml-5"
            />
          }
        >
          <Card className="overflow-visible w-full px-4 pb-4 whitespace-pre-wrap">
            <Form getForm={previewForm} className="w-full grid grid-cols-[100%]">
              {QUESTIONS.map((question: any, index) => {
                const formConfig = AUTHORITY_FORMS[question.formId]

                return (
                  <React.Fragment key={question.identifier}>
                    {index > 0 && <Divider />}
                    <QuestionRenderer
                      isEditable
                      isInfoVisible
                      authorityName={authorityName}
                      isEvaluatorOnly={formConfig?.isEvaluatorOnly}
                      question={question}
                      statusesClassName="mb-1 mt-2"
                    />
                  </React.Fragment>
                )
              })}
            </Form>
          </Card>
        </Section>
      </Overlay.Content>
    </Overlay>
  )
}

const tsvToJson = (tsv: string) => {
  const rows = tsv.split(DELIMITER)
  const headers = rows[0].split('\t')

  const evaluatorCriterias = {}

  for (const q of QUESTIONS) {
    if (q.evaluatorCriteria) {
      evaluatorCriterias[q.identifier] = q.evaluatorCriteria
    }
  }

  const data = rows.slice(1).map((row) => {
    const values = row.split('\t')
    const obj: any = {}
    const states: string[] = []

    for (let i = 0; i < headers.length; i++) {
      const header = headers[i]
      const headerId = camelCase(header)

      const identifierIndex = headers.indexOf('IDENTIFIER')
      const identifier = values[identifierIndex]

      const inputTypeIndex = headers.indexOf('INPUT TYPE')
      const inputType = values[inputTypeIndex]

      if (!headerId) continue

      if (headerId in STATE_COLUMNS) {
        if (values[i] === 'TRUE') states.push(STATE_COLUMNS[headerId])
      } else if (headerId === 'evaluatorCriteria') {
        obj['evaluatorCriteria'] = evaluatorCriterias[identifier]
      } else if (headerId === 'options' && values[i]) {
        if (inputType !== 'boolean') {
          obj['options'] = {}

          const options = values[i].split(' / ')

          for (const option of options) {
            obj['options'][snakeCase(option)] = option
          }
        }
      } else {
        obj[headerId] = values[i]
      }
    }

    return {
      ...obj,
      states,
    }
  })

  console.log({
    rows,
    headers,
    data,
  })

  return data
}

const STATE_COLUMNS = {
  virginia: 'VA',
  westVirginia: 'WV',
  michigan: 'MI',
  maine: 'ME',
  texas: 'TX',
  rhodeIsland: 'RI',
  washington: 'WA',
  massachusetts: 'MA',
  delaware: 'DE',
  newHampshire: 'NH',
  florida: 'FL',
  pennsylvania: 'PA',
  montana: 'MT',
  tennessee: 'TN',
  missouri: 'MO',
  colorado: 'CO',
  california: 'CA',
  connecticut: 'CT',
  ohio: 'OH',
  northCarolina: 'NC',
  minnesota: 'MN',
  georgia: 'GA',
  arizona: 'AZ',
  vermont: 'VT',
  wisconsin: 'WI',
  illinois: 'IL',
}
