import React from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import { countWord } from '../../utils/functions'
import { useCreate } from '../../hooks/useNewAPI'

import Button from '../../components/Button'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'

import { useDataTable } from '../../components/DataTable/useDataTable'
import { AdminDiscountTemplatesDataTable } from './AdminDiscountTemplatesDataTable'

export const DiscountsImportOverlay = () => {
  const navigate = useNavigate()

  const [selectedRows, setSelectedRows]: any = React.useState([])

  const tableProps = useDataTable({
    name: ['internal_discounts_templates'],
    endpoint: `/internal_templates`,
    params: { category: 'discount', subcategory: 'discount', level: 'behave_template' },
    localStorageKey: 'internal_discounts_templates_v1',
  })

  const createMutation = useCreate({
    name: ['import-quote'],
    url: '/internal_templates/import',
    invalidate: 'discounts',
  })

  const selectedRowsCount = size(selectedRows)
  const hasSelectedRows = selectedRowsCount >= 1

  const onImport = async () => {
    try {
      const ids = selectedRows.map((o: any) => o.id)

      await createMutation.mutateAsync({
        ids: ids,
        category: 'discount',
        subcategory: 'discount',
      })

      navigate(-1)
    } catch (errors) {
      console.debug(errors)
    }
  }

  return (
    <Overlay showBackdrop position="center" maxWidth={64}>
      <Overlay.Header title="Import Discounts from Catalog" icon="certificate" />

      <Overlay.Content>
        <Section>
          <AdminDiscountTemplatesDataTable {...tableProps} canBatchSelect onRowSelectionUpdate={setSelectedRows} />
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label={hasSelectedRows ? `Import ${countWord('Discounts', selectedRowsCount)}` : 'Select Discounts to Import'}
          isDisabled={!hasSelectedRows}
          type="primary"
          color="green"
          isLoading={createMutation.isLoading}
          onClick={onImport}
        />
      </Overlay.Footer>
    </Overlay>
  )
}
