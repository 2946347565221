import React from 'react'
import { useMedia } from 'use-media'
import { useParams } from 'react-router-dom-v5-compat'
import clsx from 'clsx'
import debounce from 'lodash/debounce'
import snakeCase from 'lodash/snakeCase'

import { PlainSwitch } from '../../components/Forms/Switch'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'

import Button from '../../components/Button'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Form from '../../components/Forms/Form'
import Input from '../../components/Forms/Input'
import Label from '../../components/Label'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'

import { AUTHORITY_FORMS, CATEGORIES, SUBCATEGORIES } from './forms'
import { getFormSchema } from './schemas'
import { DataFormSection } from './components/DataFormSection'
import { DataFormPreview } from './components/DataFormPreview'

const ENDPOINTS = {
  certification: 'authority_forms',
  inspection: 'authority_forms',
  complaint: 'authority_complaints',
  issue: 'authority_complaints',
  study: 'authority_studies',
  survey: 'authority_studies',
}

export const AuthorityFormBuilder = (props: any) => {
  const params = useParams()
  const endpoint = ENDPOINTS?.[snakeCase(params?.category)]

  const {
    cancel,
    close,
    edit,
    form,
    id,
    initialModel,
    isEditable,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
    data,
    isInvalid,
    isDeleting,
    deleteRecord,
    saveWithData,
  } = useOverlay({
    name: endpoint,
    endpoint: `/${endpoint}`,
    invalidate: endpoint,
    options: props,
  })

  const isDesktop = useMedia({ minWidth: 1024 })
  const { isBehave, tenant } = useSettings()
  const authorityName = props.authorityName || tenant?.name

  const subcategory = data?.subcategory || snakeCase(params?.category)

  const [formData, setFormData] = React.useState({})
  const [isPreviewVisible, setIsPreviewVisible] = React.useState(false)

  const [isInfoVisible, setIsInfoVisible] = React.useState(false)

  const handleInfoChange = () => setIsInfoVisible((prev) => !prev)

  const handlePreviewChange = () => {
    setIsPreviewVisible((c) => !c)
  }

  const debouncedSetFormData = React.useMemo(() => {
    return debounce((value) => {
      setFormData?.(value)
    }, 300)
  }, [])

  const CONFIG = AUTHORITY_FORMS?.[subcategory]

  const schema = React.useMemo(() => {
    const res = getFormSchema(subcategory)
    return res
  }, [subcategory])

  if (isOverlayLoading) return <OverlayLoader position="right" maxWidth={isDesktop && isPreviewVisible ? 140 : 70} />

  if (!CONFIG) {
    console.error(`Form config not found for ${subcategory}`)
    return null
  }

  if (!schema) {
    console.error(`Form schema not found for ${subcategory}`)
    return null
  }

  const { name, icon, isEvaluatorOnly } = CONFIG

  return (
    <Overlay onClose={close} showBackdrop={isEditable} position="right" maxWidth={isDesktop && isPreviewVisible ? 140 : 70}>
      <Overlay.Header icon={icon} title={name} />

      {isDesktop && (
        <Overlay.SubHeader className="flex items-center justify-end flex-nowrap px-2 !py-1.5">
          {isBehave && (
            <div className="flex flex-nowrap items-center ml-auto mr-5">
              <Label isCompact label="Show Info (BH)" onClick={handleInfoChange} className="!flex-[0_0_auto] cursor-pointer" />
              <PlainSwitch size={200} isChecked={isInfoVisible} onCheckedChange={handleInfoChange} />
            </div>
          )}

          <div className="flex flex-nowrap items-center">
            <Label isCompact label="Show Preview" onClick={handlePreviewChange} className="!flex-[0_0_auto] cursor-pointer" />
            <PlainSwitch size={200} isChecked={isDesktop && isPreviewVisible} onCheckedChange={handlePreviewChange} />
          </div>
        </Overlay.SubHeader>
      )}

      <div
        className={clsx(
          'grid grid-rows-[100%] overflow-hidden flex-[1_1_auto]',
          isDesktop && isPreviewVisible ? 'grid-cols-[50rem_1fr]' : 'grid-cols-1',
        )}
      >
        <Overlay.Content className={isDesktop && isPreviewVisible ? 'border-r border-0 border-solid border-divider' : ''}>
          <Form
            isCompact
            getForm={form}
            initialModel={initialModel}
            onValidationUpdate={onValidationUpdate}
            isEditable={isEditable}
            onUpdate={debouncedSetFormData}
            linked={{
              // category: AUTHORITY_FORMS?.[subcategory]?.category,
              category: params?.category,
              subcategory,
            }}
          >
            <div className="p-5 pt-3 grid gap-4 [--field-max-width:100%]">
              <Input label="Form Name" defaultValue={name} model="name" />

              {Object.keys(schema).map((categoryId: any, index) => {
                const section = schema[categoryId]
                const category = CATEGORIES[categoryId]

                return (
                  <React.Fragment key={categoryId}>
                    {index > 0 && <Divider />}

                    <Section title={category?.name} headingType="h2" className="!m-0 !p-0">
                      <div className="grid gap-5 grid-cols-1">
                        {Object.keys(section).map((subcategoryId: any) => {
                          const questions = section[subcategoryId]
                          const model = `${categoryId}.${subcategoryId}`
                          const subcategory = SUBCATEGORIES[subcategoryId]

                          return (
                            <DataFormSection
                              isOpen
                              key={model}
                              authorityName={authorityName}
                              title={subcategory?.name || 'No Subcategory'}
                              isEditable={isEditable}
                              isPreviewVisible={isDesktop && isPreviewVisible}
                              questions={questions}
                              model={`${categoryId}.${subcategoryId}`}
                              isEvaluatorOnly={isEvaluatorOnly}
                              sectionId={subcategoryId}
                              isInfoVisible={isInfoVisible}
                            />
                          )
                        })}
                      </div>
                    </Section>
                  </React.Fragment>
                )
              })}
            </div>
          </Form>
        </Overlay.Content>

        {isDesktop && isPreviewVisible && (
          <Overlay.Content>
            <DataFormPreview
              schema={schema}
              settings={formData?.schema}
              authorityName={authorityName}
              isEvaluatorOnly={isEvaluatorOnly}
              isInfoVisible={isInfoVisible}
            />
          </Overlay.Content>
        )}
      </div>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              glyph="edit"
              label="Edit Authority Form"
              type="default"
              isDisabled={isLoading}
              onClick={edit}
              flex="100 1 auto"
              // permission="authority_forms.edit"
            />

            <DeleteDialog
              title="Delete Authority Form?"
              message="Are you sure you want to delete this Authority Form? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={isDeleting}
                fullWidth
                // permission="authority_forms.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}
