import React from 'react'

import SmartStatus from '../../components/SmartStatus'

export const STATUSES = {
  base: {
    label: 'Base',
    color: 'blue',
  },
  supplemental: {
    label: 'Supplemental',
    color: 'violet',
  },
  state: {
    label: 'State',
    color: 'orange',
  },
}

export const AuthorityFormLevelStatus = ({ ...rest }) => {
  return <SmartStatus statuses={STATUSES} {...rest} />
}
