import React from 'react'

import { FEATURES } from '../../theme'
import { RECURRING_DAYS } from '../../utils/recurrence'
import { titleCase } from '../../utils/functions'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import ContextShow from '../../components/Forms/ContextShow'
import Card from '../../components/Card'
import DateInput from '../../components/Forms/DateInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import MultiObjectSelector from '../../components/Forms/Selectors/MultiObject/MultiObjectSelector'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import QuickView from '../../components/QuickView'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import Timeline from '../../components/Timeline/Timeline'

import {
  CLIENT_COLUMNS,
  ALUMNI_COLUMNS,
  CURRENT_CLIENT_COLUMNS,
  ANONYMIZED_OUTCOMES,
  EXTENDED_ANONYMIZED_OUTCOMES,
  BREATHALYZER_TESTS,
  UA_TESTS,
  VITAL_SIGNS,
  COMMUNICABLE_DISEASE_TESTS,
  CLIENT_FINANCIAL_SUMMARY,
  CLIENT_FINANCIAL_TRANSACTIONS,
  CLIENT_FINANCIALS_HEALTH,
  ORGANIZATION_FINANCIAL_SUMMARY,
  ORGANIZATION_FINANCIAL_TRANSACTIONS,
  ORGANIZATION_FINANCIALS_HEALTH,
  ADMIT_SURVEY,
  DEMOGRAPHIC_INFORMATION_FORM,
  PROGRESS_MONITORING_SURVEY,
  OUTCOMES_SURVEY,
  RECOVERY_CAPITAL,
  MIRC,
  DISCHARGE_SURVEY,
  INSURANCE_POLICIES,
  INSURANCE_BILLING,
  INSURANCE_PAYMENTS_BY_PAYMENT_DATE,
  INSURANCE_PAYMENTS_BY_POSTED_DATE,
  INSURANCE_AGING,
  NEW_INSURANCE_PAYMENTS_BY_PAYMENT_DATE,
  NEW_INSURANCE_PAYMENTS_BY_POSTED_DATE,
  NEW_INSURANCE_AGING,
  NEW_INSURANCE_BILLING_BY_SERVICE_DATE,
  NEW_INSURANCE_BILLING_BY_BILL_DATE,
  DOCUMENTATION_STATUS,
  DOCUMENTATION_TIME,
  ALL_REFERRALS,
  ALL_CLIENT_CONTACTS,
  BED_ASSIGNMENTS,
  PROGRAM_ASSIGNMENTS,
  DISCHARGES,
  TREATMENT_EPISODES,
  CLINICAL_NOTES,
  SHIFT_NOTES,
  SAFETY_CHECKS,
  SERVICE_EPISODES,
  PEER_NOTES,
  EVENTS,
  FORM_SUBMISSIONS,
  COMMUNICATIONS,
  ORGANIZATIONS,
  ORGANIZATION_CONTACTS,
  EMPLOYEES,
  STAFF_PRODUCTIVITY,
  NOTIFICATIONS_BLACKLIST,
  ROSTER,
  LOCATION_CHECKS,
} from './constants'

const CATEGORY_COLUMNS = {
  clients: CLIENT_COLUMNS,
  current_clients: CURRENT_CLIENT_COLUMNS,
  alumni: ALUMNI_COLUMNS,
  anonymized_outcomes: ANONYMIZED_OUTCOMES,
  extended_anonymized_outcomes: EXTENDED_ANONYMIZED_OUTCOMES,
  breathalyzer_tests: BREATHALYZER_TESTS,
  ua_tests: UA_TESTS,
  vital_signs: VITAL_SIGNS,
  communicable_disease_tests: COMMUNICABLE_DISEASE_TESTS,
  client_financial_summary: CLIENT_FINANCIAL_SUMMARY,
  current_client_financial_summary: CLIENT_FINANCIAL_SUMMARY,
  client_financial_transactions: CLIENT_FINANCIAL_TRANSACTIONS,
  client_financials_health: CLIENT_FINANCIALS_HEALTH,
  organization_financial_summary: ORGANIZATION_FINANCIAL_SUMMARY,
  organization_financial_transactions: ORGANIZATION_FINANCIAL_TRANSACTIONS,
  organization_financials_health: ORGANIZATION_FINANCIALS_HEALTH,
  admit_survey: ADMIT_SURVEY,
  demographic_information_form: DEMOGRAPHIC_INFORMATION_FORM,
  progress_monitoring_survey: PROGRESS_MONITORING_SURVEY,
  outcomes_survey: OUTCOMES_SURVEY,
  recovery_capital: RECOVERY_CAPITAL,
  mirc: MIRC,
  discharge_survey: DISCHARGE_SURVEY,
  insurance_policies: INSURANCE_POLICIES,
  insurance_billing: INSURANCE_BILLING,
  insurance_payments_by_payment_date: INSURANCE_PAYMENTS_BY_PAYMENT_DATE,
  insurance_payments_by_posted_date: INSURANCE_PAYMENTS_BY_POSTED_DATE,
  insurance_aging: INSURANCE_AGING,
  new_insurance_payments_by_payment_date: NEW_INSURANCE_PAYMENTS_BY_PAYMENT_DATE,
  new_insurance_payments_by_posted_date: NEW_INSURANCE_PAYMENTS_BY_POSTED_DATE,
  new_insurance_aging: NEW_INSURANCE_AGING,
  new_insurance_billing_by_service_date: NEW_INSURANCE_BILLING_BY_SERVICE_DATE,
  new_insurance_billing_by_bill_date: NEW_INSURANCE_BILLING_BY_BILL_DATE,
  documentation_status: DOCUMENTATION_STATUS,
  documentation_time: DOCUMENTATION_TIME,
  all_referrals: ALL_REFERRALS,
  all_client_contacts: ALL_CLIENT_CONTACTS,
  bed_assignments: BED_ASSIGNMENTS,
  program_assignments: PROGRAM_ASSIGNMENTS,
  discharges: DISCHARGES,
  treatment_episodes: TREATMENT_EPISODES,
  clinical_notes: CLINICAL_NOTES,
  shift_notes: SHIFT_NOTES,
  safety_checks: SAFETY_CHECKS,
  service_episodes: SERVICE_EPISODES,
  peer_notes: PEER_NOTES,
  events: EVENTS,
  form_submissions: FORM_SUBMISSIONS,
  communications: COMMUNICATIONS,
  organizations: ORGANIZATIONS,
  organization_contacts: ORGANIZATION_CONTACTS,
  employees: EMPLOYEES,
  staff_productivity: STAFF_PRODUCTIVITY,
  notifications_blacklist: NOTIFICATIONS_BLACKLIST,
  roster: ROSTER,
  location_checks: LOCATION_CHECKS,
}

const categoriesWithNoAssignmentInfo = [
  'average_daily_census',
  'clients',
  'current_clients',
  'client_financials_health',
  'documentation_status',
  'documentation_time',
  'employees',
  'event_attendance_report',
  'events',
  'form_submissions',
  'organization_financial_summary',
  'organization_financial_transactions',
  'organization_financials_health',
  'roster',
  'rounds',
  'bed_occupancy_report',
  'seat_occupancy_report',
  'organizations',
  'organization_contacts',
  'employees',
  'staff_productivity',
  'notifications_blacklist',
]

export const RootScheduledExcelReportOverlay = (props: any) => {
  const { user, timezone, isBehave, isSelfServiceBilling } = useSettings()

  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'scheduled-report',
    endpoint: '/scheduled_reports',
    invalidate: 'scheduled-reports',
    options: props,
    onDeleteSuccessful: props.onDeleteSuccessful,
    onSaveSuccessful: props.onSaveSuccessful,
  })

  const [category, setCategory] = React.useState<any>(null)

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  const categoryColumns = CATEGORY_COLUMNS?.[category]

  return (
    <Overlay position="right" onClose={close} isDirty={isEditable} showBackdrop={isNew || isEditable || props.showBackdrop}>
      <Overlay.Header icon={FEATURES.live_reports.icon} title={isNew ? 'Schedule Report Generation' : 'Scheduled Report Generation'} />

      <Overlay.Content>
        <Form
          getForm={form}
          timezone={timezone}
          initialModel={initialModel}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
        >
          {(data) => (
            <>
              <Section>
                <FormSection layout="vertical">
                  {!isNew && (
                    <Input
                      label="Name"
                      model="name"
                      isEditable={isNew}
                      validations={{
                        presence: {
                          message: 'Please enter a name for this report',
                        },
                      }}
                    />
                  )}

                  <Select
                    label="Report Type"
                    model="category"
                    isEditable={isNew}
                    validations={{
                      presence: {
                        message: 'Please select a report type',
                      },
                    }}
                    onUpdate={({ value }: any) => {
                      setCategory(value)
                    }}
                  >
                    <option />

                    <optgroup label="Clients">
                      <Option label="All Clients " value="clients" />
                      <Option label="Current" value="current_clients" />
                      <Option label="Alumni" value="alumni" />
                    </optgroup>

                    <option disabled />

                    <optgroup label="Outcomes">
                      <Option label="Anonymized Outcomes" value="anonymized_outcomes" />
                      <Option label="Extended Anonymized Outcomes" value="extended_anonymized_outcomes" />
                    </optgroup>

                    <option disabled />

                    <optgroup label="Tests">
                      <Option label="Breathalyzer Tests" value="breathalyzer_tests" />
                      <Option label="UA Tests" value="ua_tests" />
                      <Option label="Vital Signs" value="vital_signs" />
                      <Option label="Communicable Disease Tests" value="communicable_disease_tests" />
                    </optgroup>

                    <option disabled />

                    <optgroup label="Client Financials">
                      <Option label="Financial Summary - All Clients" value="client_financial_summary" />
                      <Option label="Financial Summary - Current Clients" value="current_client_financial_summary" />
                      <Option label="Ledger Transactions" value="client_financial_transactions" />
                      <Option label="Weekly Business Health" value="client_financials_health" />
                    </optgroup>

                    <option disabled />

                    <optgroup label="Organization Financials">
                      <Option label="Financial Summary" value="organization_financial_summary" />
                      <Option label="Ledger Transactions" value="organization_financial_transactions" />
                      <Option label="Weekly Business Health" value="organization_financials_health" />
                    </optgroup>

                    <option disabled />

                    <optgroup label="Outcome Measures">
                      <Option label="Admit Survey (FoRSE)" value="admit_survey" />
                      <Option label="Demographic Information Form (FoRSE)" value="demographic_information_form" />
                      <Option label="Progress Monitoring Survey (FoRSE)" value="progress_monitoring_survey" />
                      <Option label="Outcomes Survey (FoRSE)" value="outcomes_survey" />
                      <Option label="Discharge Survey (FoRSE)" value="discharge_survey" />
                      <Option label="Recovery Capital" value="recovery_capital" />
                      <Option label="Multidimensional Inventory of Recovery Capital" value="mirc" />
                    </optgroup>

                    {(isBehave || isSelfServiceBilling) && (
                      <>
                        <option disabled />

                        <optgroup label="Legacy Insurance Reports">
                          <Option label="Insurance Policies" value="insurance_policies" />
                          <Option label="Insurance Billing" value="insurance_billing" />
                          <Option label="Insurance Payments by Payment Date" value="insurance_payments_by_payment_date" />
                          <Option label="Insurance Payments by Posted Date" value="insurance_payments_by_posted_date" />
                          <Option label="Insurance Aging" value="insurance_aging" />
                        </optgroup>
                      </>
                    )}

                    {(isBehave || isSelfServiceBilling) && (
                      <>
                        <option disabled />

                        <optgroup label="New Insurance Reports">
                          <Option label="Insurance Payments by Payment Date" value="new_insurance_payments_by_payment_date" />
                          <Option label="Insurance Payments by Posted Date" value="new_insurance_payments_by_posted_date" />
                          <Option label="Insurance Aging" value="new_insurance_aging" />
                          <Option label="Insurance Billing By Service Date" value="new_insurance_billing_by_service_date" />
                          <Option label="Insurance Billing By Bill Date" value="new_insurance_billing_by_bill_date" />
                        </optgroup>
                      </>
                    )}

                    <option disabled />

                    <optgroup label="Documentation">
                      <Option label="Documentation Status" value="documentation_status" />
                      <Option label="Documentation Time" value="documentation_time" />
                    </optgroup>

                    <option disabled />

                    <optgroup label="Operations">
                      <Option label="All Referrals" value="all_referrals" />
                      <Option label="All Client Contacts" value="all_client_contacts" />
                      <Option label="Bed Assignments" value="bed_assignments" />
                      <Option label="Bed Occupancy Report" value="bed_occupancy_report" />
                      <Option label="Seat Occupancy Report" value="seat_occupancy_report" />
                      <Option label="Average Daily Census (ADC)" value="average_daily_census" />
                      <Option label="Program Assignments" value="program_assignments" />
                      <Option label="Discharges" value="discharges" />
                      <Option label="Treatment Episodes" value="treatment_episodes" />
                      <Option label="Clinical Notes" value="clinical_notes" />
                      <Option label="Shift Notes" value="shift_notes" />
                      <Option label="Safety Checks" value="safety_checks" />
                      <Option label="Service Episodes" value="service_episodes" />
                      <Option label="Peer Notes" value="peer_notes" />
                      <Option label="Events" value="events" />
                      <Option label="Form Submissions" value="form_submissions" />
                      <Option label="Communication Logs" value="communications" />
                      <Option label="Organizations" value="organizations" />
                      <Option label="Organization Contacts" value="organization_contacts" />
                      <Option label="Staff" value="employees" />
                      <Option label="Staff Productivity" value="staff_productivity" />
                      <Option label="Blocked Notifications" value="notifications_blacklist" />
                      <Option label="Roster" value="roster" />
                      <Option label="Location Check Ins" value="location_checks" />
                    </optgroup>
                  </Select>

                  <Flex gap="1rem">
                    <Select label="Generate Every" model="frequency" defaultValue="weekly" isEditable={isNew}>
                      {/* <Option label="Day" value="daily" /> */}
                      <Option label="Week" value="weekly" />
                      <Option label="Two Weeks" value="biweekly" />
                      <Option label="Month" value="monthly" />
                      <Option label="Quarter" value="quarterly" />
                      <Option label="Year" value="yearly" />
                    </Select>

                    <ContextShow orMode when="frequency" within={['quarterly', 'yearly']}>
                      <DateInput
                        defaultToNow
                        label="Start Date"
                        model="start_date"
                        isEditable={isNew}
                        validations={{
                          presence: {
                            message: 'Please select a start date',
                          },
                        }}
                      />
                    </ContextShow>

                    <ContextShow orMode when="frequency" within={['weekly', 'biweekly']}>
                      <Select label="Day to Run" model="day_of_week" defaultValue="monday" isEditable={isNew}>
                        {RECURRING_DAYS.weekly.map((day) => (
                          <Option key={day} label={day} value={day.toLowerCase()} />
                        ))}
                      </Select>
                    </ContextShow>

                    <ContextShow when="frequency" is="monthly">
                      <Select label="Day to Run" model="day_of_month" defaultValue="monday" isEditable={isNew}>
                        {RECURRING_DAYS.monthly.map((day) => (
                          <Option key={day} label={day} value={parseInt(day)} />
                        ))}
                      </Select>
                    </ContextShow>

                    <Select label="Time to Run" model="hour" defaultValue="7am" isEditable={isNew}>
                      <Option label="7am" value={7} />
                      <Option label="8am" value={8} />
                      <Option label="9am" value={9} />
                      <Option label="10am" value={10} />
                      <Option label="11am" value={11} />
                      <Option label="12pm" value={12} />
                      <Option label="1pm" value={13} />
                      <Option label="2pm" value={14} />
                      <Option label="3pm" value={15} />
                      <Option label="4pm" value={16} />
                      <Option label="5pm" value={17} />
                      <Option label="6pm" value={18} />
                      <Option label="7pm" value={19} />
                      <Option label="8pm" value={20} />
                      <Option label="9pm" value={21} />
                      <Option label="10pm" value={22} />
                      <Option label="11pm" value={23} />
                      <Option label="12am" value={0} />
                      <Option label="1am" value={1} />
                      <Option label="2am" value={2} />
                      <Option label="3am" value={3} />
                      <Option label="4am" value={4} />
                      <Option label="5am" value={5} />
                      <Option label="6am" value={6} />
                    </Select>
                  </Flex>

                  {!isEditable && (
                    <>
                      <DateInput label="Last Run Date" model="last_run_at" isEditable={false} />
                      <DateInput label="Next Run Date" model="next_run_at" isEditable={false} />
                      <DateInput label="Created At" model="created_at" isEditable={false} />
                      <ObjectSelector label="Created by" icon="employees" type="employees" model="author" isEditable={false} />
                    </>
                  )}

                  <Checkbox defaultChecked isEditable={isNew} label="Send email notification once generated" model="send_notification" />

                  {category && !categoriesWithNoAssignmentInfo.includes(category) && (
                    <>
                      <Checkbox label="Include Bed Assignments" model="include_bed_assignments" />
                      <Checkbox label="Include Program Assignments" model="include_program_assignments" />
                    </>
                  )}

                  <ContextShow when="send_notification" is={true}>
                    <MultiObjectSelector
                      label="Staff"
                      model="employees"
                      type="employees"
                      icon="employees"
                      selectTitle={(data: any) => data?.name}
                      selectDescription={(data: any) => titleCase(data?.position)}
                      validations={{
                        presence: {
                          message: 'Please select at least one staff member',
                        },
                      }}
                    />

                    {isNew && (
                      <Alert contrast glyph="info">
                        A link will be emailed to the selected staff every time the report is generated.
                      </Alert>
                    )}
                  </ContextShow>

                  <ContextShow when="category" is="clients">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Clients Creation Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="current_clients">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report will return <strong>All the Current Clients</strong>.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="alumni">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Treatment Episodes Discharge Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="anonymized_outcomes">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Treatment Episodes Discharge Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="extended_anonymized_outcomes">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Treatment Episodes Discharge Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="breathalyzer_tests">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Breathalyzer Tests Collection Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="ua_tests">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>UA Tests Collection Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="vital_signs">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Vital Signs Date & Time</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="communicable_disease_tests">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Communicable Disease Tests Date & Time</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="client_financial_summary">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report will return <strong>All Clients Financial Summary</strong>.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="current_client_financial_summary">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report will return <strong>All Current Clients Financial Summary</strong>.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="client_financial_transactions">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Transaction Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="client_financials_health">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Transaction Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="organization_financial_summary">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report will return the<strong>Organizations Financial Summary</strong>.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="organization_financial_transactions">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Transaction Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="organization_financials_health">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Transaction Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="admit_survey">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Surveys Start Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="demographic_information_form">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Surveys Start Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="progress_monitoring_survey">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Surveys Start Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="outcomes_survey">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Surveys Start Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="discharge_survey">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Surveys Start Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="recovery_capital">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Surveys Start Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="mirc">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Surveys Start Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="insurance_policies">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Policys Effective Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="insurance_billing">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Insurance Claim Service Lines Start Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="insurance_payments_by_payment_date">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Insurance Payments Collected Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="insurance_payments_by_posted_date">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Insurance Payments Posted At Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="insurance_aging">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Insurance Claim Service Lines Start Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="new_insurance_payments_by_payment_date">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Insurance Payments Payment Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="new_insurance_payments_by_posted_date">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Insurance Payments Creation Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="new_insurance_aging">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Insurance Claim Service Lines Start Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="new_insurance_billing_by_service_date">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Insurance Claim Service Lines Start Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="new_insurance_billing_by_bill_date">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Insurance Claim Service Lines Initial Submission Date</strong> as
                      reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="documentation_status">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Clients Creation Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="documentation_time">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the{' '}
                      <strong>Date component of each Record Type (Clinical Note, UA Test, Breathalyzer Test, etc.)</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="all_referrals">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report will return <strong>All Current Clients Referral details</strong>.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="all_client_contacts">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Contacts Creation Dates</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="bed_assignments">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Bed Assignments Occupy Bed From Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="bed_occupancy_report">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Bed Assignments Occupy Bed From Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="seat_occupancy_report">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>From Date from each Seat Assignment</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="event_attendance_report">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Events Start Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="average_daily_census">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report will return <strong>All Locations Census details</strong>.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="program_assignments">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>From Date from each Seat Assignment</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="discharges">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Discharge Summarys Start Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="treatment_episodes">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report will return <strong>All Treatment Episodes</strong>.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="clinical_notes">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Sessions Start Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="clinical_measures">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Clinical Measures Start Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="shift_notes">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Shift Notes Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="safety_checks">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Shift Notes Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="service_episodes">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Service Episodes Start Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="rounds">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Rounds Start Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="peer_notes">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Peer Notes Start Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="events">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Events Start Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="form_submissions">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Form Submissions Creation Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="communications">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Communications Contact Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="organizations">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Organizations Creation Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="organization_contacts">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Organization Contacts Creation Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="employees">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Employees Creation Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="staff_productivity">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report will return <strong>All Employees Activities within the set time frame</strong>.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="notifications_blacklist">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Added At Time of each Notification Blacklist</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="roster">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the{' '}
                      <strong>Date component of each Record Type (Clinical Note, UA Test, Breathalyzer Test, etc.)</strong> as reference.
                    </Alert>
                  </ContextShow>

                  <ContextShow when="category" is="location_checks">
                    <Alert contrast glyph="info" type="warning">
                      <b>Important:</b> This Report is using the <strong>Check-In Date</strong> as reference.
                    </Alert>
                  </ContextShow>

                  {categoryColumns && (
                    <Card>
                      <h4 className="px-4 pt-2">Export Columns</h4>

                      <div className="px-4 py-1">
                        {categoryColumns.map((column: any) => (
                          <div
                            key={column.model}
                            className="py-2 px-0 border-b border-0 border-solid border-divider font-mono text-[0.88rem] whitespace-pre last:border-b-0"
                          >
                            {column.label}
                          </div>
                        ))}
                      </div>
                    </Card>
                  )}
                </FormSection>
              </Section>

              {!isNew && (
                <>
                  <Divider />

                  <Section headingType="h2" title="Timeline">
                    <Timeline isLoadingRecord={isLoading} recordID={data?.id} recordType={data?.type} />
                  </Section>
                </>
              )}
            </>
          )}
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label={isNew ? 'Request Report' : 'Save'}
              glyph="check"
              type="primary"
              color="green"
              flex="100 1 auto"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            {data?.attachment && (
              <>
                <QuickView
                  width={100}
                  title={data?.name}
                  contentType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  url={data?.attachment}
                >
                  <Button glyph="view" label="View Report" type="primary" flex="2 1 auto" />
                </QuickView>
                <Button onClick={() => window.open(data?.attachment)} glyph="download" label="Download" type="primary" color="green" />
              </>
            )}

            <Flex stretchChildrenX gap={8} className="!w-full">
              <Button
                glyph="edit"
                label="Edit Report"
                type="default"
                isDisabled={isLoading}
                onClick={edit}
                flex="100 1 auto"
                permission="generated_reports.edit"
              />

              <DeleteDialog
                title="Delete Report?"
                message="Are you sure you want to delete this report? This action cannot be undone."
                onYes={deleteRecord}
              >
                <Button
                  fullWidth
                  glyph="delete"
                  label="Delete"
                  type="default"
                  color="red"
                  isLoading={isDeleting}
                  permission="generated_reports.delete"
                />
              </DeleteDialog>
            </Flex>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const ScheduledExcelReportOverlay = withOverlayError(RootScheduledExcelReportOverlay)
