import React from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import { StaffCustomNoteTemplatesDataTable } from '../../../constructs/CustomNoteTemplates/StaffCustomNoteTemplatesDataTable'
import { countWord } from '../../../utils/functions'
import { useCreate } from '../../../hooks/useNewAPI'
import { useDataTable } from '../../DataTable/useDataTable'
import { useSettings } from '../../../hooks/useSettings'

import Button from '../../Button'
import Overlay from '../../Overlay'
import Section from '../../Section'
import SummonOverlay from '../../SummonOverlay'
import Tabs from '../../Tabs'

import StaffCustomNoteTemplateOverlay from './StaffCustomNoteTemplateOverlay'
import TemplateLegalTooltip from '../TemplateLegalTooltip'

const StaffCustomNoteTemplatesImportOverlay = () => {
  const navigate = useNavigate()

  const [selectedRows, setSelectedRows]: any = React.useState([])
  const [preview, setPreview]: any = React.useState(null)
  const [selectedType, setSelectedType]: any = React.useState('public_template')

  const { isBehave } = useSettings()

  const tableProps = useDataTable({
    name: ['internal_staff_custom_note_templates'],
    endpoint: '/internal_templates',
    params: {
      category: 'custom_note',
      subcategory: 'staff',
      level: selectedType,
      status: 'active',
    },
    localStorageKey: 'internal_staff_custom_note_templates_v1',
  })

  const createMutation = useCreate({
    name: ['import-custom-note'],
    url: '/internal_templates/import',
    invalidate: 'custom_note_templates',
  })

  const selectedRowsCount = size(selectedRows)
  const hasSelectedRows = selectedRowsCount >= 1

  const onImport = async () => {
    try {
      const ids = selectedRows.map((o: any) => o.id)

      await createMutation.mutateAsync({
        ids: ids,
        category: 'custom_note',
        subcategory: 'staff',
      })

      navigate(-1)
    } catch (errors) {
      console.debug(errors)
    }
  }

  return (
    <Overlay showBackdrop position="center" maxWidth={64}>
      <Overlay.Header title="Import Staff Custom Note Templates" icon="custom_notes" titleAside={<TemplateLegalTooltip />} />

      <Overlay.Content>
        {preview && (
          <SummonOverlay
            isOpen={!!preview}
            onClose={() => setPreview(null)}
            overlay={<StaffCustomNoteTemplateOverlay allowEditing={false} dataID={preview.id} initialData={preview.data} />}
          />
        )}

        <Section margin="0 0 1.5rem 0">
          {isBehave && (
            <Tabs onChange={setSelectedType} defaultTab="public_template">
              <Tabs.List>
                <Tabs.Item label="Public Templates" name="public_template" />
                <Tabs.Item glyph="behave_health" label="Behave Templates" name="behave_template" />
              </Tabs.List>
            </Tabs>
          )}

          <StaffCustomNoteTemplatesDataTable
            {...tableProps}
            canBatchSelect
            onRowSelectionUpdate={setSelectedRows}
            className="!mt-4"
            onClick={setPreview}
          />
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label={hasSelectedRows ? `Import ${countWord('Templates', selectedRowsCount)}` : 'Select Templates to Import'}
          isDisabled={!hasSelectedRows}
          type="primary"
          color="green"
          isLoading={createMutation.isLoading}
          onClick={onImport}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export default StaffCustomNoteTemplatesImportOverlay
