import React from 'react'
import { v4 as uuid } from 'uuid'
import { DateTime } from 'luxon'
import { lighten } from 'polished'
import { PanelGroup, Panel } from 'react-resizable-panels'
import { produce } from 'immer'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import { COLORS } from '../../theme'
import { ExportPDFButton } from '../../components/Buttons/ExportPDFButton'
import { styles } from '../../components/DropdownItem'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import Dropdown from '../../components/Dropdown'
import Flex from '../../components/Flex'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Permission from '../../components/Permission'
import Status from '../../components/Status'
import SummonOverlay from '../../components/SummonOverlay'

import { FormBuilderPublishStatus } from './FormBuilderPublishStatus'
import { FormBuilderShareOverlay } from './FormBuilderShareOverlay'
import { FormBuilderShareStatus } from './FormBuilderShareStatus'

import { ActivePage } from './components/ActivePage'
import { MainMenu } from './components/MainMenu'
import { NewFormBuilderProvider, useFormBuilder } from './useFormBuilder'
import { PanelResize } from './components/PanelResize'
import { useFormBuilderHotkeys } from './useFormBuilderHotkeys'

const formatNewInitialData = (initialData: any) => {
  if (size(initialData) === 0) return null

  const newIds = {}

  const getNewId = (id: string) => {
    if (!id) return null

    if (newIds[id]) return newIds[id]

    const newId = uuid()

    newIds[id] = newId

    return newId
  }

  return produce(initialData, (draft: any) => {
    delete draft.id
    delete draft.created_at
    delete draft.updated_at

    draft.status = 'draft'
    draft.external_id = getNewId(draft.external_id)

    if (draft.elements_order) {
      draft.elements_order = draft.elements_order?.map?.((id: string) => getNewId(id)) || []
    }

    // format pages
    for (const page of draft.form_pages) {
      delete page.id
      delete page.created_at
      delete page.updated_at

      page.uuid = getNewId(page.uuid)
      page.elements_order = page.elements_order?.map?.((id: string) => getNewId(id)) || []

      // format elements
      for (const element of page.form_blocks) {
        delete element.id
        delete element.created_at
        delete element.updated_at

        element.uuid = getNewId(element.uuid)
        element.parent_uuid = getNewId(element.parent_uuid)

        if (element.elements_order) {
          element.elements_order = element.elements_order.map((id: string) => getNewId(id))
        }

        if (element.category === 'conditional' && element.config?.element_uuid) {
          element.config.element_uuid = getNewId(element.config.element_uuid)
        }
      }
    }

    if (draft.form_variables) {
      // keep only form variables
      draft.form_variables = draft.form_variables.filter((variable: any) => variable.category === 'form')

      // format form variables
      for (const variable of draft.form_variables) {
        delete variable.id
        delete variable.created_at
        delete variable.updated_at

        variable.external_id = getNewId(variable.external_id)
      }
    }
  })
}

const RootFormBuilderOverlay = (props: any) => {
  const location = useLocation()
  const navigate = useNavigate()

  const { cancel, close, data, deleteRecord, edit, initialData, isDeleting, isEditable, isNew, isOverlayLoading, isSaving, saveWithData } =
    useOverlay({
      name: 'form',
      endpoint: '/forms',
      invalidate: 'forms',
      options: props,
      closeOnSave: false,
      skipClose: true,
      onSaveSuccessful: (apiData) => {
        if (!apiData) return

        if (isNew) {
          const newURL = location.pathname.replace(`/new`, `/${apiData.id}`)
          navigate(newURL, { replace: true })
        }
      },
    })

  const [apiData, setApiData] = React.useState(data)
  const [shareOverlayOpen, setShareOverlayOpen] = React.useState(false)
  const [previousBuilderState, setPreviousBuilderState] = React.useState(null)

  const handleCancel = () => {
    setApiData({ ...data, updated_at: DateTime.local().toISO() })
    cancel()
  }

  const hasInitialData = size(initialData) > 0

  React.useEffect(() => {
    if (isNew && initialData) {
      const newInitialData = formatNewInitialData(initialData)

      setApiData(newInitialData)

      return
    }

    if (!isNew && data) {
      setApiData(data)
    }
  }, [isNew, data, initialData])

  if (isOverlayLoading) return <OverlayLoader showBackdrop={isEditable} maxWidth={130} position="right" />

  if (!isNew && !apiData) return null

  if (isNew && hasInitialData && !apiData) return null

  const formId = data?.id

  return (
    <>
      <NewFormBuilderProvider
        key={`updated-${apiData?.updated_at}`}
        isNew={isNew}
        apiData={apiData}
        isEditable={isEditable}
        previousBuilderState={previousBuilderState}
      >
        <InnerOverlay
          cancel={handleCancel}
          data={data}
          deleteRecord={deleteRecord}
          edit={edit}
          formId={formId}
          isDeleting={isDeleting}
          isEditable={isEditable}
          isNew={isNew}
          isSaving={isSaving}
          onClose={close}
          saveWithData={saveWithData}
          setShareOverlayOpen={setShareOverlayOpen}
          setPreviousBuilderState={setPreviousBuilderState}
        />
      </NewFormBuilderProvider>

      <SummonOverlay
        isOpen={shareOverlayOpen}
        onClose={() => {
          setShareOverlayOpen(false)
        }}
        overlay={<FormBuilderShareOverlay dataID={data?.id} />}
      />
    </>
  )
}

const InnerOverlay = (props: any) => {
  const {
    cancel,
    data,
    deleteRecord,
    edit,
    formId,
    isDeleting,
    isEditable,
    isNew,
    isSaving,
    onClose,
    saveWithData,
    setShareOverlayOpen,
    setPreviousBuilderState,
  } = props

  const { isCommunity } = useSettings()

  const isReadonlyPDFExportEnabled = !!data?.pdf_export_settings?.readonly_pdf_export
  const isEditablePDFExportEnabled = !!data?.pdf_export_settings?.editable_pdf_export

  const isPDFExportEnabled = !!data?.use_pdf_export && (isReadonlyPDFExportEnabled || isEditablePDFExportEnabled)

  const exitPreviewMode: any = useFormBuilder((state: any) => state.exitPreviewMode)
  const getCurrentBuilderState: any = useFormBuilder((state: any) => state.getCurrentBuilderState)
  const getFormattedApiData: any = useFormBuilder((state: any) => state.getFormattedApiData)
  const parseAllElementConfigVariables: any = useFormBuilder((state: any) => state.parseAllElementConfigVariables)
  const setIsEditable: any = useFormBuilder((state: any) => state.setIsEditable)
  const setActiveElementId: any = useFormBuilder((state: any) => state.setActiveElementId)

  const allowEditing = !isCommunity

  const handleSave = async ({ keepEditing = false }) => {
    // save the current builder state to restore it after save
    // e.g. active page, active element, etc.
    const currentState = getCurrentBuilderState()

    if (currentState) {
      setPreviousBuilderState(currentState)
    }

    // get the formatted data from the builder to save
    const formattedData = await getFormattedApiData()
    await saveWithData(formattedData, { keepEditing })

    if (!keepEditing) setActiveElementId(null)

    exitPreviewMode()
  }

  React.useEffect(() => {
    setIsEditable(isEditable)
  }, [isEditable])

  React.useEffect(() => {
    parseAllElementConfigVariables()
  }, [])

  useFormBuilderHotkeys()

  return (
    <Overlay
      fullheight
      showBackdrop={isEditable}
      closeOnEscape={false}
      maxWidth={130}
      position="right"
      onClose={onClose}
      css={STYLES.root}
      isDirty={isEditable}
    >
      <Overlay.Header
        icon="web_form"
        title="Form Builder"
        titleAside={
          !isNew &&
          !isEditable && (
            <Flex centerY className="!ml-2">
              <FormBuilderPublishStatus status={data?.status} className="mr-3" />
              <FormBuilderShareStatus status={data?.use_public_sharing ? 'public' : 'private'} />

              <div>
                <Button
                  label={data?.use_public_sharing ? 'Share Link' : 'Get Share Link'}
                  glyph="share"
                  type="link"
                  size={200}
                  onClick={() => {
                    setShareOverlayOpen(true)
                  }}
                />
              </div>

              {formId && isPDFExportEnabled && (
                <Permission featureFlagV2="forms_builder_pdf_export">
                  <div className="h-[16px] w-[1px] bg-divider mx-2" />

                  <div className="!mr-4">
                    <Dropdown label={<div className="flex items-center">Download Form PDF</div>} buttonType="link" glyph="download">
                      {isReadonlyPDFExportEnabled && (
                        <ExportPDFButton url={`/forms/${formId}/pdf`} label="Readonly PDF" css={dropdownItemStyles} />
                      )}

                      {isEditablePDFExportEnabled && (
                        <ExportPDFButton
                          url={`/forms/${formId}/pdf`}
                          label="Editable PDF"
                          css={dropdownItemStyles}
                          params={{ editable: true }}
                        />
                      )}
                    </Dropdown>
                  </div>
                </Permission>
              )}
            </Flex>
          )
        }
      />

      <Overlay.Content className="grid grid-rows-[100%] grid-cols-[100%] !overflow-hidden">
        <PanelGroup direction="horizontal">
          <Panel
            id="menu"
            order={1}
            maxSize={40}
            minSize={20}
            defaultSize={25}
            className="grid grid-rows-[100%] grid-cols-[100%] shadow-right-hard-3 z-[3] border-r border-0 border-solid border-divider"
          >
            <MainMenu
              isNew={isNew}
              allowEditing={allowEditing}
              deleteRecord={deleteRecord}
              isDeleting={isDeleting}
              onSave={handleSave}
              isSaving={isSaving}
              cancel={cancel}
              edit={edit}
            />
          </Panel>

          <PanelResize direction="vertical" />

          <Panel id="page" order={2} maxSize={80} defaultSize={75}>
            <ActivePage
              isPDFExportEnabled={isPDFExportEnabled}
              isReadonlyPDFExportEnabled={isReadonlyPDFExportEnabled}
              isEditablePDFExportEnabled={isEditablePDFExportEnabled}
              isEditable={isEditable}
              isNew={isNew}
              cancel={cancel}
              onSave={handleSave}
              isSaving={isSaving}
              edit={edit}
              allowEditing={allowEditing}
            />
          </Panel>
        </PanelGroup>
      </Overlay.Content>
    </Overlay>
  )
}

const dropdownItemStyles = {
  ...styles(COLORS.blue),
  display: 'flex !important',
}

const STYLES = {
  root: {
    '--header-height': '2.8rem',
    '--header-background': COLORS.white,
    '--subheader-height': '2.4rem',
    '--subheader-background': lighten(0.01, '#F7F8FB'),
    '--main-menu-width': '440px',
    '--field-max-width': '100% !important',
  },
}

export const FormBuilderOverlay = withOverlayError(RootFormBuilderOverlay)
