import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import size from 'lodash/size'

import { titleCase } from '../../utils/functions'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import AccordionCard from '../../components/AccordionCard'
import Alert from '../../components/Alert'
import Attachments from '../../components/Forms/Attachments'
import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import Chotomate from '../../components/Chotomate'
import ClinicalNoteStatus from '../../components/Statuses/ClinicalNoteStatus'
import ContextShow from '../../components/Forms/ContextShow'
import DataArray from '../../components/Forms/DataArray'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import HelpTagIframe from '../../components/Help/HelpTagIframe'
import Input from '../../components/Forms/Input'
import MiniRichTextEditor from '../../components/Forms/MiniRichTextEditor'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import SignatureDialog from '../../components/Dialogs/SignatureDialog'
import SignaturePad from '../../components/Forms/SignaturePad'
import Section from '../../components/Section'
import State from '../../components/State'

import EventAttendancePresentStatusSelect from '../../components/Elements/EventAttendancePresentStatusSelect'
import EventAttendanceAbsentStatusSelect from '../../components/Elements/EventAttendanceAbsentStatusSelect'

import { COLORS } from '../../theme'
import { TreatmentPlanSelector } from '../../components/Forms/TreatmentPlanSelector'
import { InsuranceBillingFormSection } from '../RCM/components/InsuranceBillingFormSection'

import { FormFutureDateWarning } from '../Misc/FormFutureDateWarning'

const GroupNotes = ({ model, format }: any) => (
  <DataArray model={model}>
    {({ orderedIds, add, remove }) => {
      const isEmpty = size(orderedIds) === 0

      return (
        <>
          {orderedIds?.map((id: string, idx: number) => (
            <GroupNote key={id} id={id} idx={idx} remove={remove} format={format} />
          ))}

          {isEmpty ? (
            <Section>
              <State
                isEmpty
                emptyDescription="No Notes added yet"
                emptyActions={<Button type="default" glyph="add" label="Add Session Note" onClick={add} />}
              />
            </Section>
          ) : (
            <Button type="default" glyph="add" label="Add Session Note" onClick={add} />
          )}
        </>
      )
    }}
  </DataArray>
)

const GroupNote = (props: any) => {
  const { id, idx, remove, format } = props

  const [clientId, setClientId] = React.useState<string | null>(null)

  const { tenant, user }: any = useSettings()

  const [isAttending, setIsAttending] = React.useState()

  return (
    <AccordionCard
      isOpen
      title={`Note #${idx + 1}`}
      css={{ background: COLORS.lightBackground }}
      aside={
        <Button
          size={200}
          type="minimal"
          glyph="delete"
          color="red"
          label="Remove"
          display="inline-flex"
          onClick={(event: any) => {
            event.preventDefault()
            remove(id)
          }}
        />
      }
    >
      <FormSection maxWidth="100%">
        <Flex gap="1rem">
          <OverlaySelector
            isEditable
            label="Client"
            icon="clients"
            type="clients.current"
            className="!flex-auto"
            model={`${id}.client`}
            selectTitle={(data: any) => data?.name}
            selectDescription={(data: any) => data?.category}
            validations={{
              presence: {
                message: 'Please select a Client',
              },
            }}
            onUpdate={({ object }) => {
              if (!object) return

              setClientId(object.id)
            }}
          />
        </Flex>

        {tenant?.is_billed && (
          <InsuranceBillingFormSection
            showPlaceOfService
            feeScheduleModel={`${id}.insurance_new_fee_schedule`}
            model={`${id}.insurance_new_fee_schedule_service`}
          />
        )}

        {/* <ContextShow when={`${id}.is_billable`} is={true}>
          <ObjectSelector
            dependent="client_id"
            model={`${id}.insurance_service`}
            label="Rendered Insurance Service"
            type="client.insurance_services"
            icon="billing"
            searchKeys={['code', 'short_description', 'long_description']}
            selectTitle={(o: any) => `${o?.code} - ${o?.short_description}`}
            selectDescription={(o: any) => o?.long_description}
            groupBy={{
              accessor: 'service_type',
              sections: {
                in_network: 'In Network',
                out_of_network: 'Out of Network',
                global_oon: 'Global Out of Network',
              },
            }}
            validations={{
              presence: {
                message: 'Please select a service',
              },
            }}
          />

          <MiniRichTextEditor useDictation model={`${id}.billing_notes`} label="Billing Notes" />

          <Divider className="!m-0" />
        </ContextShow> */}

        <TreatmentPlanSelector baseModel={id} clientId={clientId} />

        <Flex fixChildrenX gap="1rem" alignItems="flex-end">
          {/* <Input model={`${id}.treatment_goal`} label="Treatment Goal Addressed 3" className="!flex-auto" /> */}

          <RadioGroup
            label="Presence"
            model={`${id}.is_attending`}
            layout="horizontal-dense"
            trueIcon="check"
            falseIcon="cross"
            validations={{
              presence: {
                message: 'Please set presence',
              },
            }}
            onUpdate={({ value }) => {
              setIsAttending(value)
            }}
          >
            <Radio label="Present" value={true} />
            <Radio label="Absent" value={false} />
          </RadioGroup>

          <ContextShow when={`${id}.is_attending`} is={true}>
            <div className="!flex-auto">
              <EventAttendancePresentStatusSelect label="Present Status" model={`${id}.present_status`} />
            </div>
          </ContextShow>

          <ContextShow when={`${id}.is_attending`} is={false}>
            <div className="!flex-auto">
              <EventAttendanceAbsentStatusSelect label="Absent Status" model={`${id}.absent_status`} />
            </div>
          </ContextShow>
        </Flex>

        <ContextShow when={`${id}.is_attending`} is={true}>
          <Checkbox
            defaultValue={false}
            label="Override Default Session Date & Time"
            model={`${id}.is_custom_time`}
            trueIcon="check"
            falseIcon="cross"
            css={{ flex: '1 1 auto !important' }}
          />
        </ContextShow>

        <ContextShow when={`${id}.is_custom_time`} is={true}>
          <Flex gap="1rem">
            <DateTimeInput
              defaultToNow
              className="!flex-auto"
              model={`${id}.custom_started_at`}
              label="Custom Date & Time"
              validations={{
                presence: {
                  message: 'Please enter a custom date and time',
                },
              }}
            />

            <Input
              model={`${id}.custom_duration`}
              label="Custom Duration"
              suffix="mins"
              type="number"
              min={1}
              size={4}
              validations={{
                presence: {
                  message: 'Please enter a custom duration',
                },
                numericality: {
                  greaterThanOrEqualTo: 1,
                  message: 'The duration must be higher than 0 minutes',
                },
              }}
            />
          </Flex>
        </ContextShow>

        {isAttending && (
          <>
            {format === 'individual_assessment' && (
              <MiniRichTextEditor
                useDictation
                useQuickText
                model={`${id}.assessment`}
                label="Client Note"
                validations={{
                  presence: {
                    message: 'Please provide an individual assessment',
                  },
                }}
              />
            )}

            {format === 'dap' && (
              <>
                <MiniRichTextEditor useDictation useQuickText model={`${id}.data`} label="Data" />
                <MiniRichTextEditor useDictation useQuickText model={`${id}.assessment`} label="Assessment" />
                <MiniRichTextEditor useDictation useQuickText model={`${id}.plan`} label="Plan" />
              </>
            )}

            {format === 'soap' && (
              <>
                <MiniRichTextEditor useDictation useQuickText model={`${id}.subjective`} label="Subjective" />
                <MiniRichTextEditor useDictation useQuickText model={`${id}.objective`} label="Objective" />
                <MiniRichTextEditor useDictation useQuickText model={`${id}.assessment`} label="Assessment" />
                <MiniRichTextEditor useDictation useQuickText model={`${id}.plan`} label="Plan" />
              </>
            )}

            {format === 'girp' && (
              <>
                <MiniRichTextEditor useDictation useQuickText model={`${id}.goals`} label="Goals" />
                <MiniRichTextEditor useDictation useQuickText model={`${id}.intervention`} label="Intervention" />
                <MiniRichTextEditor useDictation useQuickText model={`${id}.response`} label="Response" />
                <MiniRichTextEditor useDictation useQuickText model={`${id}.plan`} label="Plan" />

                <div className="!hidden">
                  {/* keep assessment model data when toggling between different note types */}
                  <MiniRichTextEditor model={`${id}.assessment`} label="Individual Assessment" />
                </div>
              </>
            )}
          </>
        )}

        <ContextShow when={`${id}.is_attending`} is={false}>
          <MiniRichTextEditor
            useDictation
            useQuickText
            model={`${id}.absentee_note`}
            label="Absentee Note"
            validations={{
              presence: {
                message: 'Please provide an absentee note',
              },
            }}
          />
        </ContextShow>
      </FormSection>
    </AccordionCard>
  )
}

export const RootClinicalNoteGroupBuilderOverlay = (props: any) => {
  const { position = 'right' } = props

  const { data, form, initialModel, isInvalid, isOverlayLoading, isSaving, onValidationUpdate, saveWithData, isEditable } = useOverlay({
    name: 'clinical-notes',
    endpoint: '/clinical_notes/builder',
    invalidate: 'clinical-notes',
    options: props,
    onDeleteSuccessful: props.onDeleteSuccessful,
    onSaveSuccessful: props.onSaveSuccessful,
  })

  const location = useLocation()
  const history = useHistory()

  const close = () => {
    if (props.type === 'summon' && props.onClose) return props.onClose()

    const url = location?.parent?.url || location.pathname.substring(0, location.pathname.lastIndexOf('/gn/builder'))

    history.push(url)
  }

  const { timezone, defaultSupervisor, systemPrefs }: any = useSettings()

  const isRequiredSupervisorNonEditable = systemPrefs?.require_clinical_notes_supervisor
  const [format, setFormat] = React.useState('individual_assessment')
  const [supervisor, setSupervisor] = React.useState<any>(null)
  const [requireSupervisor, setRequireSupervisor] = React.useState(true)

  const save = async () => {
    const formData = form.current.getFormValue()

    if (props.save) {
      await props.save(formData)
      props.onClose?.()
    } else {
      await saveWithData(formData)
    }
  }

  if (isOverlayLoading) {
    return <OverlayLoader position={position} />
  }

  return (
    <Overlay isDirty showBackdrop maxWidth={47} onClose={close} position={position}>
      <Overlay.Header
        icon="clinical_notes"
        title="Group Note"
        subtitle={<ClinicalNoteStatus status={initialModel?.status} />}
        help={<HelpTagIframe id="clinical_notes_group" />}
      />

      <Overlay.Content>
        <Chotomate ready name="group_note_overlay" />

        <Form
          isEditable
          getForm={form}
          timezone={timezone}
          initialModel={{
            ...initialModel,
            ...(defaultSupervisor && { supervisor: defaultSupervisor }),
          }}
          onValidationUpdate={onValidationUpdate}
          linked={{ category: 'group_note' }}
        >
          {initialModel?.status === 'updates_required' && (
            <Section>
              <Alert type="negative" glyph="note">
                <MiniRichTextEditor useDictation fullwidth label="Updates Requested before Sign-Off" model="rejected_reason" />
              </Alert>
            </Section>
          )}

          <Section headingType="h2" title="Session Details">
            <FormSection maxWidth="100%">
              <Flex gap="1rem">
                <DateTimeInput
                  defaultToNow
                  className="!flex-auto"
                  model="started_at"
                  label="Default Session Date and Time"
                  validations={{
                    presence: {
                      message: 'Please enter a session date and time',
                    },
                  }}
                />

                <Input
                  model="duration"
                  label="Duration"
                  suffix="mins"
                  type="number"
                  min={1}
                  size={4}
                  validations={{
                    presence: {
                      message: 'Please enter a session duration',
                    },
                    numericality: {
                      greaterThanOrEqualTo: 1,
                      message: 'The duration must be higher than 0 minutes',
                    },
                  }}
                />
              </Flex>

              <FormFutureDateWarning dateLabel="Session Start Date and Time" model="started_at" />

              <Flex gap="1rem" justifyContent="space-between" alignItems="flex-end">
                <ObjectSelector
                  isPolymorphic
                  css={isEditable && { flex: '1 1 auto' }}
                  model="supervisor"
                  label="Clinical Supervisor"
                  type="clinical_supervisors"
                  icon="employees"
                  selectTitle={(data) => data?.name}
                  selectDescription={(data) => titleCase(data?.position)}
                  validations={
                    requireSupervisor && {
                      presence: {
                        message: 'Please select a clinical supervisor',
                      },
                    }
                  }
                  onUpdate={({ object }) => {
                    setSupervisor(object)
                  }}
                  value={isEditable ? supervisor : data?.supervisor}
                />

                <CheckboxGroup label="Supervisor" tooltip={isRequiredSupervisorNonEditable ? 'Required from Settings' : null}>
                  <Checkbox
                    isEditable={isRequiredSupervisorNonEditable ? false : isEditable}
                    defaultChecked
                    trueIcon="check"
                    falseIcon="cross"
                    falseStyle="none"
                    label="Require Supervisor"
                    model="require_supervisor"
                    onChange={(state: any) => {
                      const nextValue = state.value
                      setRequireSupervisor(nextValue)

                      if (nextValue === false) {
                        setSupervisor(null)
                      }
                    }}
                  />
                </CheckboxGroup>
              </Flex>
            </FormSection>
          </Section>

          <Divider />

          <Section
            headingType="h2"
            title="Session Notes"
            description="Add all clients present to this session and write notes for each of them"
          >
            <FormSection maxWidth="100%">
              <Input model="topic" label="Group Topic" />
              <MiniRichTextEditor useDictation useQuickText model="description" label="Group Discussion" />
              <MiniRichTextEditor useDictation useQuickText model="notes" label="Notes" />

              <Divider className="!my-2" />

              <RadioGroup
                model="format"
                label="Note Type"
                layout="horizontal-dense"
                defaultValue={'individual_assessment'}
                onUpdate={({ value }) => {
                  setFormat(value)
                }}
              >
                <Radio label="DAP" value="dap" />
                <Radio label="SOAP" value="soap" />
                <Radio label="GIRP" value="girp" />
                <Radio label="Client Note" value="individual_assessment" />
              </RadioGroup>

              <GroupNotes model="group_notes" format={format} />
            </FormSection>
          </Section>

          <Divider />

          <Section headingType="h2" title="Signatures">
            <FormSection maxWidth="100%" layout="vertical">
              <SignaturePad
                allowPin
                person={data?.signed_by}
                label="Staff Signature"
                model="employee_signature"
                signedAtModel="author_signed_at"
                showAccept={false}
              />

              <SignaturePad
                allowPin
                isEditable={false}
                person={data?.supervisor}
                label="Supervisor Signature"
                model="supervisor_signature"
                signedAtModel="supervisor_signed_at"
                showAccept={false}
              />
            </FormSection>
          </Section>

          <Divider />

          <Section headingType="h2" title="Attachments" description="Upload the files related to this note">
            <FormSection maxWidth="100%">
              <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label="Save"
          glyph="check"
          type="primary"
          color="green"
          isLoading={props.isSaving || isSaving}
          onClick={save}
          isDisabled={isInvalid}
          flex="100 1 auto"
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export const ClinicalNoteGroupBuilderOverlay = withOverlayError(RootClinicalNoteGroupBuilderOverlay)
