import React from 'react'

import Field from './Field'
import Grid from '../Grid'
import Flex from '../Flex'
import Button from '../Button'
import SummonOverlay from '../SummonOverlay'
import StateSelect from './elements/StateSelect'
import Input from './Input'
import FormSection from './FormSection'

import AddressSelectorOverlay from '../Overlays/actions/AddressSelectorOverlay'

import withSettings from '../../hocs/withSettings'
import { withFormContext } from './context'

import { useFormField } from './hooks/useFormField'

const DEFAULT_VALUE = {
  address_line_1: '',
  address_line_2: '',
  city: '',
  state: '',
  zip_code: '',
}

const AddressInput = (props: any) => {
  const {
    className,
    model,
    actionLabel,
    tenant,
    form,
    glyph,
    isEditable,
    coords,
    afterAddressSelect,
    showSelector = true,
    initialValue,
    ...rest
  } = props
  const [data, setData]: any = React.useState(initialValue || DEFAULT_VALUE)

  const { formActions } = useFormField({
    model: model,
    form: form,
  })

  // ON MOUNT
  React.useEffect(() => {
    let initialData = form?.getInitialInputFieldValue(model)
    if (!initialData) return

    setData(initialData)
  }, [])

  // UPDATE FORM
  React.useEffect(() => {
    formActions.setValue(data)
  }, [form, model, data])

  const addressSelect = (address: any) => {
    const processedAddress = {
      address_line_1: `${address.address} ${address.text}`,
      address_line_2: address.addressLine2,
      city: address.context.place?.text,
      state: address.context.region?.short_code.split('-')[1],
      zip_code: address.context.postcode?.text,
      country: 'USA',
      coords: {
        lat: address.center[1],
        lon: address.center[0],
      },
    }

    if (afterAddressSelect) afterAddressSelect(processedAddress)
    setData(processedAddress)
  }

  return (
    <Field {...rest}>
      <Grid gap={20}>
        <FormSection className="mt-2">
          <Input
            label="Address Line 1"
            value={data.address_line_1}
            onUpdate={(state: any) => setData((o: any) => ({ ...o, address_line_1: state.value }))}
          />
          <Input
            label="Address Line 2"
            value={data.address_line_2}
            onUpdate={(state: any) => setData((o: any) => ({ ...o, address_line_2: state.value }))}
          />
          <Input label="City" value={data.city} onUpdate={(state: any) => setData((o: any) => ({ ...o, city: state.value }))} />

          <Flex gap="1rem">
            <StateSelect
              className="!flex-auto"
              label="State"
              value={data.state}
              onUpdate={(state: any) => setData((o: any) => ({ ...o, state: state.value }))}
            />

            <Input
              size={8}
              label="Zip Code"
              value={data.zip_code}
              onUpdate={(state: any) => setData((o: any) => ({ ...o, zip_code: state.value }))}
            />
          </Flex>
        </FormSection>

        {isEditable && showSelector && (
          <Flex gap="1rem">
            <SummonOverlay
              overlay={
                <AddressSelectorOverlay
                  lat={coords?.lat}
                  lon={coords?.lon}
                  afterSelect={addressSelect}
                  centerOnState={tenant?.mailing_address?.state}
                />
              }
            >
              <Button flex="1 1 auto" type="default" glyph={glyph} label={actionLabel} />
            </SummonOverlay>
          </Flex>
        )}
      </Grid>
    </Field>
  )
}

AddressInput.defaultProps = {
  isEditable: true,
  actionLabel: 'Quick Search Address',
  glyph: 'map',
}

export default withFormContext(withSettings(AddressInput))
